import React, { useState, useEffect } from "react";
import { baseUrl } from "../Url";
import style from "./Locations.module.css";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Country, State, City } from 'country-state-city';

import {
  Modal,
  TextField,
  Paper,
  Table,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useOutletContext } from 'react-router-dom';

function Locations() {

  const { selectedNewHub } = useOutletContext();

  const [showModal, setShowModal] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [locationName, setLocationName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [roleId] = useState("");
  const [orgSuccess, setOrgSuccess] = useState(false);
  const [orgFail, setOrgFail] = useState(false);
  const [orgDeletion, setOrgDeletion] = useState(false);
  const [orgUpdation, setOrgUpdation] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [hubData, setHubData] = useState([]);
  const [selectedHub, setSelectedHub] = useState("");
  const [Locitems, setLocItems] = useState([]);
  const [deleteLocation, setDeleteLocation] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [editLocationData, setEditLocationData] = useState({
    location_id: "",
    location_name: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    latitude: "",
    longitude: "",
    org_id: "",
    status:""
  });
  const [searchValue, setSearchValue] = useState("");
  const [locView, setLocView] = useState(true);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();


  const handleHubSelect = (e) => {
    const selectedHubId = e.target.value;
    const selectedHubName = hubData.find((hub) => hub.org_id === selectedHubId)?.org_name;
    setSelectedHub(selectedHubId);
    localStorage.setItem('selectedHubName', selectedHubName); // Store hub name in local storage
    LocationList(selectedHubId);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("access-token");
    const handleNavigation = () => {
      if (storedToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        navigate("/login");
      }
    };

    handleNavigation();
    fetchData();
  }, [navigate, setIsLoggedIn]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  async function userSearch(searchValue, orgId) {
    const reqData = {
      search_value: searchValue,
      org_id: orgId,
    };
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/location/search`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setLocItems(data.data);
          setLocView(true);
        } else {
          setLocView(false);
        }
      });
  }

  //---------  HUB LISTING ---------
  async function fetchData() {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/organizations/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      setHubData(data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  }

  //--------- LOCATION CREATION ---------
  const handleAddLoc = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/location/creation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          location_name: locationName,
          country: countryName,
          state: stateName,
          city: cityName,
          zip_code: zipCode,
          latitude: latitude,
          longitude: longitude,
          org_id: selectedNewHub,
        }),
      });
      const data = await response.json();
      if (data.status === true) {
        setLocationData([...locationData, data.data]);
        setOrgSuccess(true);
        closeModal();
        await LocationList(selectedNewHub);

        // Reset the modal form fields
        setLocationName("");
        setCountryName("");
        setStateName("");
        setCityName("");
        setZipCode("");
        setLatitude("");
        setLongitude("");
        // setSelectedHub(selectedHub); // Reset hub selection if needed
      } else {
        console.error("Error adding Location:", data.description);
        setApiDescription(data.description);
        setOrgFail(true);
      }
    } catch (error) {
      console.error("Error adding Location:", error);
    }
  };


  // --------- LOCATION LISTING ---------
  async function LocationList(value) {
    const storedToken = JSON.parse(localStorage.getItem("access-token"));
    let reqRole = roleId ? roleId : "All";
    await fetch(`${baseUrl}/location/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify({
        role: reqRole,
        org_id: value,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLocItems(data.data);
          setLocView(true);
        } else {
          setLocView(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  }

  // ---------- Location Delete ----------
  const handleDeleteLocation = (location) => {
    setDeleteLocation(location);
    setDeleteConfirmation(true);
  };

  const confirmDeleteLocation = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));

      const response = await fetch(`${baseUrl}/location/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          location_id: deleteLocation.location_id,
        }),
      });
      const data = await response.json();
      if (data.status === true) {
        setLocItems((prevItems) =>
          prevItems.filter(
            (prevItem) => prevItem.location_id !== deleteLocation.location_id
          )
        );
        setOrgDeletion(true);
      } else {
        console.error("Error deleting Location:", data.description);
        setApiDescription(data.description);
        setOrgFail(true);
      }
    } catch (error) {
      console.error("Error deleting Location:", error);
    }
    setDeleteConfirmation(false);
  };

  // ---------------------------- //

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  // ------ Loction Edit/update ------

  const openEditModal = (location) => {
    setEditLocationData(location);
    setEditLocationModalOpen(true);
  };
  const handleEditLocation = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/location/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(editLocationData),
      });
      const data = await response.json();
      if (data.status === true) {
        setLocItems((prevItems) =>
          prevItems.map((prevItem) =>
            prevItem.location_id === editLocationData.location_id
              ? { ...prevItem, ...editLocationData }
              : prevItem
          )
        );
        setOrgUpdation(true);
        setEditLocationModalOpen(false);
      } else {
        console.error("Error updating Location:", data.description);
        setApiDescription(data.description);
        setOrgFail(true);
      }
    } catch (error) {
      console.error("Error updating Location:", error);
    }
  };

  useEffect(()=>{
    console.log(selectedNewHub,"newh hub");
    LocationList(selectedNewHub)
  },[selectedNewHub])

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const filteredCountries = allCountries.filter(country => 
      ['IN', 'AE', 'GB', 'US'].includes(country.isoCode)
    );
    setCountries(filteredCountries);
  }, []);

  useEffect(() => {
    if (editLocationData.country&&editLocationModalOpen) {
      const country = countries.find(country => country.name === editLocationData.country);
      if (country) {
        const countryCode = country.isoCode;
        setStates(State.getStatesOfCountry(countryCode));
      }
    }
  }, [editLocationData.country, countries]);

  

  return (
    <div className={style.mainBox}>
      {isLoggedIn && (
        <div className={style.container}>
          <div className={style.Header}>
            <div className={style.HeaderText}>
              <p>Location Management</p>
            </div>

            {/* <div className={style.dropdownSelect}>
              <Box sx={{ minWidth: 200 }}>
                <FormControl sx={{ m: 2, minWidth: 200 }} size="small">
                  <Select
                    value={selectedHub}
                    onChange={(e) => {
                      const selectedHubValue = e.target.value;
                      setSelectedHub(selectedHubValue);
                      LocationList(selectedHubValue);
                    }}
                    displayEmpty
                    input={
                      <OutlinedInput
                        sx={{
                          "& fieldset": {
                            borderColor: "#59293a",
                            borderWidth: 2,
                          },
                          "&fieldset:hover ": {
                            borderColor: "#794a57",
                          },
                          "& fieldset:active": {
                            borderColor: "#a96b84",
                          },
                        }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      {" "}
                      <em>Select a Hub</em>
                    </MenuItem>
                    {hubData.map((item) => (
                      <MenuItem key={item.org_id} value={item.org_id}>
                        {item.org_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div> */}

            <div className={style.search}>
              <IoSearch className={style.searchIcon} />
              <input
                type="text"
                placeholder="search"
                className={style.searchInput}
                value={searchValue}
                onChange={(e) => {
                  const searchInputValue = e.target.value;
                  setSearchValue(searchInputValue);
                  if (searchInputValue !== "") {
                    userSearch(searchInputValue, selectedNewHub);
                  } else {
                    LocationList(selectedNewHub);
                    setLocView(true);
                  }

                }}
              />
            </div>
            <button onClick={openModal} className={style.ButtonSection}>Add Location</button>
          </div>

          {/* <div className={style.ButtonSection}>
            <button onClick={openModal}>Add Location</button>
          </div> */}

          <div className={style.locTable}>
            {selectedNewHub ? (
              locView ? (
                <TableContainer
                  component={Paper}
                  className={style.tableContainer}
                >
                  <Table>
                    <TableHead className={style.tableHead}>
                      <TableRow>
                        <TableCell>Location Name</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>ZIP Code</TableCell>
                        <TableCell>Latitude</TableCell>
                        <TableCell>Longitude</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Locitems.map((item) => (
                        <TableRow key={item.location_id}>
                          <TableCell>{item.location_name}</TableCell>
                          <TableCell>{item.country}</TableCell>
                          <TableCell>{item.state}</TableCell>
                          <TableCell>{item.city}</TableCell>
                          <TableCell>{item.zip_code}</TableCell>
                          <TableCell>{item.latitude}</TableCell>
                          <TableCell>{item.longitude}</TableCell>
                          <TableCell>
                            <div className={style.actionIcons}>
                              <div className={style.editbtn}>
                                <BiEditAlt
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => openEditModal(item)}
                                  title="Edit"
                                />
                              </div>
                              <div
                                className={style.deletebtn}
                                onClick={() => handleDeleteLocation(item)}
                                title="Delete"

                              >
                                <RiDeleteBinLine
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert severity="error" color="warning">
                  No locations found.
                </Alert>
              )
            ) : (
              <Alert severity="error" color="warning">
                Please select a hub to view the locations.
              </Alert>
            )}
          </div>


          {/* ------------- Create modal ------------- */}
          <div className={style.modalLoc}>
            <Modal
              open={showModal}
              onClose={closeModal}
              aria-labelledby="add-location-modal"
              aria-describedby="add-location-form"
            >
              <Paper className={style.modal} elevation={3}>
                <h2 id="add-location-modal">Add Location</h2>
                <div className={style.modalContent}>


                  <TextField
                    label="Location Name"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <div   className={style.textFieldGroup}>
                  <FormControl style={{ width: '48%' }} fullWidth margin="normal">
                  <InputLabel id="org-select-label">Country</InputLabel>
                  <Select
                    labelId="org-select-label"
                    label="Country"
                    id="country-select"
                    
                    onChange={(e)=>{
                      const countryName = e.target.value;
                      setCountryName(countryName)
                      const country = countries.find(country => country.name === countryName);
                      if (country) {
                        const countryCode = country.isoCode;
                        setStates(State.getStatesOfCountry(countryCode));
                      } else {
                        setStates([]);
                      }
                    }}
                  >
                    {countries.map((country) => (
                   <MenuItem key={country.isoCode} value={country.name}>
                   {country.name}
                    </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: '48%' }} fullWidth margin="normal">
                  <InputLabel id="org-select-label">State</InputLabel>
                  <Select
                    labelId="org-select-label"
                    label="State"
                    id="-select"
                    
                    onChange={(e) => {
                      const stateName = e.target.value;
                      setStateName(stateName)
                     
                    }}
                    disabled={!countryName}
                  >
                   {states.map((state) => (
                <MenuItem key={state.isoCode} value={state.name}>
                     {state.name}
              </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  </div>
                  <div className={style.textFieldGroup}>
                    <TextField
                      label="City"
                      value={cityName}
                      onChange={(e) => setCityName(e.target.value)}
                      // fullWidth
                      margin="normal"
                      style={{ width: '48%' }}
                    />
                    <TextField
                      label="ZIP Code"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      // fullWidth
                      margin="normal"
                      style={{ width: '48%' }}
                    />
                  </div>
                  <div  className={style.textFieldGroup}>
                    <TextField
                      label="Latitude"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      // fullWidth
                      margin="normal"
                      style={{ width: '48%' }}
                    />
                    <TextField
                      label="Longitude"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      // fullWidth
                      margin="normal"
                      style={{ width: '48%' }}
                    />
                  </div>
                  {/* <FormControl fullWidth margin="normal">
                    <InputLabel id="hub-select-label">Select Hub</InputLabel>
                    <Select
                      labelId="hub-select-label"
                      label="Select Hub"
                      id="hub-select"
                      onChange={(e) => setSelectedHub(e.target.value)}
                      MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                    >
                      {hubData.map((item) => (
                        <MenuItem key={item.org_id} value={item.org_id}>
                          {item.org_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <div className={style.modalActions}>
                    <button
                      className={style.locSub}
                      variant="contained"
                      onClick={handleAddLoc}
                    >
                      SUBMIT
                    </button>
                    <button
                      className={style.locClose}
                      variant="outlined"
                      onClick={closeModal}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </Paper>
            </Modal>
          </div>

          {/* ------------- Edit modal ------------- */}
          <div className={style.modalLoc}>
            <Modal
              open={editLocationModalOpen}
              onClose={() => setEditLocationModalOpen(false)}
              aria-labelledby="edit-location-modal"
              aria-describedby="edit-location-form"
            >
              <Paper className={style.modal} elevation={3}>
                <h2 id="edit-location-modal">Edit Location</h2>
                <div className={style.modalContent}>
                  {/* <FormControl fullWidth margin="normal">
          <InputLabel id="hub-select-label">Select Hub</InputLabel>
          <Select
            label="Select Hub"
            labelId="hub-select-label"
            id="hub-select"
            MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
            value={editLocationData.org_id}
            onChange={(e) =>
              setEditLocationData({
                ...editLocationData,
                org_id: e.target.value,
              })
            }
          >
            {hubData.map((item) => (
              <MenuItem key={item.org_id} value={item.org_id}>
                {item.org_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

                  <TextField
                    label="Location Name"
                    value={editLocationData.location_name}
                    onChange={(e) =>
                      setEditLocationData({
                        ...editLocationData,
                        location_name: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                  />
                   <div  style={{ display: "flex", justifyContent: "space-between"}}>
                  <FormControl style={{ width: '48%' }} fullWidth margin="normal">
                  <InputLabel id="org-select-label">Country</InputLabel>
                  <Select
                    labelId="org-select-label"
                    label="Country"
                    id="country-select"
                    value={editLocationData.country}
                    onChange={(e)=>{
                      const countryName = e.target.value;
                      setEditLocationData({
                        ...editLocationData,
                        country: countryName,
                      })
                      const country = countries.find(country => country.name === countryName);
                      if (country) {
                        const countryCode = country.isoCode;
                        setStates(State.getStatesOfCountry(countryCode));
                      } else {
                        setStates([]);
                      }
                     
                    }}
                  >
                    {countries.map((country) => (
                   <MenuItem key={country.isoCode} value={country.name}>
                   {country.name}
                    </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: '48%' }} fullWidth margin="normal">
                  <InputLabel id="org-select-label">State</InputLabel>
                  <Select
                    labelId="org-select-label"
                    label="State"
                    id="-select"
                    value={editLocationData.state}
                    onChange={(e) => {
                      const stateName = e.target.value;
                      setEditLocationData({
                        ...editLocationData,
                        state:stateName
                      })
                    }}
                    
                  >
                   {states.map((state) => (
                <MenuItem key={state.isoCode} value={state.name}>
                     {state.name}
              </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  </div>
                  
                  <div>
                    <TextField
                      label="City"
                      value={editLocationData.city}
                      onChange={(e) =>
                        setEditLocationData({
                          ...editLocationData,
                          city: e.target.value,
                        })
                      }
                      fullWidth
                      margin="normal"
                      style={{ marginRight: '10px', width: '48%' }}
                    />
                    <TextField
                      label="ZIP Code"
                      value={editLocationData.zip_code}
                      onChange={(e) =>
                        setEditLocationData({
                          ...editLocationData,
                          zip_code: e.target.value,
                        })
                      }
                      fullWidth
                      margin="normal"
                      style={{ marginLeft: '10px', width: '48%' }}
                    />
                  </div>
                  <div>
                  <TextField
                    label="Latitude"
                    value={editLocationData.latitude}
                    onChange={(e) =>
                      setEditLocationData({
                        ...editLocationData,
                        latitude: e.target.value,
                      })
                    }
                    // fullWidth
                    margin="normal"
                    style={{ marginRight: '10px', width: '48%' }}
                  />
                  <TextField
                    label="Longitude"
                    value={editLocationData.longitude}
                    onChange={(e) =>
                      setEditLocationData({
                        ...editLocationData,
                        longitude: e.target.value,
                      })
                    }
                    // fullWidth
                    margin="normal"
                    style={{ marginLeft: '10px', width: '48%' }}
                  />
                  </div>
                  <FormControl style={{ width: '48%' }} fullWidth margin="normal">
                  <InputLabel id="org-select-label">Status</InputLabel>
                  <Select
                    labelId="org-select-label"
                    label="Status"
                    id="org-select"
                    value={editLocationData.status}
                    onChange={(e) => {
                      setEditLocationData({
                        ...editLocationData,
                        status: e.target.value,
                      })
                    }}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
                  <div className={style.modalActions}>
                    <button
                      className={style.locSub}
                      variant="contained"
                      onClick={handleEditLocation}
                    >
                      UPDATE
                    </button>
                    <button
                      className={style.locClose}
                      variant="outlined"
                      onClick={() => setEditLocationModalOpen(false)}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </Paper>
            </Modal>
          </div>

          {/* ------------- Alert modals ------------- */}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={orgSuccess || orgDeletion || orgUpdation}
            sx={{ width: "20rem" }}
            autoHideDuration={3000}
            onClose={() => {
              setOrgSuccess(false);
              setOrgDeletion(false);
              setOrgUpdation(false);
            }}
          >
            <Alert
              severity="success"
              variant="filled"
              sx={{ width: "100%", fontSize: "1.15rem" }}
            >
              {orgSuccess
                ? "Location added successfully"
                : orgDeletion
                  ? "Location deleted successfully"
                  : orgUpdation
                    ? "Location updated successfully"
                    : ""}
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={orgFail}
            sx={{ width: "20rem" }}
            autoHideDuration={3000}
            onClose={() => {
              setOrgFail(false);
            }}
          >
            <Alert
              variant="filled"
              severity="error"
              sx={{ width: "100%", fontSize: "1.15rem" }}
            >
              {apiDescription}
            </Alert>
          </Snackbar>

          <div className={style.deleteBox}>
            <Dialog
              open={deleteConfirmation}
              onClose={() => setDeleteConfirmation(false)}
            >
              <DialogTitle>{"Delete Location?"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this location?
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }}>
                <button
                  className={style.delConfirm}
                  onClick={confirmDeleteLocation}
                  autoFocus
                >
                  Confirm
                </button>
                <button
                  className={style.delCancel}
                  onClick={() => setDeleteConfirmation(false)}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}

    </div>
  );
}

export default Locations;
