import React, { useState, useEffect, useRef } from "react";
import style from "./Delivery.module.css";
import { IoSearch, IoFilter } from "react-icons/io5";
import {
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  TablePagination,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { useOutletContext } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";


const Delivery = () => {

  const { selectedNewHub } = useOutletContext();

  const navigate = useNavigate()
  const sortRef = useRef(null)

  const token = JSON.parse(localStorage.getItem("access-token"));

  const [deliveryData, setDeliveryData] = useState([])
  const [filterMenuItem, setFilterMenuItem] = useState([])
  const [sortValueId, setSortValueId] = useState(0);
  const [sortListShow, setSortListShow] = useState(false);
  const [searchValue, setSearchVAlue] = useState("")
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [modalOpen, setModalOpen] = useState(false);
  const [assetData, setAssetData] = useState([])
  const [deliveryIdForMap, setDeliveryIdForMap] = useState("")
  const [selectedAssets, setSelectedAssets] = useState([])

  const convertDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const handleSortValue = (value) => {
    setSortValueId(value);
    deliveryList(selectedNewHub, searchValue, fromDate, toDate, value)

  };

  async function filterItems() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify({
        order: "delivery order"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const updatedData = [
          { status_code: 0, status_name: "None" },
          ...data.data
        ];
        setFilterMenuItem(updatedData);

      });
  }

  async function listItemAssets() {
    await fetch(`${baseUrl}/list/item/assets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub
      })
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'asset list');
        if (data.status === true) {
          setAssetData(data.data)
          setModalOpen(true);
          console.log(data.data, 'assetData');
        }
      })
  }

  async function deliveryAssetsMap() {
    const reqData = {
      delivery_order_id: deliveryIdForMap,
      asset_id: selectedAssets.map(asset => asset.itemID)
    }
    await fetch(`${baseUrl}/delivery/order/assets/map`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'asset list');
        if (data.status === true) {
          setDeliveryData(data.data)
        }
      })
  }

  async function deliveryList(hubId, search, from, to, sort) {
    if (from && !to) {
      to = new Date().toISOString().split('T')[0]; // Set current date to t_date in YYYY-MM-DD format
      setToDate(to)
    }
    const body = {
      hub_id: hubId ? hubId : "",
      search: search ? search : "",
      from_date: from ? from : "",
      to_date: to ? to : "",
      sort: sort ? sort : 0

    }

    await fetch(`${baseUrl}/delivery/order/lists`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'delivery');
        if (data.status === true) {
          setDeliveryData(data.data)
        }
      })
  }

  useEffect(() => {
    if (selectedNewHub) {
      deliveryList(selectedNewHub);
      filterItems()
    }

  }, [selectedNewHub])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortListShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Delivery</p>
        </div>
        <div className={style.headerActions}>
          <div className={style.dateSection}>
            <div className={style.dateSectionLeft}>
              <label>From </label>
              <input
                type="date"
                placeholder=""
                className={style.dateInput}
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)
                  if (newFromDate !== "") {
                    deliveryList(selectedNewHub, searchValue, newFromDate, toDate)
                  } else {
                    deliveryList(selectedNewHub, searchValue, "", toDate)
                  }
                }}
              />
            </div>
            <div className={style.dateSectionRight}>
              <label>To</label>
              <input
                type="date"
                placeholder=""
                className={style.dateInput}
                value={toDate}
                onChange={(e) => {
                  const newToDate = e.target.value
                  setToDate(newToDate)
                  if (newToDate !== "") {
                    deliveryList(selectedNewHub, searchValue, fromDate, newToDate)
                  } else {
                    deliveryList(selectedNewHub, searchValue, fromDate, "")
                  }
                }}
              />
            </div>
          </div>
          <div className={style.filterSection}>
            <IoFilter onClick={(e) => {
              setSortListShow(!sortListShow);
              e.stopPropagation()
            }} />
            {sortListShow && (
              <div ref={sortRef} className={style.sortValueContainer}>
                {filterMenuItem.map((list, i) => {
                  return (
                    <div
                      className={style.sortValue}
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleSortValue(list.status_code);
                      }}
                    >
                      {sortValueId === list.status_code ? (
                        <div className={style.activeSortValue}>
                          <FaCheck />
                          <p>{list.status_name}</p>
                        </div>
                      ) : (
                        <div className={style.nonActiveSortValue}>
                          <p>{list.status_name}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={style.search}>
            <IoSearch className={style.searchIcon} />
            <input
              type="text"
              placeholder=""
              value={searchValue}
              onChange={(e) => {
                const newValue = e.target.value
                setSearchVAlue(newValue);
                if (newValue !== "") {
                  deliveryList(selectedNewHub, newValue)
                } else {
                  deliveryList(selectedNewHub)
                }

              }}
              className={style.searchInput} />
          </div>
        </div>
      </div>
      <div className={style.tableSection}>
        <TableContainer className={style.table} component={Paper}>
        {deliveryData && deliveryData.length > 0 ? (
          <Table aria-label="caption table">
            <TableHead className={style.tableHead}>
              <TableRow>
                <TableCell align="left">Order Number</TableCell>
                <TableCell align="left">Customer</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell>Delivery Date</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Delivery status</TableCell>
                <TableCell align="left">Staff</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
                {deliveryData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{data.delivery_order_number}</TableCell>
                    <TableCell align="left">{data.customer_name}</TableCell>
                    <TableCell align="left">{data.net_amount}</TableCell>
                    <TableCell component="th" scope="row">
                      {convertDateFormat(data.order_date.$date)}
                    </TableCell>
                    <TableCell align="left">{data.customer_address[0]?.address1}</TableCell>
                    <TableCell align="left">
                      {data.status_name}
                    </TableCell>
                    <TableCell align="left">{data.staff_details?.firstname}</TableCell>
                    <TableCell align="left">
                      <button
                        className={style.detailButton}
                        onClick={() => {
                          navigate("/delivery/details", {
                            state: {
                              delivery_id: data.delivery_order_id
                            }
                          });
                        }}
                      >
                        Details
                      </button>
                    </TableCell>
                    
                  </TableRow>
                ))}
              
            </TableBody>
          </Table>
          ) : (
            <Alert severity="error" color="warning">
            No orders found..!
          </Alert>
          )}
        </TableContainer>

      </div>

      <Modal
        open={modalOpen}
        aria-labelledby="create-order-modal"
        aria-describedby="create-order-form"
      >
        <Paper className={style.modal} elevation={6}>
          <h2 id="create-order-modal">Map Assets</h2>
          <div className={style.modalContent}>
            <Box sx={{ minWidth: 200 }}>
              <Autocomplete
              multiple
                id="asset-select"
                options={assetData}
                getOptionLabel={(option) => option.ItemName}
                value={selectedAssets}
                onChange={(event, newValue) => {
                  setSelectedAssets(newValue);  // Update with entire object
                }}
                isOptionEqualToValue={(option, value) => option.itemID === value.itemID}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an Asset"
                    variant="outlined"
                    size="small"
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&:hover fieldset": {
                        borderColor: "#794a57",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <div className={style.modalActions}>
              <button
                variant="contained"
                onClick={()=>{
                  setModalOpen(false);
                  deliveryAssetsMap()
                }}
                className={style.EditSub}
              >
                Confirm
              </button>
              <button
                variant="outlined"
                onClick={() => {
                  setModalOpen(false);
                }}
                className={style.EditClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default Delivery;
