export const dateTimeISOConverter = (originalDateString) => {
  let formattedDateString = '';
  if (originalDateString !== "" && originalDateString !== undefined) {
    const originalDate = new Date(originalDateString);
    const targetYear = originalDate.getFullYear();
    const targetMonth = originalDate.getMonth() + 1; 
    const targetDay = originalDate.getDate(); 
    formattedDateString = `${targetYear}-${padZero(targetMonth)}-${padZero(targetDay)}`;
  }
  return formattedDateString;
}

  export const datetimeReadable =  (timestampStr) =>{

    var timestamp = new Date(timestampStr);
    var year = timestamp.getFullYear().toString().slice(-2);
    var month = ('0' + (timestamp.getMonth() + 1)).slice(-2); 
    var day = ('0' + timestamp.getDate()).slice(-2);
    var formattedDate = day + '/' + month + '/' + year ;
    return formattedDate;

  }

  function padZero(num) {
    return num.toString().padStart(2, '0');
  }

  export function generateRandomAlphaNumeric() {
    const timestamp = Date.now(); 
    const randomNum = Math.floor(Math.random() * 1000000); 
    const combined = `${timestamp}${randomNum}`;
    const base36String = parseInt(combined).toString(36);
    const result = base36String.slice(0, 10);
    return result;

  }

  export function customRound(value, decimalPlaces) {
    let multiplier = 10 ** decimalPlaces;
    return Math.round(value * multiplier) / multiplier;
  }
  

