import React, { useState, useEffect } from "react";
import style from "./Slots.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import dayjs from "dayjs";
import { baseUrl } from "../Url";
import { useOutletContext } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

const Slots = () => {
  const { selectedNewHub } = useOutletContext();

  const token = JSON.parse(localStorage.getItem("access-token"));

  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [dates, setDates] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [slotData, setSlotData] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSlotsByDate, setSelectedSlotsByDate] = useState({});

  const minDate = dayjs().startOf("month");

  const getAllDatesInMonth = (month) => {
    const startOfMonth = dayjs(month).startOf("month");
    const endOfMonth = dayjs(month).endOf("month");
    const datesArray = [];

    let currentDate = startOfMonth;

    while (currentDate.isBefore(endOfMonth) || currentDate.isSame(endOfMonth)) {
      datesArray.push(currentDate.toDate());
      currentDate = currentDate.add(1, "day");
    }

    return datesArray;
  };

  const handlePrevious = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 1, dates.length - 1));
  };

  const handleSlot = (slotId) => {
    const selectedDateKey = selectedDate; 
    setSelectedSlotsByDate((prevSelectedSlotsByDate) => {
      const selectedSlotsForDate = prevSelectedSlotsByDate[selectedDateKey] || []; 
      if (selectedSlotsForDate.includes(slotId)) {
       
        return {
          ...prevSelectedSlotsByDate,
          [selectedDateKey]: selectedSlotsForDate.filter((id) => id !== slotId),
        };
      } else {
        return {
          ...prevSelectedSlotsByDate,
          [selectedDateKey]: [...selectedSlotsForDate, slotId],
        };
      }
    });
  };

  const getSlotCountForDate = (date) => {
    const dateKey = dayjs(date).format('DD-MM-YYYY'); 
    const selectedSlotsForDate = selectedSlotsByDate[dateKey] || [];
    return selectedSlotsForDate.length;
  };

  const formatTimeTo12Hour = (time24) => {
    let [hours, minutes] = time24.split(':');
    let period = 'AM';
  
    hours = parseInt(hours, 10);
  
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) hours -= 12;
    }
  
    if (hours === 0) {
      hours = 12;
    }
  
    return `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  const handleDateChange = (date) =>{
    setSelectedDate(dayjs(date).format("DD-MM-YYYY"));
    slotListByDate(dayjs(date).format("DD-MM-YYYY"));
  }
  
  async function slotList() {
    await fetch(`${baseUrl}/slot/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setSlotData(data.data);
        }
      });
  }

  async function slotListByDate(date) {
    await fetch(`${baseUrl}/slot/list/status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub,
        selected_date:date
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setSlotData(data.data);
          const activeSlots = data.data.filter(slot => slot.status === "active");
          const activeSlotIds = activeSlots.map(slot => slot.slot_id);
          setSelectedSlotsByDate((prevSelectedSlotsByDate) => {
            return {
              ...prevSelectedSlotsByDate,
              [date]: activeSlotIds,
            };
          });
        }
      });
  }

  async function slotCreate() {
    const selectedSlots = selectedSlotsByDate[selectedDate] || [];
    if(selectedSlots.length<1){
      setValidationFail(true)
      setErrorMessage("Select at least one slot")
    }
    else{
    await fetch(`${baseUrl}/create/selected/slots`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        selected_date: selectedDate,
        slot_id: selectedSlots,
        hub_id: selectedNewHub,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCreateSuccess(true)
          slotListByDate(selectedDate)
        }
      });
  }
}


  useEffect(() => {
    if (selectedMonth) {
      const newDates = getAllDatesInMonth(selectedMonth);
      setDates(newDates);
      const todayIndex = newDates.findIndex((date) =>
        dayjs(date).isSame(dayjs(), "day")
      );
      setStartIndex(todayIndex >= 0 ? todayIndex : 0);
      setSelectedDate(dayjs().format("DD-MM-YYYY"));
      slotListByDate(dayjs().format("DD-MM-YYYY"))
    }
  }, [selectedMonth,selectedNewHub]);
  useEffect(() => {
    if (selectedNewHub) {
      setSelectedSlotsByDate({});
      if (selectedDate) {
        slotListByDate(selectedDate);
      }
    }
  }, [selectedNewHub]);
  

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "Slot created successfully"
            : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <div className={style.header}>
        <div className={style.heading}>
          <p>Slots</p>
        </div>
        <div className={style.headerButton}>
          <button onClick={()=>slotCreate()}>Submit</button>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.calendarContainer}>
          <div className={style.calendarHeader}>
            <div className={style.monthSelection}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'"month" and "year"'}
                  views={["month", "year"]}
                  value={selectedMonth}
                  onChange={(newMonth) => setSelectedMonth(newMonth)}
                  minDate={minDate}
                />
              </LocalizationProvider>
            </div>
            <div className={style.navigateButton}>
              <button title="Previous Date" onClick={handlePrevious}>
                <FaAngleLeft />
              </button>
              <button title="Next Date" onClick={handleNext}>
                <FaAngleRight />
              </button>
            </div>
          </div>
          <div className={style.dateContainer}>
            <div className={style.dateCarousel}>
              {dates.slice(startIndex, startIndex + 7).map((date, index) => (
                <div
                  onClick={() => {
                    if (!dayjs(date).isBefore(dayjs(), "day")) {
                      handleDateChange(date);
                    }
                  }}
                  key={index}
                  className={
                    dayjs(date).isBefore(dayjs(), "day")
                      ? style.disabledDateCard
                      : selectedDate === dayjs(date).format("DD-MM-YYYY")
                      ? style.selectedDateCard
                      : style.dateCard
                  }
                >
                  <div className={style.dateCardHeader}>
                    {dayjs(date).format("ddd")}
                  </div>
                  <div className={style.dateCardBody}>
                    {dayjs(date).format("DD")}
                  </div>
                  <div className={style.dateCardSlot}>
                    <p>{getSlotCountForDate(date)} Slot</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={style.slotSection}>
            <div className={style.slotContainer}>
              {slotData && slotData.length > 0
                ? slotData.map((slot) => {
                  const formattedStart = formatTimeTo12Hour(slot.slot_start);
                  const formattedEnd = formatTimeTo12Hour(slot.slot_end);
                    return (
                      <div
                        key={slot.slot_id}
                        onClick={() => handleSlot(slot.slot_id)}
                        className={
                          selectedSlotsByDate[selectedDate]?.includes(slot.slot_id)
                            ? style.selectedSlotCard
                            : style.slotCard
                        }
                      >
                        <p> {`${formattedStart} - ${formattedEnd}`}</p>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slots;
