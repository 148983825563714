import React, { useState, useEffect } from "react";
import style from "./TaxGroup.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Alert,
  Snackbar,
  OutlinedInput,
} from "@mui/material";
import { baseUrl } from "../Url";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";

const TaxGroup = () => {
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [groupStatus, setGroupStatus] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [editGroup, setEditGroup] = useState({
    tax_group_id: "",
    tax_group_name: "",
    tax_group_description: "",
    tax_segment_code: "",
    tax_group_status: "",
    tax_group_percentage:""
  });
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupPercentage, setGroupPercentage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const createValidation = () => {
    if (!groupName) {
      setErrorMessage("Group name is required");
      return false;
    }
    if (!groupDesc) {
      setErrorMessage("Group description is required");
      return false;
    }
    if (!groupPercentage) {
      setErrorMessage("Tax percentage is required");
      return false;
    }
    if (!selectedSegment) {
      setErrorMessage("Segment is required");
      return false;
    }
    if (!groupStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  }

  async function segmentList() {
    const reqData = {
      source: "web",
    };
    await fetch(`${baseUrl}/tax/segment/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSegmentData(data.data);
        }
      });
  }

  async function createGroup() {
    const reqData = {
      tax_group_name: groupName,
      tax_group_description: groupDesc,
      tax_segment_code: selectedSegment,
      tax_group_status: groupStatus,
      tax_group_percentage:groupPercentage
    };
    if (!createValidation()) {
      setValidationFail(true);
      return
    }
    else {
    await fetch(`${baseUrl}/tax/group/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCreatePopup(false);
          GroupList();
          setCreateSuccess(true);
          setGroupDesc("");
          setGroupName("");
          setGroupStatus("");
          setSelectedSegment("");
        }
      });
    }
  }

  async function GroupList() {
    const reqData = {
      source: "web",
    };
    await fetch(`${baseUrl}/tax/group/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setGroupData(data.data);
        }
      });
  }

  async function updateGroup() {
    const reqData = {
      tax_group_id: editGroup.tax_group_id,
      tax_group_name: editGroup.tax_group_name,
      tax_group_description: editGroup.tax_group_description,
      tax_segment_code: editGroup.tax_segment_code,
      tax_group_status: editGroup.tax_group_status,
      tax_group_percentage:editGroup.tax_group_percentage
    };
    await fetch(`${baseUrl}/tax/group/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setEditPopup(false);
          GroupList();
          setUpdateSuccess(true);
        }
      });
  }

  async function deleteGroup() {
    const reqData = {
      tax_group_id: groupId,
    };
    await fetch(`${baseUrl}/tax/group/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          GroupList();
          setDeleteSuccess(true);
          setGroupId("");
        }
      });
  }

  useEffect(() => {
    segmentList();
    GroupList();
  }, [token]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess || updateSuccess || deleteSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
          setUpdateSuccess(false);
          setDeleteSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "Group created successfully"
            : updateSuccess
            ? "Group updated successfully"
            : deleteSuccess
            ? "Group deleted successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Tax Groups</p>
        </div>
        <div className={style.headerAction}>
          <div className={style.addButton}>
            <button
              onClick={() => {
                setCreatePopup(true);
              }}
            >
              Add Tax group
            </button>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.bodySection}>
          <div className={style.listTableHeader}>
            <div className={style.listTableHeadName}>
              <p className={style.listTableHeaderText}>Name</p>
            </div>
            <div className={style.listTableDescription}>
              <p className={style.listTableHeaderText}>Description</p>
            </div>
            <div className={style.listTablePercentage}>
              <p className={style.listTableHeaderText}>Tax %</p>
            </div>
            <div className={style.listTableSegment}>
              <p className={style.listTableHeaderText}>Segment</p>
            </div>
            <div className={style.listTableStatus}>
              <p className={style.listTableHeaderText}>Status</p>
            </div>
            <div className={style.listTableHeadActions}>
              <p className={style.listTableHeaderText}>Actions</p>
            </div>
          </div>
          <div className={style.listTableBody}>
            {groupData && groupData.length > 0
              ? groupData.map((item, i) => {
                  return (
                    <div key={i} className={style.listTableRow}>
                      <div className={style.listTableDataName}>
                        <p className={style.listTableDataText}>
                          {item.tax_group_name}
                        </p>
                      </div>
                      <div className={style.listTableDataDescription}>
                        <p className={style.listTableDataText}>
                          {item.tax_group_description}
                        </p>
                      </div>
                      <div className={style.listTableDataPercentage}>
                        <p className={style.listTableDataText}>
                        {item.tax_group_percentage}
                        </p>
                      </div>
                      <div className={style.listTableDataSegment}>
                        <p className={style.listTableDataText}>
                          {item.tax_segment_code}
                        </p>
                      </div>
                      <div className={style.listTableDataStatus}>
                        <p className={style.listTableDataText}>
                          {item.tax_group_status === "active" ? (
                            <FaRegCircleCheck
                              style={{ color: "green", fontSize: "1.35rem" }}
                            />
                          ) : item.tax_group_status === "inactive" ? (
                            <IoIosCloseCircleOutline
                              style={{ color: "red", fontSize: "1.5rem" }}
                            />
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                      <div className={style.listTableDataActions}>
                        <div className={style.listTableDataIcons}>
                          <BiEditAlt
                            onClick={() => {
                              setEditPopup(true);
                              setEditGroup(item);
                            }}
                          />
                          <RiDeleteBinLine
                            onClick={() => {
                              setDeletePopup(true);
                              setGroupId(item.tax_group_id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
      {createPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Create Tax Group</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setCreatePopup(false);
                    setGroupDesc("");
                    setGroupName("");
                    setGroupStatus("");
                    setSelectedSegment("");
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={groupName}
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                label="Tax group name"
                fullWidth
                margin="normal"
              />

              <TextField
                value={groupDesc}
                onChange={(e) => {
                  setGroupDesc(e.target.value);
                }}
                label="Tax group description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={groupPercentage}
                onChange={(e) => {
                  setGroupPercentage(e.target.value);
                }}
                label="Tax percentage"
                fullWidth
                margin="normal"
              />
              <TextField
                value={groupStatus}
                onChange={(e) => {
                  setGroupStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <TextField
                select
                label="Select Segment"
                fullWidth
                margin="normal"
                value={selectedSegment}
                onChange={(e) => {
                  setSelectedSegment(e.target.value);
                }}
              >
                {segmentData.map((segment, i) => {
                  return (
                    <MenuItem key={i} value={segment.tax_segment_code}>
                      {segment.tax_segment_name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    createGroup();
                  }}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                    setGroupDesc("");
                    setGroupName("");
                    setGroupStatus("");
                    setSelectedSegment("");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/*Edit Popup */}
      {editPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Edit Tax Group</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setEditPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={editGroup.tax_group_name}
                onChange={(e) => {
                  setEditGroup({
                    ...editGroup,
                    tax_group_name: e.target.value,
                  });
                }}
                label="Tax group name"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editGroup.tax_group_description}
                onChange={(e) => {
                  setEditGroup({
                    ...editGroup,
                    tax_group_description: e.target.value,
                  });
                }}
                label="Tax group description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editGroup.tax_group_percentage}
                onChange={(e) => {
                  setEditGroup({
                    ...editGroup,
                    tax_group_percentage: e.target.value,
                  });
                }}
                label="Tax percentage"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editGroup.tax_group_status}
                onChange={(e) => {
                  setEditGroup({
                    ...editGroup,
                    tax_group_status: e.target.value,
                  });
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <TextField
                select
                label="Select Segment"
                fullWidth
                margin="normal"
                value={editGroup.tax_segment_code}
                onChange={(e) => {
                  setEditGroup({
                    ...editGroup,
                    tax_segment_code: e.target.value,
                  });
                }}
              >
                {segmentData.map((segment, i) => {
                  return (
                    <MenuItem key={i} value={segment.tax_segment_code}>
                      {segment.tax_segment_name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    updateGroup();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setEditPopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* Delete popup*/}
      {deletePopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.deletePopup}>
            <div className={style.deletePopupHeader}>
              <div className={style.deletePopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setDeletePopup(false);
                    setGroupId("")
                  }}
                />
              </div>
            </div>
            <div className={style.deletePopupSection}>
              <div className={style.deletePopupText}>
                <p>Are you sure want to delete?</p>
              </div>
              <div className={style.deletePopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    deleteGroup();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.closeButton}
                  onClick={() => {
                    setDeletePopup(false);
                    setGroupId("")
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TaxGroup;
