import React, { useState, useEffect } from "react";
import style from "./FeatureManage.module.css";
import { baseUrl } from "../Url";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  Modal,
  TextField,
  Paper,
  Box,
  Table,
  Alert,
  Snackbar,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

function FeatureManage() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [featureName, setFeatureName] = useState("");
  const [description, setDescription] = useState("");
  const [apiDescription, setApiDescription] = useState("");
  const [featureSuccess, setFeatureSuccess] = useState(false);
  const [featureFail, setFeatureFail] = useState(false);
  const [items, setItems] = useState([]);

  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    clearForm();
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/features/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const data = await response.json();
      setItems(data.data);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/feature/creation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          feature_name: featureName,
          description: description,
        }),
      });

      const data = await response.json();
      if (data.status === true) {
        setItems([...items, data.data]);
        setFeatureSuccess(true);
        setApiDescription("Feature added successfully");
        fetchData();
        handleCloseAddModal();
      } else {
        console.error("Error adding feature:", data.description);
        setApiDescription(data.description);
        setFeatureFail(true);
      }
    } catch (error) {
      console.error("Error adding feature:", error);
    }
  }

  const clearForm = () => {
    setFeatureName("");
    setDescription("");
   
  };

  return (
    <>
      <div className={style.snackBar}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={featureSuccess}
          sx={{ width: "20rem" }}
          autoHideDuration={3000}
          onClose={() => {
            setFeatureSuccess(false);
          }}
        >
          <Alert
            severity="success"
            variant="filled"
            sx={{ width: "100%", fontSize: "1.15rem" }}
          >
            {apiDescription}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={featureFail}
          sx={{ width: "20rem" }}
          autoHideDuration={3000}
          onClose={() => {
            setFeatureFail(false);
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            sx={{ width: "100%", fontSize: "1.15rem" }}
          >
            {apiDescription}
          </Alert>
        </Snackbar>
      </div>

      {/* Add Feature Modal */}
      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modalBox}>
          <h2 id="add-feature-modal">Add Feature</h2>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Feature"
              margin="normal"
              variant="outlined"
              required
              value={featureName}
              onChange={(e) => setFeatureName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              variant="outlined"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className={style.modalActions}>
              <button className={style.modalSub} type="submit">
                Submit
              </button>
              <button className={style.modalClose} onClick={handleCloseAddModal}>
                Close
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <div className={style.container}>
        <div className={style.buttonSection}>
          <button onClick={handleOpenAddModal}>Add Features</button>
        </div>

        <div className={style.tableBody}>
          <TableContainer className={style.table} component={Paper}>
            <div className={style.tableHeading}>Features</div>

            <Table>
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.feature_id}>
                    <TableCell>{item.feature_name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      <div className={style.actionIcons}>
                        <div className={style.editbtn}>
                          <BiEditAlt
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className={style.deletebtn}
                        >
                          <RiDeleteBinLine
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default FeatureManage;
