import React, { useState, useEffect,useRef } from "react";
import style from "./Order.module.css";
import carousel from "./Carousel.module.css";
import styles from "./ManageOrder.module.css"
import {
  dateTimeISOConverter,
  generateRandomAlphaNumeric,
  customRound,
} from "./Utils";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight,FaCheck } from "react-icons/fa";
import {
  Button,
  Modal,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Typography,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Alert,
  Snackbar,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Autocomplete
} from "@mui/material";

import { useLocation, useParams, } from "react-router-dom";
import { baseUrl } from "../Url";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdEdit ,MdClose} from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import DatePicker from 'react-multi-date-picker';
import dayjs from 'dayjs';

const ManageOrder = () => {
  const navigate = useNavigate();
  const sortRef=useRef(null)

  const { selectedNewHub } = useOutletContext();
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [modalOpen, setModalOpen] = useState(false);
  const [customers, setCustomer] = useState([
    {
      customer_id: "",
      customer_name: "",
    },
  ]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [items, setItems] = useState([]);
  const [carouselId, setCarouselId] = useState(0);
  const [tableItems, setTableItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderDetail, setOrderDetail] = useState({
    org_id: "",
    org_code: "",
    order_id: "",
    order_date: "",
    order_drafted_by: "",
    drafted_date: "",
    customer_code: "",
    gross_amount: 0,
    total_tax_amount: 0,
    invoice_discount_in_percentage: 0,
    invoice_discount_in_amount: 0,
    total_discount_amount: 0,
    round_off_value: 0,
    net_amount: 0,
    order_status: 1,
    internal_remark: "",
    customer_remark: "",
    order_reviewed_by: "",
    reviewed_date: "",
    order_approved_by: "",
    approved_date: "",
    type:"",
    scheduled_date:[],
    slot_id:"",
    table_no:"",
    order_details: [
      {
        item_id: "",
        item_code: "",
        item_name: "",
        quantity: "",
        selling_price: 0,
        discount_percentage:0,
        discount: 0,
        tax_percentage: 0,
        tax_amount: 0,
        total_amount: 0,
        taxInclusive:true,
        price_before_tax:0,
        item_media:[]
      },
    ],
  });
  const [orderSuccess,setOrderSuccess]=useState(false)
  const [orderUpdateSuccess,setOrderUpdateSuccess]=useState(false)
  const [sortListShow, setSortListShow] = useState(false);
  const[sortValue,setSortValue]=useState(null)
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [variantPopup,setVariantPopup]=useState(false)
  const [selectedProductData,setSelectedProductData]=useState({})
  const [showDetails, setShowDetails] = useState(false);
  const [filterMenuItem,setFilterMenuItem]=useState([])
  const [dates, setDates] = useState([]);
  const [slotData,setSlotData]=useState([])
  const [apiOrderStatus,setApiOrderStatus]=useState(0)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);  

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300, 
      },
    },
  };

  const parseDatesFromApiResponse = (dates) => {
    return dates.map(date => new Date(date));
};

  const handleDateChange = (selectedDates) => {
    console.log("api called");
    
    setDates(selectedDates);
    const formattedDates = selectedDates.map(date => dayjs(date).format('DD-MM-YYYY'));
    setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        scheduled_date: formattedDates,
    }));
};

  const handleSortValue = (value) => {
    setOrderDetail({
      ...orderDetail,
      order_status:value
    });
    const selectedValue = filterMenuItem.find((item) => item.status_code === value);
    setSortValue(selectedValue.status_name)
  };

  // let { orderId } = useParams();
  // const { state } = useLocation();
  // const orderId = state?.orderId;
  const location = useLocation();
  const { orderId } = location.state || {};

  useEffect(() => {
    total_calculation();
  }, [tableItems]);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    itemSearch(e.target.value);
  };

  useEffect(()=>{
    if(searchTerm!==''){
      itemSearch(searchTerm)
    }
    else{
      if(selectedCategory){
      menuItemsList(selectedCategory);
      }
      else{
        setItems([])
      }
    }
  },[searchTerm])

  useEffect(() => {
    filterItems()
   customerList();

    categoryList(selectedNewHub);
    if (orderId) {
      orderListing(orderId);
    } else {
      setModalOpen(true);
    }
  }, [selectedNewHub,orderId]);
  
  const rightHandle = () => {
    setCarouselId((prevCarouselId) =>
      prevCarouselId >= category.length - 3 ? 0 : prevCarouselId + 3
    );
  };

  const leftHandle = () => {
    setCarouselId((prevCarouselId) =>
      prevCarouselId <= 0 ? category.length - 3 : prevCarouselId - 3
    );
  };

  const handleClick = (clickedId) => {
    setSelectedCategory(clickedId);
    if (clickedId) {
      menuItemsList(clickedId);
    }
  };

  const handleCustomer = (customer_code) => {
    const selectedCustomer = customers.find(
      (customer) => customer.customer_id === customer_code
    );
    if (selectedCustomer) {
      setOrderDetail({
        ...orderDetail,
        customer_code: customer_code,
        customer_name: selectedCustomer.customer_name,
      });
    }
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("itemId", item.itemID.toString());
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const itemId = e.dataTransfer.getData("itemId");
    const droppedItem = items.find(
      (item) => item.itemID.toString() === itemId
    );
    handleVariantProduct(droppedItem)
  };

  const handleAddItem = (droppedItem) => {
    if (droppedItem) {
      const isItemInTable = tableItems.some(
        (item) => item.item_id === droppedItem.itemID
      );

      if (isItemInTable) {
        setValidationFail(true)
        setErrorMessage(`Item "${droppedItem.ItemName}${droppedItem.variant_name ? ` (${droppedItem.variant_name})` : ''}" is already added.`);

      } else {
        let cartItem = {};
        cartItem.item_id = droppedItem["itemID"];
        cartItem.item_code = droppedItem["item_code"];
        cartItem.item_name = droppedItem["ItemName"];
        cartItem.quantity = droppedItem["quantity"];
        cartItem.selling_price = droppedItem["SalePrice"];
        cartItem.discount = droppedItem["discount"];
        cartItem.discount_percentage=droppedItem["SaleDiscountPercentage"];
        cartItem.tax_percentage = droppedItem["SaleTaxPercentage"];
        cartItem.tax_amount = droppedItem["tax_amount"];
        cartItem.total_amount = droppedItem["total_amount"];
        cartItem.variant_name = droppedItem["variant_name"];
        cartItem.taxInclusive = droppedItem["taxInclusive"]
        cartItem.price_before_tax = droppedItem["price_before_tax"]
        cartItem.item_media=droppedItem["item_media"]
        console.log(cartItem,"cartItem");
        let newItem = priceCalculation(cartItem, 1);
        setTableItems((prevItems) => [...prevItems, newItem]);
      }
    }
  };

  const handleRemoveItem = (itemId) => {
    const updatedTableItems = tableItems.filter(
      (item) => item.item_id !== itemId
    );
    setTableItems(updatedTableItems);
  };

  const priceCalculation = (item, newQuantity) => {
    const itemprice = parseFloat(item.selling_price >= 0 ? item.selling_price : 0);
    const tax_percentage = parseFloat(item.tax_percentage >= 0 ? item.tax_percentage/ 100 : 0);
    const discount_percentage = parseFloat(item.discount_percentage >= 0 ? item.discount_percentage/ 100 : 0);
    const discount_amount = customRound(itemprice*discount_percentage,2);
    let price_before_tax;
    let tax_amount;

    if(item.taxInclusive===true){
      const discount_unit_price=itemprice-discount_amount
      price_before_tax = discount_unit_price/(1+tax_percentage)
      tax_amount = customRound(price_before_tax*tax_percentage,2)
    }
    else{
      price_before_tax = itemprice-discount_amount;
      tax_amount = customRound(price_before_tax*tax_percentage,2)
    }

    const total_amount = (price_before_tax + tax_amount)
    const total_amount_for_quantity = customRound(total_amount * newQuantity, 2);

    const newItem = {
      ...item,
      tax_amount: tax_amount,
      total_amount: total_amount_for_quantity,
      quantity: newQuantity,
      selling_price: itemprice,
      discount:discount_amount,
      price_before_tax:price_before_tax
    };
    return newItem;
  };

  const total_calculation = () => {
    const now = new Date();
    let tax_total = 0;
    let net_total = 0;
    let sub_total =0;
    let discount_total=0
    tableItems.forEach((item) => {
      if (parseInt(item.total_amount) > 0) {
        net_total += item.total_amount;
      }
      if (parseInt(item.tax_amount) > 0) {
        tax_total += item.tax_amount;
      }
      if(parseInt(item.price_before_tax)>0){
        sub_total+=item.price_before_tax
      }
      if(parseInt(item.discount)>0){
        discount_total+=item.discount
      }
    });
    setOrderDetail({
      ...orderDetail,
      gross_amount: customRound(sub_total, 2),
      total_tax_amount: customRound(tax_total, 2),
      net_amount: customRound(net_total, 2),
      total_discount_amount:customRound(discount_total,2),
      org_id: selectedNewHub,
      order_date: dateTimeISOConverter(now),
      drafted_date: dateTimeISOConverter(now),
      order_details: tableItems,
      order_drafted_by: localStorage.getItem("adminUserId"),
    });
  };

  const handleQtyChange = (itemId, newQty) => {
    const updatedItems = tableItems.map((item) => {
      if (item.item_id === itemId) {
        return priceCalculation(item, newQty);
      }
      return item;
    });
    setTableItems(updatedItems);
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleVariantProduct=(product)=>{
    console.log(product,"product");
    if(product.IsVariantsExist===true){
      setVariantPopup(true);
      setSelectedProductData(product)
    }
    else{
      handleAddItem({
        itemID: product.itemID,
        item_code: product.item_code,
        ItemName: product.ItemName,
        quantity: 1, 
        SalePrice: product.SalePrice,
        SaleDiscountPercentage: product.SaleDiscountPercentage,
        SaleTaxPercentage: product.SaleTaxPercentage,
        tax_amount: 0, 
        total_amount: 0,
        variant_name: "",
        discount:0,
        taxInclusive:product.product_tax_type,
        price_before_tax:0,
        item_media:[product.media?.[0]?.MediaThumbnail]
      });
    }
  }

  const handleClose = () => {
    setVariantPopup(false);
    setSelectedProductData({});
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const formatTimeTo12Hour = (time24) => {
    let [hours, minutes] = time24.split(':');
    let period = 'AM';
    hours = parseInt(hours, 10);
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  async function hubGet() {
    await fetch(`${baseUrl}/organizations/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          const selectedOrganization = data.data.find(
            (org) => org.org_id === selectedNewHub
          );
          if (selectedOrganization) {
            setOrderDetail((prevState) => ({
              ...prevState,
              org_code: selectedOrganization.org_code,
            }));
          }
         
        }
      })
  }

  async function filterItems() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body:JSON.stringify({
        order:"order"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setFilterMenuItem(data.data);
       
      });
  }
  /*
    Function:loadCategory
    Description: Fetch Category List
  */
  async function loadCategory() {
    const reqData = {
      org_id: `${selectedNewHub}`,
      category_id: "",
    };
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/categories/list`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.data.unshift({ category_id: "All", name: "All" });

        let categoryitem = data.data;
        categoryitem = categoryitem.map((category) => ({
          ...category,
          image: "https://i.imgur.com/JRz924Z.jpeg",
        }));

        setCategory(categoryitem);
      });
  }
  async function categoryList() {
    const reqData = {
      org_id: selectedNewHub,
    };
    await fetch(`${baseUrl}/hub/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCategory(data.data);
        }
      });
  }

  async function slotList() {
    await fetch(`${baseUrl}/slot/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub
    })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setSlotData(data.data);
        }
      });
  }

  async function selectedSlotList(){
    const reqData = {
      hub_id: selectedNewHub,
      selected_date: orderDetail.scheduled_date
    }
    await fetch(`${baseUrl}/selected/slot/list`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
    .then((res)=>res.json())
    .then((data)=>{
      
      if(data.status === true){
        setSlotData(data.data)
      }
      else{
        setSlotData([])
      }
    })
  }
  /*
    Function:loadCustomer
    Description: Search Customer
  */
  async function customerList() {
    await fetch(`${baseUrl}/customer/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let customerlist = [];
        let datacustomer = data.data;
        for (var i in datacustomer) {
          customerlist.push({
            customer_id: datacustomer[i].customer_id,
            customer_name: datacustomer[i].customer_name,
          });
        }
        setCustomer(customerlist);
      });
  }
  /*
    Function:itemSearch
    Description: Search Items based on Category
  */
  async function itemSearch(search = "") {
    if (search === "") {
      search = searchTerm;
    }
    let cat_id = "All";
    if (selectedCategory !== "") {
      cat_id = selectedCategory;
    }
    const reqData = {
      search: search,
    };
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/items/search`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let dataitem = data.data;
        dataitem = dataitem.map((product) => ({
          ...product,
          item_code: product.ExternalItemCode,
          currentImage: product.media?.length > 0 ? product.media[0]?.MediaThumbnail : "https://i.imgur.com/vuTSHe3.png",
        }));
        setItems(dataitem);
      });
  }

  async function menuItemsList(categoryId) {
    if (categoryId === null || categoryId === undefined) {
        categoryId = 'All';
    }
    await fetch(`${baseUrl}/items/under/category`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            category_id: categoryId
        })
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.status === true) {
              if(data.data){
              let dataitem = data.data;
        dataitem = dataitem.map((product) => ({
          ...product,
          item_code: product.ExternalItemCode,
          currentImage: product.media.length > 0 ? product.media[0]?.MediaThumbnail : "https://i.imgur.com/vuTSHe3.png",
        }));
        setItems(dataitem);
            }
            else{
              setItems([])
            }
          }
        })
}
  /*
    Function:orderListing
    Description: fetch order by Id on edit
  */
  async function orderListing(orderId) {
    const reqData = {
      order_id: orderId,
    };
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/get`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.data){

        const parsedDates = parseDatesFromApiResponse(data.data.scheduled_date);
        setDates(parsedDates);
      // const updatedScheduledDates = data.data.scheduled_date?.map(date => dayjs(date).format('DD-MM-YYYY'));
      const updatedScheduledDates = data.data.scheduled_date?.map((dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      });
      const updatedOrderDetail = {
        ...data.data,
        scheduled_date: updatedScheduledDates
    };
    setApiOrderStatus(updatedOrderDetail.order_status)
    setOrderDetail(updatedOrderDetail);
    setTableItems(updatedOrderDetail.order_details);
  }
  else{
    setValidationFail(true)
    setErrorMessage("Something went wrong")
  }
      });
  }
  /*
    Function:saveOrder
    Description: save order
  */
  const saveOrder = async () => {
    if (orderDetail.order_id === "") {
      try {
        const storedToken = JSON.parse(localStorage.getItem("access-token"));
        const response = await fetch(`${baseUrl}/order/creation`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(orderDetail),
        });
        const data = await response.json();
        if (data.status === true) {
          setOrderSuccess(true)
          setTimeout(() => {
            navigate("/orders")
          }, 2000);
        }
      } catch (error) {
        console.error("Error adding order:", error);
      }
    } else {
      try {
        const storedToken = JSON.parse(localStorage.getItem("access-token"));
        const response = await fetch(`${baseUrl}/order/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(orderDetail),
        });
        const data = await response.json();
        if (data.status === true) {
          setOrderUpdateSuccess(true)
          setTimeout(() => {
            navigate("/orders")
          }, 2000);
        }
      } catch (error) {
        console.error("Error adding order:", error);
      }
    }
  };

  const handleAddOrder = async () => {
    if (orderDetail.customer_code !== "") {
      setModalOpen(false);
    } else {
      setValidationFail(true)
      setErrorMessage("Select Customer to Proceed");
    }
  };

  useEffect(()=>{
    if(category.length>0){
      setSelectedCategory(category[0]?.category_id)
      menuItemsList(category[0]?.category_id)
    }
  },[category])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target) ) {
        setSortListShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if ( filterMenuItem.length > 0&&orderId===undefined) {
      handleSortValue(filterMenuItem[0].status_code);
    }
    else if(filterMenuItem.length > 0 && orderDetail.order_status!==undefined){
      handleSortValue(orderDetail.order_status);
    }
  },[filterMenuItem]);

  useEffect(()=>{
    if(!orderId){

    if(orderDetail.type==="Instant"||orderDetail.type==="Dine in"){
      const currentDate = new Date().toISOString().split('T')[0]; 
      const updatedOrderDetail = {
        ...orderDetail,
        scheduled_date: [currentDate]  
      };
  
      setOrderDetail(updatedOrderDetail);  
    } 
    else {
      const updatedOrderDetail = {
        ...orderDetail,
        scheduled_date: []  
      };
  
      setOrderDetail(updatedOrderDetail);  
      setDates([])
    
     }
    

    if(orderDetail.type===""){
      setOrderDetail({
        ...orderDetail,
        type: "Dine in",
      });
    }
  }
  },[orderDetail.type])

  useEffect(()=>{
    hubGet()
  },[selectedNewHub])
  
  useEffect(() => {
    if(orderDetail.scheduled_date.length>0 || orderDetail.type==="Delivery"){
      selectedSlotList()
    }
  }, [orderDetail.scheduled_date])

  return (
    <div className={styles.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={orderSuccess||orderUpdateSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setOrderSuccess(false);
          setOrderUpdateSuccess(false)
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          {orderSuccess ? "Order created successfully"
          :orderUpdateSuccess?"Order updated successfully" : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Order Management</p>
        </div>
      </div>

      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box  sx={{ flex: 1, padding: 1 }} className={style.listcontainer}>
          <div
            onDrop={handleDrop}
            onDragOver={allowDrop}
            className={style.frame}
          >
            <TableContainer component={Paper}>
              <Card sx={{ borderRadius: 2, boxShadow: 3, }}>
                <CardContent style={{ display: "flex",flexDirection:"column"}}>
                <div style={{ display: "flex",alignItems:"center" ,justifyContent:'space-between'}}>
                    <div style={{ display: "flex",alignItems:"center",maxWidth: "57%" }}>
                      <Typography
                        sx={{fontFamily:"Poppins", color: "#59293a"}}
                        gutterBottom
                       
                        component="div"
                        color="text.secondary"
                      >
                        Customer: {orderDetail.customer_name}
                      </Typography>
                        <Typography sx={{ color: "#59293a" ,fontSize:'1rem',textAlign:"center",cursor:"pointer",paddingLeft:'1rem'}}> {orderId===undefined?<MdEdit onClick={()=>{
                          setModalOpen(true);
                        }}/>:''}</Typography>
                    </div>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="text.secondary"
                    >
                      <div className={styles.orderType}>
                                  <button
                                  disabled={orderId!==undefined}
                                    className={`${styles.orderTypeButton} ${orderDetail.type === "Dine in" ? styles.activeOrderButton : ""}`}
                                    onClick={() => {
                                      setOrderDetail({
                                        ...orderDetail,
                                        type: "Dine in",
                                      });
                                    }}
                                  >
                                    Dine in
                                  </button>
                                  <button
                                  disabled={orderId!==undefined}
                                   style={{margin:"0 12px"}}
                                    className={`${styles.orderTypeButton} ${orderDetail.type === "Instant" ? styles.activeOrderButton : ""}`}
                                    onClick={() => {
                                      setOrderDetail({
                                        ...orderDetail,
                                        type: "Instant",
                                      });
                                    }}
                                  >
                                    Instant
                                  </button>
                                  <button
                                  disabled={orderId!==undefined}
                                    className={`${styles.orderTypeButton} ${orderDetail.type === "Delivery" ? styles.activeOrderButton : ""}`}
                                    onClick={() => {
                                      setOrderDetail({
                                        ...orderDetail,
                                        type: "Delivery",
                                      });
                                    }}
                                  >
                                    Delivery
                                  </button>
                                </div>

                       
                    </Typography>
              </div>
              {/* 11 */}
              <div className={styles.orderTypeInputSection} >
                       {orderDetail.type==="Delivery"?
                        <div className={styles.dineIN}>
                         <div className={styles.dineINData}>
                          <p style={{width:"40%"}}>  Order Date:</p>
                            
                              <DatePicker 
                              style={{width:"100%"}}
                              multiple
                              value={dates}
                              onChange={handleDateChange}
                              minDate={today}
                              disabled={apiOrderStatus === 3 || apiOrderStatus === 4}
                              />
                           
                         </div>
                         <div className={styles.dineINData}>
                         <FormControl fullWidth size="small" sx={{ margin: "0 12px" }}>
                          <InputLabel>Select slot</InputLabel>
                          <Select
                            value={orderDetail.slot_id}
                            onChange={(e)=>{
                              setOrderDetail({
                                ...orderDetail,
                                slot_id:e.target.value
                              })
                            }}
                            label="Select slot"
                            MenuProps={menuProps}
                            disabled={apiOrderStatus === 3 || apiOrderStatus === 4}
                          >
                            {slotData.map((slot) => (
                              <MenuItem
                                key={slot.slot_id}
                                value={slot.slot_id}
                              
                              >
                                {`${ formatTimeTo12Hour(slot.slot_start)} - ${formatTimeTo12Hour(slot.slot_end)}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                         </div>
                      </div>
                      :orderDetail.type==="Dine in"?
                      <div className={styles.dineIN}>
                       
                      <div className={styles.dineINData}><p> Order Date: &nbsp;{orderDetail.scheduled_date[0]}</p></div>
                       
                        <div  className={styles.dineINData}>
                        Table no:<input disabled={apiOrderStatus === 3 || apiOrderStatus === 4} style={{border:"none",borderBottom:"1px solid",outline:"none",margin:"0 6px"}}
                         value={orderDetail.table_no}
                         onChange={(e)=>{
                          setOrderDetail({
                            ...orderDetail,
                            table_no:e.target.value
                          })
                         }}
                         placeholder="Enter table no"/>
                        </div>
                      </div>
                      :<div className={styles.dineIN}>
                     <div className={styles.dineINData}> <p> Order Date:&nbsp;{orderDetail.scheduled_date[0]}</p></div>
                      </div>}
                       </div>
                </CardContent>
              </Card>

              <Table>
                <TableHead>
                  <TableRow sx={{fontFamily:"Poppins", color: "#59293a", }}>
                    <TableCell sx={{width:"25%",padding:"16px 10px"}} >Item</TableCell>
                    <TableCell sx={{width:"10%",padding:"16px 10px"}} align="left">Qty</TableCell>
                    <TableCell sx={{width:"16%",padding:"16px 10px"}} align="left">Unit Price</TableCell>
                    <TableCell sx={{width:"10%",padding:"16px 10px"}} align="left">Tax</TableCell>
                    <TableCell sx={{width:"10%",padding:"16px 10px"}} align="left">Discount</TableCell>
                    <TableCell sx={{width:"16%",padding:"16px 10px"}} align="left">Amount</TableCell>
                    <TableCell sx={{width:"10%",padding:"16px 10px"}} align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableItems.map((item,i) => (
                    <TableRow key={i}>
                      <TableCell  sx={{padding:"16px 10px"}}>
                        {truncateText(item.item_name, 10)} {item.variant_name ? `(${item.variant_name})` : ''}</TableCell>
                      <TableCell align="center" sx={{padding:"16px 10px"}} >
                        <input
                          disabled={apiOrderStatus === 3 || apiOrderStatus === 4}
                          type="number"
                          style={{width:"100%"}}
                          min={1}
                          value={item.quantity}
                          className={style.cart_item_qty}
                          onChange={(e) =>
                            handleQtyChange(item.item_id, e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell  sx={{padding:"16px 10px"}} align="left">{item.selling_price}</TableCell>
                      <TableCell  sx={{padding:"16px 10px"}} align="left">{item.tax_amount}</TableCell>
                      <TableCell  sx={{padding:"16px 10px"}} align="left">{item.discount}</TableCell>
                      <TableCell  sx={{padding:"16px 10px"}} align="left">{item.total_amount}</TableCell>
                      <TableCell  sx={{padding:"16px 10px"}} align="left">
                        <RiDeleteBinLine
                          
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            marginLeft: "15px",
                          }}
                          title="Delete"
                          onClick={() => {
                            if (apiOrderStatus !== 3 && apiOrderStatus !== 4) {
                              handleRemoveItem(item.item_id);
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Card className={style.total_summary} sx={{ p: 1 }}>
            <CardContent sx={{padding:"4px !important"}}>
            <div className={styles.tableHead}> {showDetails?<FaCaretDown onClick={()=>{
                  setShowDetails(false)
                }}/>:<FaCaretUp onClick={()=>{
                  setShowDetails(true)
                }}/>}</div>
              <Table>
                                <TableBody>
                  {showDetails&&(
               <>
                  <TableRow sx={{fontFamily:"Poppins", color: "#59293a", }}>
                      <TableCell className={style.item_qty} sx={{ fontSize: 14 }}>
                        Status
                      </TableCell>
                      <TableCell>
                       <div  className={style.status}>
                          {sortValue}<FaAngleDown onClick={(e) => {
                            if (apiOrderStatus !== 3 && apiOrderStatus !== 4) {
                      setSortListShow(!sortListShow);
                      e.stopPropagation()
                      }
                    }}/>
                          {sortListShow && (
                    <div ref={sortRef} className={styles.sortValueContainer}>
                      {filterMenuItem.map((list, i) => {
                        return (
                          <div
                            className={styles.sortValue}
                            key={i}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (list.status_code !=4){
                                handleSortValue(list.status_code);
                              }
                              // handleSortValue(list.status_code);
                            }}
                          >
                            {orderDetail.order_status === list.status_code ? (
                              <div className={styles.activeSortValue}>
                                <FaCheck/>
                                <p>{list.status_name}</p>
                              </div>
                            ) : (
                              <div className={styles.nonActiveSortValue}>
                                <p>{list.status_name}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                       </div>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{fontFamily:"Poppins", color: "#59293a", }}>
                      <TableCell className={style.item_qty} sx={{ fontSize: 14 }}>
                        Note
                      </TableCell>
                      <TableCell className={style.item_price}>
                      <TextField
                            disabled={apiOrderStatus === 3 || apiOrderStatus === 4}
                            value={orderDetail.customer_remark}
                            className={carousel.noteTextarea}
                            onChange={(e) => {
                              setOrderDetail({
                                ...orderDetail,
                                customer_remark: e.target.value,
                              });
                            }}
                            placeholder="Type your note here..."
                          />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{fontFamily:"Poppins", color: "#59293a", }}>
                      <TableCell className={style.item_qty} sx={{ fontSize: 14 }}>
                        Sub Total
                      </TableCell>
                      <TableCell className={style.item_price}>
                        ₹{orderDetail.gross_amount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={style.item_qty} sx={{ fontSize: 14 }}>
                        Tax Total
                      </TableCell>
                      <TableCell className={style.item_price}>
                        ₹{orderDetail.total_tax_amount}
                      </TableCell>
                    </TableRow>
               </>)}
                  <TableRow>
                    <TableCell className={style.item_qty} sx={{ fontSize: 18 }}>
                      Net Total
                    </TableCell>
                    <TableCell
                      className={style.item_price}
                      sx={{ fontSize: 18 }}
                    >
                      ₹{orderDetail.net_amount}{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  height: 45,
                  borderRadius: 2,
                  backgroundColor: "#59293a",
                  fontFamily:"Poppins",
                  "&:hover": {
                    backgroundColor: "#59293a",
                  },
                }}
                onClick={()=>{
                  setIsButtonDisabled(true);
                  if(tableItems.length<1){
                    setErrorMessage("Please add at least one item ")
                    setValidationFail(true)
                    setIsButtonDisabled(false);
                  }else if(orderDetail.type==="Dine in" && orderDetail.table_no===""){
                    setErrorMessage("Please enter table number")
                    setValidationFail(true)
                    setIsButtonDisabled(false);
                  }else if(orderDetail.type==="Delivery" && orderDetail.slot_id===""){
                    setErrorMessage("Please select a slot")
                    setValidationFail(true)
                    setIsButtonDisabled(false);
                  }else if(orderDetail.type==="Delivery" && orderDetail.scheduled_date.length<1){
                    setErrorMessage("Please select a date")
                    setValidationFail(true)
                    setIsButtonDisabled(false);
                  }
                  else{
                    saveOrder();
                  }
                }}
                disabled={isButtonDisabled}
              >{orderId===undefined?
                "Place Order"
                :"Update Order"

              }
                
              </Button>
            </CardActions>
          </Card>
        </Box>
        <Box sx={{ flex: 1, padding: 1 ,}}>
            <div className={style.item_search}>
              <IoSearch className={style.searchIcon} />
              <input
                type="text"
                placeholder=""
                className={style.searchInput}
                value={searchTerm}
                onChange={(e)=>{
                  setSearchTerm(e.target.value)
                }}
              />
            </div>
            <div className={carousel.carousel__wrap}>
              <div
                className={carousel.carousel__inner}
                style={{
                  transform: `translateX(-${carouselId * (600 / 4)}px)`,

                }}
              >
                {category.map((item) => (
                  <div onClick={() => handleClick(item.category_id)} className={carousel.Slider} key={item.category_id}>
                    <img
                      className={selectedCategory===item.category_id?carousel.thumbnailActive:carousel.thumbnail}
                      src={item.image}
                      alt={item.name}
                    />
                    <h4>
                      {item.name}
                    </h4>
                  </div>
                ))}
              </div>
              <button
                className={`${carousel.carousel__btn} ${carousel["carousel__btn--prev"]}`}
                onClick={leftHandle}
              >
                <i
                  className={`${carousel.carousel__btn_arrow} ${carousel["carousel__btn_arrow--left"]}`}
                >
                  <FaArrowAltCircleLeft />
                </i>
              </button>
              <button
                className={`${carousel.carousel__btn} ${carousel["carousel__btn--next"]}`}
                onClick={rightHandle}
              >
                <i
                  className={`${carousel.carousel__btn_arrow} ${carousel["carousel__btn_arrow--right"]}`}
                >
                  <FaArrowAltCircleRight />
                </i>
              </button>
            </div>
            <div className={styles.categorySeparate}></div>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                {items
                  
                  .map((product) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={product.itemID}
                      className="draggableItem"
                      draggable={apiOrderStatus !== 3 && apiOrderStatus !== 4}
                      onDragStart={apiOrderStatus !== 3 && apiOrderStatus !== 4 ? (e) => handleDragStart(e, product) : null}
                    >
                      <Card sx={{ maxWidth: 345,borderRadius:"12px" }}>
                        <CardMedia
                          sx={{ height: 160, width:"100%" }}
                          image={product.currentImage}
                          title={product.ItemName}
                        />
                        <CardContent sx={{padding:"8px 12px !important",minHeight:"7vh"}}>
                          <Typography sx={{fontSize:"1.2rem",fontWeight:"600"}} variant="h4" component="div">
                            {product.ItemName.length > 20 ? 
                                            product.ItemName.substring(0, 19) + '...' : 
                                            product.ItemName}
                          </Typography>
                          <Typography sx={{fontSize:"1rem",fontWeight:"500"}} gutterBottom variant="h7" component="div">
                          ₹ {product.SalePrice}
                          </Typography>
                        </CardContent>
                        <CardActions sx={{justifyContent:'flex-end'}}>
                          <Button sx={{fontFamily:"Poppins", color: "#59293a",border:"1px solid #59293a" }}
                          disabled={apiOrderStatus === 3 || apiOrderStatus === 4}
                            onClick={() => {
                              handleVariantProduct(product);
                            }}
                            size="small"
                          >
                            Add
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Container>
        </Box>
      </Box>

      {/* Order Create Modal */}
      <Modal
        open={modalOpen}
        aria-labelledby="create-order-modal"
        aria-describedby="create-order-form"
      >
        <Paper className={styles.modal} elevation={6}>
          <h2 id="create-order-modal">Create Order</h2>
          <div className={styles.modalContent}>
          <Box sx={{ minWidth: 200 }}>
              <Autocomplete
                id="customer-select"
                options={customers}
                getOptionLabel={(option) => option.customer_name}
                value={customers.find((customer) => customer.customer_id === orderDetail.customer_code) || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleCustomer(newValue.customer_id);
                  } else {
                    handleCustomer('');
                  }
                }}
                isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Customer"
                    variant="outlined"
                    size="small"
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&:hover fieldset": {
                        borderColor: "#794a57",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                )}
              />
            </Box>
            {/* Modal actions (submit and close) */}
            <div className={style.modalActions}>
                                <button
                                    variant="contained"
                                    onClick={handleAddOrder}
                                    className={styles.EditSub}
                                >
                                    Proceed
                                </button>
                                <button
                                    variant="outlined"
                                    onClick={() => {
                                      setModalOpen(false);
                                      if(orderDetail.customer_code ===""){
                                        navigate("/orders",{
                                          state:{
                                            source:'manageOrder'
                                          }
                                        });
                                      }
                                      
                                    }}
                                    className={styles.EditClose}
                                >
                                    Cancel
                                </button>
                            </div>
          </div>
        </Paper>
      </Modal>


      <Modal
        open={variantPopup}
        aria-labelledby="variant selection-modal"
        aria-describedby="variant selection-form"
      >
        <Paper className={styles.variantModal} elevation={6}>
          <div className={styles.variantPopupHeader}><h2 id="create-order-modal">Select variant</h2><MdClose onClick={handleClose} style={{fontSize:"1.5rem",cursor:'pointer'}}/></div>
          <div className={styles.modalContent}>
          <Box sx={{ minWidth: 200 }}>
            <div className={styles.variantList}>
                  <div className={styles.variantGrid}>
                  {selectedProductData.variant && selectedProductData.variant.map((variant, index) => (
                    <div key={index} className={styles.variantRow}>
                      <div className={styles.variantRowLeft}>
                        <p>{selectedProductData.ItemName} ({variant.attribute_type.map(attr => attr.type_name).join(', ')})</p>
                      </div>
                      <div className={styles.variantRowRight}>
                        <button onClick={() =>{
                           handleAddItem({
                            itemID: variant.variant_id,
                            item_code: selectedProductData.item_code,
                            ItemName: selectedProductData.ItemName,
                            quantity: 1, 
                            SalePrice: variant.sale_price,
                            SaleDiscountPercentage: selectedProductData.SaleDiscountPercentage,
                            SaleTaxPercentage: selectedProductData.SaleTaxPercentage,
                            tax_amount: 0, 
                            total_amount: 0,
                            variant_name: variant.attribute_type.map(attr => attr.type_name).join(', ') ,
                            discount:0,
                            taxInclusive:selectedProductData.product_tax_type,
                            price_before_tax:0,
                            item_media:variant.image_file
                          });
                          handleClose()
                        }
                        }>Add</button>
                      </div>
                    </div>
                  ))}
                  </div>
            </div>
              
            </Box>
            
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default ManageOrder;
