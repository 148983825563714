import React, { useEffect, useState } from 'react';
import style from './CustomerUnderGroup.module.css';
import { Alert, Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { IoSearch } from 'react-icons/io5';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../Url';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { LuLink } from "react-icons/lu";
import { GoUnlink } from "react-icons/go";

function CustomerUnderGroup() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerGroupId = queryParams.get('customerGroupId');
    const selectedLocationId = queryParams.get('selectedLocationId');
    const { selectedGroupId } = location.state || {};

    const token = JSON.parse(localStorage.getItem("access-token"));
    const navigate = useNavigate();

    const [customerData, setCustomerData] = useState([]);
    const [customerView, setCustomerView] = useState(true);
    const [customerUnderGroup, setCustomerUnderGroup] = useState([]);
    const [unMapId, setUnMapId] = useState("");
    const [unMapPopup, setUnMapPopup] = useState(false);
    const [unMapName, setUnMapName] = useState("");
    const [customerMapPopup, setCustomerMapPopup] = useState(false);
    const [unMappedCustomerData, setUnMappedCustomerData] = useState([]);
    const [selectedUnmappedCustomers, setSelectedUnmappedCustomers] = useState([]);



    async function customerList() {
        await fetch(`${baseUrl}/customer/list`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'customerDataList');
                if (data.status === true) {
                    setCustomerData(data.data);
                    if (data.data.length > 0) {
                        setCustomerView(true);
                    } else {
                        setCustomerView(false);
                    }
                }
            })
    }

    async function customerListUnderGroup() {
        await fetch(`${baseUrl}/customers/under/group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                group_id: selectedGroupId
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'customer under group');
                if (data.status === true) {
                    setCustomerUnderGroup(data.data);
                }
            })
    }

    async function unMappingCustomer() {
        await fetch(`${baseUrl}/unmap/customers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                mapped_id: unMapId
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'unmapping customers');
                if (data.status === true) {
                    setUnMapPopup(false);
                    customerListUnderGroup()
                }
            })
    }

    async function unMappedCustomerList() {
        await fetch(`${baseUrl}/not_mapped/customers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                customergroup_id: selectedGroupId
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'unmapped customer list');
                if (data.status === true) {
                    setUnMappedCustomerData(data.data);
                }
            })
    }

    async function mapCustomerToGroup() {
        const reqData = {
            customergroup_id: selectedGroupId,
            customer_id: selectedUnmappedCustomers
        }
        await fetch(`${baseUrl}/customer/map`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reqData)
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'list for map');
                if (data.status === true) {
                    setCustomerMapPopup(false);
                    customerListUnderGroup()
                    setSelectedUnmappedCustomers("");
                }
            })
    }

    useEffect(() => {
        console.log(customerUnderGroup, 'customerUnderGroup');
        if (selectedGroupId) {
            customerListUnderGroup(selectedGroupId);
        } else {
            customerList();
        }
    }, [selectedGroupId]);

    return (
        <div className={style.container}>
            <div className={style.header}>
                <div className={style.heading}>
                    <p>Customer</p>
                </div>
                {/* <div className={style.search}>
                    <IoSearch className={style.searchIcon} />
                    <input
                        type="text"
                        placeholder="search"
                        className={style.searchInput}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                            if (e.target.value === "") {
                                customerList();
                            } else {
                                customerSearch(e.target.value);
                            }
                        }}
                    />
                </div> */}
                <button
                    onClick={() => {
                        unMappedCustomerList(selectedGroupId);
                        setCustomerMapPopup(true);
                    }}
                    className={style.buttonSection}
                >
                    Add Customer
                </button>
            </div>
            <div className={style.tableSection}>
            {customerView && (customerUnderGroup && customerUnderGroup.length > 0) ? (
                    <TableContainer component={Paper} className={style.table}>
                        {/* <div className={style.tableHeading}> Customer </div> */}
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead style={{ backgroundColor: "#f7eedf" }}
                                className={style.tableHead}
                            >
                                <TableRow>
                                    {/* <TableCell></TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customerUnderGroup.map((item) => (
                                    <TableRow key={item.customer_id}>
                                        <TableCell component="th" scope="row">
                                            {item.Customer_details?.[0]?.customer_name}
                                        </TableCell>
                                        <TableCell align="left">{item.status}</TableCell>
                                        <TableCell align="left">
                                            <GoUnlink
                                                style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                    marginLeft: "15px",
                                                }}
                                                title="Unmap Customer"
                                                onClick={() => {
                                                    setUnMapPopup(true);
                                                    setUnMapId(item.mapped_id)
                                                    setUnMapName(item.Customer_details?.[0]?.customer_name)
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Alert severity="error" color="warning">
                        No Customer found..!
                    </Alert>
                )}
            </div>

            {unMapPopup && (
                <Modal
                    open={unMapPopup}
                    onClose={() => {
                        setUnMapPopup(false);
                    }}
                    aria-labelledby="delete-hub-modal"
                    aria-describedby="delete-hub-form"
                >
                    <Paper className={style.modal} elevation={3}>
                        <h2 id="delete-hub-modal">Confirm Unmap</h2>
                        <div className={style.modalContent}>
                            <p className={style.deleteContent}>Are you sure you want to Unmap {unMapName}?</p>
                            <div className={style.modalActions}>
                                <button
                                    variant="contained"
                                    onClick={() => {
                                        unMappingCustomer();
                                    }}
                                    className={style.EditSub}
                                >
                                    Confirm
                                </button>
                                <button
                                    variant="outlined"
                                    onClick={() => {
                                        setUnMapPopup(false);
                                    }}
                                    className={style.EditClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Paper>
                </Modal>
            )}

            {customerMapPopup && (
                <Modal
                    open={customerMapPopup}
                    onClose={() => setCustomerMapPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={style.mapModalBody}
                >
                    <Box className={style.mapModal}>
                        <h2>Select Customers to Add</h2>
                        <div className={style.mapContent}>
                            {unMappedCustomerData.map((item) => (
                                <div key={item.customer_id} className={style.customerItem}>
                                    <Checkbox
                                        checked={selectedUnmappedCustomers.includes(item.customer_id)}
                                        onChange={() => {
                                            if (selectedUnmappedCustomers.includes(item.customer_id)) {
                                                setSelectedUnmappedCustomers(selectedUnmappedCustomers.filter(customer_id => customer_id !== item.customer_id));
                                            } else {
                                                setSelectedUnmappedCustomers([...selectedUnmappedCustomers, item.customer_id]);
                                            }
                                        }}
                                    />
                                    {item.customer_name}
                                </div>
                            ))}
                        </div>
                        <div className={style.modalActions}>
                            <button
                                variant="contained"
                                className={style.EditSub}
                                onClick={() => {
                                    mapCustomerToGroup()
                                }}
                            >
                                Confirm
                            </button>
                            <button
                                variant="outlined"
                                onClick={() => {
                                    setCustomerMapPopup(false);
                                }}
                                className={style.EditClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </Box>
                </Modal>
            )}
        </div>

    )
}

export default CustomerUnderGroup