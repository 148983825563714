import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Organization from "./Components/Organization/Organization";
import Login from "./Components/Login/Login";
import Home from "./Components/Home/Home";
import Roles from "./Components/Roles/Roles";
import User from "./Components/User/User";
import Locations from "./Components/Locations/Locations";
import AdminManage from "./Components/AdminManage/AdminManage";
import Category from "./Components/Category/Category";
import SubCategory from "./Components/SubCategory/SubCategory";
import CustomerGroup from "./Components/CustomerGroup/CustomerGroup";
import Customer from "./Components/Customer/Customer";
import MenuItems from "./Components/MenuItems/MenuItems";
import ItemCreation from "./Components/ItemCreation/ItemCreation";
import CustomerCreation from "./Components/CustomerCreation/CustomerCreation";
import CustomerUpdate from "./Components/CustomerUpdate/CustomerUpdate";
import ItemUpdate from "./Components/ItemUpdate/ItemUpdate";
import Attribute from "./Components/Attribute/Attribute";
import AttributeType from "./Components/AttributeType/AttributeType"; 
import Varient from "./Components/Varient/Varient";
import CustomerUnderGroup from "./Components/CustomerUnderGroup/CustomerUnderGroup";
import Order from "./Components/Order/Order";
import ManageOrder from "./Components/Order/ManageOrder";
// import PriceList from "./Components/PriceList/PriceList";
import Tax from "./Components/Tax/Tax";
import TaxGroup from "./Components/TaxGroup/TaxGroup";
import TaxItem from "./Components/TaxItem/TaxItem";
import Delivery from "./Components/Delivery/Delivery";
import DeliveryDetails from "./Components/DeliveryDetails/DeliveryDetails";
import Slots from "./Components/Slots/Slots";
import GoDown from "./Components/GoDown/GoDown";
import VehicleManagement from "./Components/VehicleManagement/VehicleManagement";
import Return from "./Components/Return/Return";
import ReturnDetails from "./Components/ReturnDetails/ReturnDetails";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route element={<Layout />}>
            <Route path="/" element={<Organization/>}/>
            <Route path="/hub" element={<Organization />} />
            <Route path="/users/user" element={<User />} />
            <Route path="/hub/locations" element={<Locations />} />
            <Route path="/users/roles" element={<Roles />} />
            <Route path="/admin/adminmanage" element={<AdminManage />} />
            <Route path="/category" element={<Category/>}/>
            <Route path="/category/subcategory" element={<SubCategory/>}/>
            <Route path="/customers/customergroup" element={<CustomerGroup/>}/>
            <Route path="/customers/customer" element={<Customer/>}/>
            <Route path="/stockitems" element={<MenuItems/>}/>
            <Route path="/itemcreation" element={<ItemCreation/>}/>
            <Route path="/customers/customer/creation" element={<CustomerCreation/>}/>
            <Route path="/customers/customer/updation" element={<CustomerUpdate/>}/>
            <Route path="/item/update" element={<ItemUpdate/>}/>
            <Route path="/attribute" element={<Attribute/>}/>
            <Route path="/attribute/type" element={<AttributeType/>}/>
            <Route path="/varient" element={<Varient/>}/>
            <Route path="/customer/under/group" element={<CustomerUnderGroup/>}/>
            <Route path="/orders" element={<Order/>}/>
            <Route path="/orders/manageorder" element={<ManageOrder/>}/>
            <Route path="/order/manageorder/:orderId" element={<ManageOrder />} />
            <Route path="/tax/segment" element={<Tax />} />
            <Route path="/tax/group" element={<TaxGroup />} />
            <Route path="/tax/item" element={<TaxItem />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/delivery/details" element={<DeliveryDetails />} />
            <Route path="/slots" element={<Slots />} />
            <Route path="/godown" element={<GoDown />} />
            <Route path="/vehicle" element={<VehicleManagement />} />
            <Route path="/return" element={<Return />} />
            <Route path="/return/details" element={<ReturnDetails />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
