import React, { useEffect, useState } from "react";
import style from "./AttributeType.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { Alert, Modal, Paper, Snackbar, TextField } from "@mui/material";

const AttributeType = () => {

  const token = JSON.parse(localStorage.getItem("access-token"));
  const location = useLocation()
  const state = location.state
  // const attributeName=state?.attributeName

  const [createName, setCreateName] = useState("");
  const [createDescription, setCreateDescription] = useState("");
  const [createCode, setCreateCode] = useState(state.attributeValue.createCode);
  const [createId, setCreateId] = useState(state.attributeValue.createId);
  const [attributeTypeData, setAttributeTypeData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [typeSuccessSnack, setTypeSuccessSnack] = useState(false);
  const [typeDeletionSnack, setTypeDeletionSnack] = useState(false);
  const [typeUpdationSnack, setTypeUpdationSnack] = useState(false);
  const [typeFail, setTypeFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);

  const createValidation = () => {
    if (!createName) {
        setErrorMessage("Attribute Name is required");
        return false;
    }
    if (!createDescription) {
        setErrorMessage("Description is required");
        return false;
    }
    return true;
}

const updateValidation = () => {
  if (!updateName) {
      setErrorMessage("Attribute Name is required");
      return false;
  }
  if (!updateDescription) {
      setErrorMessage("Description is required");
      return false;
  }

  return true;
};


  async function attrTypeCreation() {
    const reqData = {
      type_name: createName,
      type_description: createDescription,
      attribute_code: createCode,
      attribute_id: createId
    }
    if (!createValidation()) {
      setValidationFail(true);
      return
  }
  else {
    console.log(reqData, 'reqData');
    await fetch(`${baseUrl}/attribute/type/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'created attribute type');
        if (data.status === true) {
          setTypeSuccessSnack(true);
          attrTypeList();
          setCreateName("");
          setCreateDescription("");
        } else {
          setApiDescription(data.description);
          setTypeFail(true);
        }
      })
    }
  }

  async function attrTypeList() {
    const reqData = {
      attribute_code: createCode,
      attribute_id: ""
    }
    await fetch(`${baseUrl}/attribute/type/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'attribute list');
        if (data.status === true) {
          setAttributeTypeData(data.data);
        }
      })
  }

  async function attrTypeUpdate() {
    const reqData = {
      type_id: updateId,
      type_name: updateName,
      type_description: updateDescription
    }
    if (!updateValidation()) {
      setValidationFail(true);
      return
  } else {
    await fetch(`${baseUrl}/attribute/type/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'updated attribute type');
        if (data.status === true) {
          setTypeUpdationSnack(true);
          setUpdatePopup(false);
          attrTypeList();
        }else {
          setApiDescription(data.description);
          setTypeFail(true);
        }
      })
    }
  }

  async function attrTypeDelete() {
    await fetch(`${baseUrl}/attribute/type/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        attribute_type_id: deleteId
      })
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'delete attribute type');
        if (data.status === true) {
          setTypeDeletionSnack(true);
          setDeletePopup(false);
          attrTypeList();
        }else {
          setApiDescription(data.description);
          setTypeFail(true);
        }
      })
  }

  useEffect(() => {
    attrTypeList()
  }, [])



  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={typeSuccessSnack || typeDeletionSnack || typeUpdationSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setTypeSuccessSnack(false);
          setTypeDeletionSnack(false);
          setTypeUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {typeSuccessSnack
            ? "Attribute type created successfully"
            : typeDeletionSnack
              ? "Attribute type deleted successfully"
              : typeUpdationSnack
                ? "Attribute type updated successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={typeFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setTypeFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {/* {" "} */}
      <div className={style.header}>
        <p>Attribute Type</p>
      </div>
      <div className={style.attributeBody}>
        <div className={style.bodyLeft}>
          <div className={style.leftHeading}>
            {/* <p>Add New {attributeName}</p> */}
            <p>Add New</p>
          </div>
          <div className={style.leftInputSection}>
            <div className={style.inputContainer}>
              <label>Name</label>
              <input value={createName} onChange={(e) => { setCreateName(e.target.value) }} />
            </div>
            <div className={style.inputContainer}>
              <label>Description</label>
              <textarea value={createDescription} onChange={(e) => { setCreateDescription(e.target.value) }} />
            </div>
          </div>
          <div className={style.leftButton}>
            <button onClick={() => { attrTypeCreation() }}>Add</button>
          </div>
        </div>
        <div className={style.bodyRight}>
          <div className={style.attributeTable}>
            <div className={style.attributeTableHeader}>
              <div className={style.tableHeadAttribute}>
                <p className={style.attributeTableHeaderText}>Name</p>
              </div>
              <div className={style.tableHeadDescription}>
                <p className={style.attributeTableHeaderText}>Description</p>
              </div>
              <div className={style.tableHeadActions}>
                <p className={style.attributeTableHeaderText}>Actions</p>
              </div>
            </div>
            {attributeTypeData.map((item) => (
              <div className={style.attributeTableBody}>
                <div className={style.attributeTableRow}>
                  <div className={style.tableDataAttribute}>
                    <p className={style.attributeTableNameText}>{item.type_name}</p>
                  </div>
                  <div className={style.tableDataDescription}>
                    <p className={style.attributeTableDataText}>{item.type_description}</p>
                  </div>

                  <div className={style.tableDataActions}>
                    <div className={style.attributeTableDataIcons}>
                      <BiEditAlt
                        onClick={() => {
                          setUpdatePopup(true);
                          setUpdateId(item.type_id);
                          setUpdateName(item.type_name);
                          setUpdateDescription(item.type_description);
                        }}
                      />
                      <RiDeleteBinLine
                        onClick={() => {
                          setDeletePopup(true);
                          setDeleteId(item.type_id);
                          setDeleteName(item.type_name);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className={style.attributeTableRow}>
                  <div className={style.tableDataAttribute}>
                    <p className={style.attributeTableNameText}>Size</p>
                  </div>
                  <div className={style.tableDataDescription}>
                    <p className={style.attributeTableDataText}>-</p>
                  </div>
                  <div className={style.tableDataActions}>
                    <div className={style.attributeTableDataIcons}>
                      <BiEditAlt />
                      <RiDeleteBinLine />
                    </div>
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false);
          }}
          aria-labelledby="add-user-modal"
          aria-describedby="add-user-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-user-modal">Update Attribute</h2>
            <div className={style.modalContent}>
              <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <TextField
                  label="Name"
                  fullWidth
                  value={updateName}
                  onChange={(e) => {
                    setUpdateName(e.target.value);
                  }}
                  margin="normal"
                  style={{ marginRight: "10px" }}
                />
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={updateDescription}
                  onChange={(e) => {
                    setUpdateDescription(e.target.value)
                  }}
                />
              </div>
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    attrTypeUpdate();
                  }}
                >
                  Submit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>Are you sure you want to delete {deleteName}?</p>

              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    attrTypeDelete();
                  }}
                >
                  Submit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
};

export default AttributeType;
