import { React, useState, useEffect,useRef } from "react";
import style from "./Order.module.css";
import { dateTimeISOConverter , datetimeReadable} from "./Utils";
import {
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
 TextField
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import { IoSearch  } from "react-icons/io5";
import { useOutletContext } from 'react-router-dom';
import { useNavigate,useLocation } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
import { FaCheck,FaAngleRight, FaAngleLeft } from "react-icons/fa6";


function Order() {

  const navigate = useNavigate();
  const location =useLocation();

  const sortRef=useRef(null)

  const source=location.state?.source;

  const { selectedNewHub } = useOutletContext();

  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderView, setOrderView] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [sortValueId, setSortValueId] = useState(0);
  const [sortListShow, setSortListShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterMenuItem,setFilterMenuItem]=useState([])
  const [noOfData,setNoOfData]=useState(10)
  const [totalData, setTotalData] = useState(0);


  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const handleSortValue = (value) => {
    setSortValueId(value);
    orderListing(selectedNewHub,page,noOfData,"","","",value)
  };

  const handleNextPage = ( ) => {
    if(page!==totalPages){
    const newPage= page+1
    setPage(newPage);
    orderListing(selectedNewHub,newPage,noOfData)
    }
  };
  const handlePreviousPage = () => {
    if(page!==1){
    const newPage= page-1
    setPage(newPage);
    orderListing(selectedNewHub,newPage,noOfData)
    }
  };

  const handleChangeRowsPerPage =(number)=>{
    const intNumber = parseInt(number, 10);
    setNoOfData(intNumber)
    setPage(1)
    orderListing(selectedNewHub,1,intNumber)

  }



  async function orderListing( orgId,pageNo,noOfRow, searchValue, f_date, t_date,status) {
    if (f_date && !t_date) {
      t_date = new Date().toISOString().split('T')[0]; // Set current date to t_date in YYYY-MM-DD format
      setToDate(t_date)
    }

    const reqData = {
      org_id: orgId ? orgId : "All",
      search: searchValue ? searchValue : "",
      from_date:dateTimeISOConverter(f_date),
      to_date:dateTimeISOConverter(t_date),
      order_status:status?status:0,
      page_no:pageNo?pageNo:1,
      count:noOfRow?noOfRow:10
    };

    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/search`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.status === true){
          setOrderData(data.data);
          setTotalData(data.total_count)
          if(data.data.length > 0){
            setOrderView(true)
          }else{
            setOrderView(false);
          }
        }else{
          setOrderView(false);
        }
      })

  }

  async function deleteOrder() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/delete`, {
      method: "POST",
      body: JSON.stringify({
        order_id: deleteId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          orderListing( selectedNewHub);
          setDeletePopup(false);
        }
      });
  }

  async function filterItems() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body:JSON.stringify({
        order:"order"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const updatedData = [
          { status_code: 0, status_name: "None" }, 
          ...data.data
      ];
      setFilterMenuItem(updatedData);
       
      });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target) ) {
        setSortListShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (source === 'manageOrder') {
      // Push a dummy state to the history stack
      window.history.pushState(null, '', window.location.href);

      const handlePopState = () => {
        // Prevent back navigation
        if (source === 'manageOrder') {
          // Push another dummy state to prevent back navigation
          window.history.pushState(null, '', window.location.href);
        }
      };

      // Listen for popstate events
      window.addEventListener('popstate', handlePopState);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [source]);




  useEffect(()=>{
    if(selectedNewHub){
    orderListing(selectedNewHub,page,noOfData)
    filterItems()
    }
  },[selectedNewHub])

  useEffect(() => {
    setTotalPages(Math.ceil(totalData / noOfData));
    
}, [noOfData, totalData, page, totalPages]);

  return (
    <div className={style.container}>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Orders</p>
        </div>
        <div className={style.dateSection}>
           <div className={style.dateSectionLeft}>
            <label>From </label>
              <input
                type="date"
                placeholder=""
                className={style.dateInput}
                value={from_date}
                onChange={(e) => {
                  const fromDateValue = e.target.value;
                  setFromDate(fromDateValue);
                  if (fromDateValue !== "") {
                    orderListing(selectedNewHub,page,noOfData,searchValue,fromDateValue, to_date);
                  } else {
                    orderListing( selectedNewHub,page,noOfData,searchValue, '', to_date);
                    setOrderView(true);
                  }
                }}
              />
           </div>
           <div className={style.dateSectionRight}>
           <label>To</label>
              <input
              type="date"
              placeholder=""
              className={style.dateInput}
              value={to_date}
              onChange={(e) => {
                const toDateValue = e.target.value;
                setToDate(toDateValue);
                if (toDateValue !== "") {
                  orderListing(selectedNewHub,page,noOfData,searchValue,from_date, toDateValue);
                } else {
                  orderListing( selectedNewHub,page,noOfData,searchValue,from_date, '');
                  setOrderView(true);
                }
              }}
            />
           </div>
        </div>
        <div className={style.filterSection}>
          <IoFilter onClick={(e) => {
                  setSortListShow(!sortListShow);
                  e.stopPropagation()
                }}/>
                 {sortListShow && (
                <div ref={sortRef} className={style.sortValueContainer}>
                  {filterMenuItem.map((list, i) => {
                    return (
                      <div
                        className={style.sortValue}
                        key={i}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleSortValue(list.status_code);
                        }}
                      >
                        {sortValueId === list.status_code ? (
                          <div className={style.activeSortValue}>
                            <FaCheck/>
                            <p>{list.status_name}</p>
                          </div>
                        ) : (
                          <div className={style.nonActiveSortValue}>
                            <p>{list.status_name}</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
        </div>
        <div className={style.search}>
          <IoSearch className={style.searchIcon} />
          <input
            type="text"
            placeholder=""
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              const searchInputValue = e.target.value;
              setSearchValue(searchInputValue);
              if (searchInputValue !== "") {
                orderListing(selectedNewHub,page,noOfData, searchInputValue,from_date, to_date);
              } else {
                orderListing( selectedNewHub);
                setOrderView(true);
              }
            }}
          />
        </div>  

        <div className={style.buttonSection}>
          <button
            onClick={() => {
              navigate("/orders/manageorder");
            }}
          >
            Add Order
          </button>
         
        </div>
      </div>
      <div className={style.tableSection}>
        {orderView ? (
          <TableContainer className={style.table} component={Paper}>
            {/*<div className={style.tableHeading}>Orders</div>*/}
            <Table  aria-label="caption table">
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell>Order Date</TableCell>
                  <TableCell align="left">Order Number</TableCell>
                  <TableCell align="left">Customer</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData.map((item) =>  {
                  const isDisabled = item.order_status === 3||item.order_status === 4;
                  return (
                        
                  <TableRow key={item.order_id}>
                    <TableCell component="th" scope="row">
                      {datetimeReadable(item.drafted_date)}
                    </TableCell>
                    <TableCell align="left">{item.order_number}</TableCell>
                    <TableCell align="left">{item.customer_name}</TableCell>
                    <TableCell align="left">{item.net_amount}</TableCell>
                    <TableCell align="left">{item.order_status===1?"Pending":item.order_status===2?"In Progress":item.order_status===3?"Confirmed":item.order_status===4?"Completed":""}</TableCell>
                    <TableCell align="left">
                      <BiEditAlt
                        style={{ fontSize: "20px",cursor:  "pointer",}}
                        title="Edit"
                        // onClick={() => {
                        //     // window.location.href = '/order/manageorder/' + item.order_id;
                        //     navigate(`/order/manageorder/${item.order_id}`, { state: { orderId: item.order_id } });
                        // }}
                        onClick={() => {
                          navigate(`/order/manageorder/${item.order_id}`, { 
                              state: { 
                                  orderId: item.order_id,
                                  scheduled_date: item.scheduled_date, // Assuming scheduled_date is part of item
                                  slot_id: item.slot_id                // Assuming slot_id is part of item
                              } 
                          });
                      }}
                      />
                      <RiDeleteBinLine
                        style={{
                          fontSize: "20px",
                          marginLeft: "15px",
                          cursor: isDisabled ? "not-allowed" : "pointer", color: isDisabled ? "gray" : "black" 
                        }}
                        title="Delete"

                        onClick={() => {
                          if (!isDisabled) {
                            setDeletePopup(true);
                            setDeleteId(item.order_id);
                            setDeleteName(item.ordernumber);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
            <div className={style.paginationSection}>
              <div className={style.paginationContainer}>
                <div className={style.pageCount}><p>Rows per page:</p>
                <TextField
                          select
                        
                        size="small"
                        margin="normal"
                        value={noOfData}
                        onChange={(e) => {
                        const number=e.target.value
                        handleChangeRowsPerPage(number)
                        }}
                        sx={{
                          margin:"0",
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                          },
                        }}
                        >
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="20">20</MenuItem>
                        </TextField>
                </div>
                <div className={style.paginationButtons}>
                  <FaAngleLeft  onClick={handlePreviousPage}/><p>{page} of {totalPages}</p> <FaAngleRight onClick={handleNextPage}/>
                </div>
              </div>
            </div>
          </TableContainer>
        ) : (
          <Alert severity="error" color="warning">
            No orders found..!
          </Alert>
        )}

      </div>
      
      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>Are you sure you want to delete {deleteName}?</p>

              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    deleteOrder();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default Order;
