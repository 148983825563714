import React, { useEffect, useState } from 'react';
import style from './CustomerGroup.module.css'
import { IoSearch } from 'react-icons/io5';
import { Alert, Box, FormControl, MenuItem, Modal, OutlinedInput, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { baseUrl } from '../Url';
import { Link } from 'react-router-dom';
import { TbCategoryPlus } from 'react-icons/tb';
import { MdOutlinePersonSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";



function CustomerGroup() {
    const [hubData, setHubData] = useState([]);
    const [selectedHub, setSelectedHub] = useState("");
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [locationId, setLocationId] = useState("");
    const [createLocationId, setCreateLocationId] = useState("");
    const [createCustomerGroupName, setCreateCustomerGroupName] = useState("");
    const [createPopup, setCreatePopup] = useState("");
    const [createOrgId, setCreateOrgId] = useState("");
    const [customerData, setCustomerData] = useState([]);
    const [updatePopup, setUpdatePopup] = useState("");
    const [updateCgName, setUpdateCgName] = useState("");
    const [updateCgStatus, setUpdateCgStatus] = useState("");
    const [updateCgId, setUpdateCgId] = useState("");
    const [deleteCgId, setDeleteCgId] = useState("");
    const [deletePopup, setDeletePopup] = useState("");
    const [deleteCgName, setDeleteCgName] = useState("");
    const [validationFail, setValidationFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [roleFail, setRoleFail] = useState(false);
    const [apiDescription, setApiDescription] = useState("");
    const [customerGroupSuccessSnack, setCustomerGroupSuccessSnack] = useState(false);
    const [customerGroupDeletionSnack, setCustomerGroupDeletionSnack] = useState(false);
    const [customerGroupUpdationSnack, setCustomerGroupUpdationSnack] = useState(false);
    const [showMessage, setShowMessage] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [customerGroupView, setCustomerGroupView] = useState(true);
    const [selectedGroupId, setSelectedGroupId] = useState("");



    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("access-token"));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 200,
            },
        },
    };

    const createValidation = () => {
        // if (!createOrgId) {
        //     setErrorMessage("Organization is required");
        //     return false;
        // }
        // if (!createLocationId) {
        //     setErrorMessage("Location is required");
        //     return false;
        // }
        if (!createCustomerGroupName) {
            setErrorMessage("Customer Group Name is required");
            return false;
        }
        return true;
    }

    const updateValidation = () => {
        if (!updateCgName) {
            setErrorMessage("Category Name is required");
            return false;
        }
        return true;
    };


    // async function hubList() {
    //     await fetch(`${baseUrl}/organizations/list`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((res) => res.json())
    //         .then((data) => {
    //             console.log(data, 'hubData');
    //             if (data.status === true) {
    //                 setHubData(data.data);
    //             }
    //         })
    // }

    // async function locationList(selectedHub, resetLocation = true) {
    //     await fetch(`${baseUrl}/location/list`, {
    //         method: "POST",
    //         body: JSON.stringify({
    //             org_id: selectedHub,
    //         }),
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((res) => res.json())
    //         .then((data) => {
    //             console.log(data, 'locationData');
    //             if (data.status === true) {
    //                 setLocationData(data.data);
    //                 if (resetLocation) {
    //                     setSelectedLocation("");
    //                     setLocationId("");
    //                 }
    //             } else {
    //                 setLocationData([]);
    //                 setSelectedLocation("");
    //                 setLocationId("");
    //             }
    //         })
    // }

    async function customerGroupList() {
        await fetch(`${baseUrl}/customer/group/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'customerData');
                if (data.status === true) {
                    setCustomerData(data.data);
                } else {
                    setCustomerData([]);
                }
            })
    }

    async function customerGroupCreation() {
        if (!createValidation()) {
            setValidationFail(true);
            return
        } else {
            await fetch(`${baseUrl}/customer/group/creation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    customer_group_name: createCustomerGroupName
                })
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data, 'customerGroupData');
                    if (data.status === true) {
                        setCreatePopup(false)
                        customerGroupList();
                        setCustomerGroupSuccessSnack(true);
                        setCreateCustomerGroupName("");
                        // setSelectedHub(createOrgId);
                        // setSelectedLocation(createLocationId);
                    } else {
                        setApiDescription(data.description);
                        setRoleFail(true);
                    }
                })
        }
    }

    async function customerGroupUpdation() {
        const reqData = {
            customergroup_id: updateCgId,
            customer_group_name: updateCgName,
            status: updateCgStatus
        }
        if (!updateValidation()) {
            setValidationFail(true);
            return
        } else {
            await fetch(`${baseUrl}/customer/group/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(reqData)
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data, 'updateData');
                    if (data.status === true) {
                        setUpdatePopup(false)
                        customerGroupList();
                        setCustomerGroupUpdationSnack(true);
                    } else {
                        setApiDescription(data.description);
                        setRoleFail(true);
                    }
                })
        }
    }

    async function customerGroupDelete() {
        await fetch(`${baseUrl}/customer/group/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                customergroup_id: deleteCgId
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'deleteData');
                if (data.status === true) {
                    setDeletePopup(false);
                    customerGroupList();
                    setCustomerGroupDeletionSnack(true);
                } else {
                    setApiDescription(data.description);
                    setRoleFail(true);
                }
            })
    }

    async function customerGroupSearch() {
        const reqData = {
            search_value: searchValue,
        }
        await fetch(`${baseUrl}/customer/group/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reqData)
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'searchData');
                if (data.status === true) {
                    setCustomerData(data.data);
                    setCustomerGroupView(true)
                } else {
                    setCustomerData([]);
                    setCustomerGroupView(false);
                }
            })
    }



    // useEffect(() => {
    //     if (selectedHub) {
    //         locationList(selectedHub);
    //     }
    // }, [selectedHub]);

    useEffect(() => {
        // hubList();
        customerGroupList();
    }, []);

    // useEffect(() => {
    //     setShowMessage(!(selectedHub && selectedLocation));
    // }, [selectedHub, selectedLocation]);

    // useEffect(() => {
    //     if (locationId) {
    //         customerGroupList(locationId);
    //     }
    // }, [locationId]);

    useEffect(() => {
        if (searchValue !== "") {
            customerGroupSearch();
        } else {
            customerGroupList();
            setCustomerGroupView(true);
        }
    }, [searchValue]);
    

    return (
        <div className={style.container}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={customerGroupSuccessSnack || customerGroupDeletionSnack || customerGroupUpdationSnack}
                sx={{ width: "20rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setCustomerGroupSuccessSnack(false);
                    setCustomerGroupDeletionSnack(false);
                    setCustomerGroupUpdationSnack(false);
                }}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {customerGroupSuccessSnack
                        ? "Customer Group created successfully"
                        : customerGroupDeletionSnack
                            ? "Customer Group deleted successfully"
                            : customerGroupUpdationSnack
                                ? "Customer Group updated successfully"
                                : ""}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={roleFail}
                sx={{ width: "20rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setRoleFail(false);
                }}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {apiDescription}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={validationFail}
                sx={{ width: "15rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setValidationFail(false);
                }}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className={style.header}>
                <div className={style.heading}>
                    <p>Customer Group</p>
                </div>
                {/* <div className={style.dropdownHub}>
                    <Box sx={{ minWidth: 200 }}>
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <Select
                                value={selectedHub}
                                onChange={(e) => {
                                    setSelectedHub(e.target.value);
                                    locationList(e.target.value);
                                    setCustomerData([]);
                                }}
                                displayEmpty
                                input={
                                    <OutlinedInput
                                        sx={{
                                            "& fieldset": {
                                                borderColor: "#59293a",
                                                borderWidth: 2,
                                            },
                                            "&fieldset:hover ": {
                                                borderColor: "#794a57",
                                            },
                                            "& fieldset:active": {
                                                borderColor: "#a96b84",
                                            },
                                        }}
                                    />
                                }
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem disabled value="">

                                    <em>Select a Hub</em>
                                </MenuItem>
                                {hubData.map((item) => (
                                    <MenuItem key={item.org_id} value={item.org_id}>
                                        {item.org_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div> */}
                {/* <div className={style.dropdownLocation}>
                    <Box sx={{ minWidth: 200 }}>
                        <FormControl sx={{ m: 2, minWidth: 200 }} size="small">
                            <Select
                                value={selectedLocation}
                                onChange={(e) => {
                                    const locValue = (e.target.value);
                                    setSelectedLocation(locValue);
                                    setLocationId(locValue);
                                }}
                                displayEmpty
                                input={
                                    <OutlinedInput
                                        sx={{
                                            "& fieldset": {
                                                borderColor: "#59293a",
                                                borderWidth: 2,
                                            },
                                            "&fieldset:hover ": {
                                                borderColor: "#794a57",
                                            },
                                            "& fieldset:active": {
                                                borderColor: "#a96b84",
                                            },
                                        }}
                                    />
                                }
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem disabled value="">
                                    {" "}
                                    <em>Select Location</em>
                                </MenuItem>
                                {locationData.map((item) => (
                                    <MenuItem key={item.location_id} value={item.location_id}>
                                        {item.location_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div> */}
                <div className={style.search}>
                    <IoSearch className={style.searchIcon} />
                    <input
                        type="text"
                        placeholder="search"
                        className={style.searchInput}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                        }}
                    />
                </div>
                <button
                    onClick={() => {
                        setCreatePopup(true)
                    }}
                    className={style.buttonSection}
                >
                    Add Customer Group
                </button>
            </div>
            {/* <div className={style.buttonSection}>
                <button
                    onClick={() => {
                        setCreatePopup(true)
                    }}
                >
                    Add Customer Group
                </button>
            </div> */}
            <div className={style.tableSection}>
                {/* {customerGroupView ? ( */}
                <TableContainer component={Paper} className={style.table}>
                    {/* <div className={style.tableHeading}> Customer Group </div> */}
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead style={{ backgroundColor: "#f7eedf" }}
                            className={style.tableHead}
                        >
                            <TableRow>
                                {/* <TableCell></TableCell> */}
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customerData.map((item) => (
                                <TableRow key={item.customergroup_id}>
                                    <TableCell component="th" scope="row">
                                        {item.customer_group_name}
                                    </TableCell>
                                    <TableCell align="left">{item.status}</TableCell>
                                    <TableCell align="left">
                                        <BiEditAlt
                                            style={{ fontSize: "20px", cursor: "pointer" }}
                                            title="Edit"
                                            onClick={() => {
                                                setUpdatePopup(true);
                                                setUpdateCgId(item.customergroup_id);
                                                setUpdateCgName(item.customer_group_name);
                                                setUpdateCgStatus(item.status)
                                            }}
                                        />
                                        <RiDeleteBinLine
                                            style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                marginLeft: "15px",
                                            }}
                                            title="Delete"
                                            onClick={() => {
                                                setDeletePopup(true);
                                                setDeleteCgId(item.customergroup_id);
                                                setDeleteCgName(item.customer_group_name)
                                            }}
                                        />
                                        <MdOutlinePersonSearch
                                            style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                marginLeft: "15px",
                                            }}
                                            title="View Mapped Customers"
                                            onClick={() => {
                                                navigate("/customer/under/group", {
                                                    state: { selectedGroupId: item.customergroup_id }
                                                });
                                            }}
                                        />
                                        {/* <Link to={`/customers/customer?selectedLocationId=${selectedLocation}&customerGroupId=${item.customergroup_id}`}>
                                                <TbCategoryPlus
                                                    style={{
                                                        fontSize: "20px",
                                                        cursor: "pointer",
                                                        marginLeft: "15px",
                                                        color: "black"
                                                    }}
                                                    title="View Customer"
                                                />
                                            </Link> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* ) : (
                    <Alert severity="error" color="warning">
                        No Customer group found..!
                    </Alert>
                )} */}
                {/* {!createOrgId && !createLocationId && showMessage && (
                    <div className={style.alertMessage}>
                        <Alert severity="error" color="warning">
                            Selecet Organization & Location first
                        </Alert>
                    </div>
                )} */}
            </div>

            {createPopup && (
                <Modal
                    open={createPopup}
                    onClose={() => {
                        setCreatePopup(false)
                    }}
                    aria-labelledby="add-hub-modal"
                    aria-describedby="add-hub-form"
                >
                    <Paper className={style.modal} elevation={3}>
                        <h2 id="add-hub-modal">Add Customer Group</h2>
                        <div className={style.modalContent}>
                            {/* <TextField
                                select
                                label="Organization"
                                fullWidth
                                margin="normal"
                                value={createOrgId}
                                onChange={(e) => {
                                    setCreateOrgId(e.target.value);
                                    locationList(e.target.value);
                                }}
                            >
                                {hubData.map((item) => (
                                    <MenuItem key={item.org_id} value={item.org_id}>
                                        {item.org_name}
                                    </MenuItem>
                                ))}
                            </TextField> */}
                            {/* <TextField
                                select
                                label="Location"
                                fullWidth
                                margin="normal"
                                value={createLocationId}
                                onChange={(e) => {
                                    setCreateLocationId(e.target.value);
                                    setLocationId(e.target.value)
                                }}
                            >
                                {locationData.map((item) => (
                                    <MenuItem key={item.location_id} value={item.location_id}>
                                        {item.location_name}
                                    </MenuItem>
                                ))}
                            </TextField> */}
                            <TextField
                                label="Customer Group Name"
                                fullWidth
                                multiline
                                margin="normal"
                                value={createCustomerGroupName}
                                onChange={(e) => {
                                    setCreateCustomerGroupName(e.target.value)
                                }}
                            />
                            <div className={style.modalActions}>
                                <button
                                    className={style.modalSub}
                                    variant="contained"
                                    onClick={() => {
                                        customerGroupCreation()
                                    }}
                                >
                                    SUBMIT
                                </button>
                                <button
                                    className={style.modalClose}
                                    variant="outlined"
                                    onClick={() => {
                                        setCreatePopup(false)
                                    }}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div>
                    </Paper>
                </Modal>
            )}

            {updatePopup && (
                <Modal
                    open={updatePopup}
                    onClose={() => {
                        setUpdatePopup(false)
                    }}
                    aria-labelledby="edit-hub-modal"
                    aria-describedby="edit-hub-form"
                >
                    <Paper className={style.modal} elevation={3}>
                        <h2 id="edit-hub-modal">Edit Customer Group</h2>
                        <div className={style.modalContent}>
                            <TextField
                                label="Customer Group Name"
                                fullWidth
                                margin="normal"
                                value={updateCgName}
                                onChange={(e) => {
                                    setUpdateCgName(e.target.value)
                                }}
                            />
                            <TextField
                                select
                                label="Status"
                                fullWidth
                                margin="normal"
                                value={updateCgStatus}
                                onChange={(e) => {
                                    setUpdateCgStatus(e.target.value)
                                }}
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </TextField>

                            <div className={style.modalActions}>
                                <button
                                    className={style.EditSub}
                                    variant="contained"
                                    onClick={() => {
                                        customerGroupUpdation()
                                    }}
                                >
                                    SUBMIT
                                </button>
                                <button
                                    className={style.EditClose}
                                    variant="outlined"
                                    onClick={() => {
                                        setUpdatePopup(false)
                                    }}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div>
                    </Paper>
                </Modal>
            )}

            {deletePopup && (
                <Modal
                    open={deletePopup}
                    onClose={() => {
                        setDeletePopup(false);
                    }}
                    aria-labelledby="delete-hub-modal"
                    aria-describedby="delete-hub-form"
                >
                    <Paper className={style.modal} elevation={3}>
                        <h2 id="delete-hub-modal">Confirm Delete</h2>
                        <div className={style.modalContent}>
                            <p className={style.deleteContent}>Are you sure you want to delete {deleteCgName}?</p>
                            <div className={style.modalActions}>
                                <button
                                    variant="contained"
                                    onClick={() => {
                                        customerGroupDelete();
                                    }}
                                    className={style.EditSub}
                                >
                                    Confirm
                                </button>
                                <button
                                    variant="outlined"
                                    onClick={() => {
                                        setDeletePopup(false);
                                    }}
                                    className={style.EditClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Paper>
                </Modal>
            )}

        </div>
    )
}

export default CustomerGroup