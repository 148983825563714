import React, { useEffect, useState } from "react";
import style from "./ModuleFeatures.module.css";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  Alert,
  TextField,
  Box,
  Snackbar,
} from "@mui/material";

function ModuleFeatures() {
  const [showAnimation, setShowAnimation] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingFeatureId, setEditingFeatureId] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [description, setDescription] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [moduleCode, setModuleCode] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [features, setFeatures] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    setShowAnimation(true);
    const storedModuleId = localStorage.getItem("module_id");
    const storedModuleCode = localStorage.getItem("module_code");
    const storedModuleName = localStorage.getItem("module_name");
    setModuleId(storedModuleId);
    setModuleCode(storedModuleCode);
    setModuleName(storedModuleName);
    fetchFeatures(storedModuleCode);
  }, []);

  const fetchFeatures = async (moduleCode) => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/view/module/mapped/features`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({ module_code: moduleCode }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch features");
      }

      const data = await response.json();
      setFeatures(data.data || []);
    } catch (error) {
      console.error("Error fetching features:", error.message);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    clearForm();
  };

  const handleOpenEditModal = (mapId, featureName, description) => {
    setEditingFeatureId(mapId);
    setFeatureName(featureName);
    setDescription(description);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    clearForm();
  };

  const addFeature = async (featureData) => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/module/feature/map`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(featureData),
      });

      if (!response.ok) {
        throw new Error("Failed to add feature");
      }

      const responseData = await response.json();

      if (responseData.status) {
        // Success
        setSnackbarSeverity("success");
        setSnackbarMessage(responseData.description);
        setSnackbarOpen(true);
        await fetchFeatures(moduleCode);
        clearForm();
        handleCloseAddModal();
      } else {
        // Failure
        setSnackbarSeverity("error");
        setSnackbarMessage("Please fill all the fields");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding feature:", error.message);
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const editFeature = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/edit/module/mapped/feature`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          map_id: editingFeatureId,
          feature_name: featureName,
          description: description,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to edit feature");
      }

      const responseData = await response.json();

      if (responseData.status) {
        // Success
        setSnackbarSeverity("success");
        setSnackbarMessage(responseData.description);
        setSnackbarOpen(true);
        await fetchFeatures(moduleCode);
        clearForm();
        handleCloseEditModal();
      } else {
        // Failure
        setSnackbarSeverity("error");
        setSnackbarMessage("Failed to edit feature");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error editing feature:", error.message);
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (openAddModal) {
        await addFeature({
          feature_name: featureName,
          description: description,
          module_id: moduleId,
          module_code: moduleCode,
        });
      } else if (openEditModal) {
        await editFeature();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const clearForm = () => {
    setFeatureName("");
    setDescription("");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div
        className={`${style.container} ${showAnimation ? style.slideIn : ""}`}
      >
        <div className={style.Header}>
          <div className={style.HeaderText}>
            <p>{moduleName} Features </p>
          </div>
          <div className={style.buttonSection}>
            <button onClick={handleOpenAddModal}>Add Features</button>
          </div>
        </div>

        {features.length > 0 ? (
          <div className={style.tableBody}>
            <TableContainer className={style.table} component={Paper}>
              <Table>
                <TableHead className={style.tableHead}>
                  <TableRow>
                    <TableCell>Feature</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {features.map((feature, index) => (
                    <TableRow key={index}>
                      <TableCell>{feature.feature_name}</TableCell>
                      <TableCell>{feature.description}</TableCell>
                      <TableCell>
                        <div className={style.actionIcons}>
                          <div className={style.editbtn}>
                            <BiEditAlt
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() =>
                                handleOpenEditModal(
                                  feature.map_id,
                                  feature.feature_name,
                                  feature.description
                                )
                              }
                            />
                          </div>
                          <div className={style.deletebtn}>
                            <RiDeleteBinLine
                              style={{ fontSize: "20px", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className={style.noData}>
            <Alert severity="error" color="success">
              No Features Assigned to this Module .
            </Alert>
          </div>
        )}
      </div>

      <Modal
        open={openAddModal || openEditModal}
        onClose={openAddModal ? handleCloseAddModal : handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modalBox}>
          <h2 id="add-feature-modal">
            {openAddModal ? "Add Feature" : "Edit Feature"}
          </h2>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Feature"
              margin="normal"
              variant="outlined"
              required
              value={featureName}
              onChange={(e) => setFeatureName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              variant="outlined"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className={style.modalActions}>
              <button
                className={style.modalSub}
                variant="contained"
                type="submit"
              >
                {openAddModal ? "Submit" : "Save"}
              </button>
              <button
                className={style.modalClose}
                variant="outlined"
                onClick={
                  openAddModal ? handleCloseAddModal : handleCloseEditModal
                }
              >
                Close
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        sx={{ width: "20rem" }}
      >
        <Alert
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModuleFeatures;
