import React, { useState, useEffect } from "react";
import style from "./DeliveryDetails.module.css";
import sampleImage from "../../assets/About.jpg";
import { FaAngleDown } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import {
  MenuItem,
  TextField,
  FormControl,
  Select,
  Checkbox,
  InputLabel,
  Alert,
  Snackbar,
  Box,
  Autocomplete,
  Modal,
  Paper,
  IconButton
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { IoQrCodeOutline } from "react-icons/io5";
import { QRCodeCanvas } from 'qrcode.react';
import QrScanner from 'react-qr-scanner';
import { CgCloseR } from "react-icons/cg";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { IoPrint } from "react-icons/io5";


const DeliveryDetails = () => {

  const { selectedNewHub } = useOutletContext();
  const navigate = useNavigate()
  const location = useLocation()
  const delivery_id = location.state?.delivery_id
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [deliveryData, setDeliveryData] = useState({})
  const [staffData, setStaffData] = useState([])
  const [filterMenuItem, setFilterMenuItem] = useState([])
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [slotData, setSlotData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState("")
  const [selectedStaff, setSelectedStaff] = useState("")
  const [orderID, setOrderID] = useState("")
  const [selectedAssets, setSelectedAssets] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [scannerModal, setScannerModal] = useState(false);
  const [assetData, setAssetData] = useState([])
  const [vehicleData, setVehicleData] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState("")
  const [scannerOpen, setScannerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [billModal, setBillModal] = useState(false);

  const now = new Date();
  const formattedDateTime = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()}, ${now.getHours() % 12 || 12}:${now.getMinutes().toString().padStart(2, '0')} ${now.getHours() >= 12 ? 'PM' : 'AM'}`;

  const convertDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const formatTimeTo12Hour = (time24) => {
    let [hours, minutes] = time24.split(':');
    let period = 'AM';
    hours = parseInt(hours, 10);
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  const handleScan = (result) => {
    if (result && result.text) {
      const scannedValue = result.text;
      const asset = assetData.find((asset) => asset.itemID === scannedValue);
      if (asset) {
        setSelectedAssets([asset]);
        setValidationFail(false);
        setErrorMessage("");
        setScannerOpen(false)
        setModalOpen(true);
      } else {
        setErrorMessage("Scanned asset not found in the list.");
        setValidationFail(true);
      }
    }
  };

  const handleError = (error) => {
    console.error("QR code scanning error:", error);
    setErrorMessage("An error occurred during QR code scanning.");
    setValidationFail(true);
  };

  const getSlotTimes = (slotId) => {
    const slot = slotData.find((slot) => slot.slot_id === slotId);
    if (slot) {
      const formattedStart = formatTimeTo12Hour(slot.slot_start);
      const formattedEnd = formatTimeTo12Hour(slot.slot_end);
      return `${formattedStart} - ${formattedEnd}`;
    }
    return 'N/A';
  };

  const handleStaffChange = (e) => {
    console.log('Staff selected:', e.target.value);
    setSelectedStaff(e.target.value);
  };


  async function deliveryList() {
    await fetch(`${baseUrl}/delivery/order/details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        delivery_order_id: delivery_id
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setSelectedStatus(data.data?.delivery_data?.order_status || "")
          setOrderID(data.data?.order_data?.[0]?.order_id || "")
          setDeliveryData(data.data || {});
          setSelectedVehicle(data.data?.delivery_data?.vehicle_id || "")
          setSelectedAssets(data.data?.asset_data ? data.data.asset_data : []);
        }
      })
  }

  async function listItemAssets() {
    await fetch(`${baseUrl}/list/item/assets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setAssetData(data.data)
        }
      })
  }

  async function staffList() {
    await fetch(`${baseUrl}/delivery/staff/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        org_id: selectedNewHub,
        role: "Delivery Staff"
      })
    })
      .then((res) => res.json())
      .then((data) => {

        if (data.status === true && data.data.length > 0) {
          setStaffData(data.data)
        }
        else {
          setStaffData([])
        }
      })
  }

  async function slotList() {
    await fetch(`${baseUrl}/slot/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hub_id: selectedNewHub
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setSlotData(data.data);
        }
      });
  }

  async function deliveryUpdate() {
    await fetch(`${baseUrl}/delivery/order/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        delivery_order_id: delivery_id,
        order_id: orderID,
        order_status: selectedStatus,
        order_staff: selectedStaff,
        asset_id: selectedAssets.map(asset => asset.itemID),
        vehicle_id: selectedVehicle
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setUpdateSuccess(true)
          setTimeout(() => {
            navigate("/delivery")
          }, 2000);
        }
      });
  }

  async function filterItems() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify({
        order: "delivery order"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true && data.data.length > 0) {
          setFilterMenuItem(data.data);
        }
        else {
          setFilterMenuItem([])
        }
      });
  }

  async function vehicleList() {
    const reqData = {
      hub_id: selectedNewHub,
    };
    await fetch(`${baseUrl}/list/vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setVehicleData(data.data);
        }
      });
  }

  useEffect(() => {
    if (delivery_id) {
      deliveryList();
    }
  }, [delivery_id])

  useEffect(() => {
    if (selectedNewHub) {
      filterItems()
      staffList()
      slotList()
      listItemAssets()
      vehicleList()
    }
  }, [selectedNewHub])

  useEffect(() => {
    console.log(assetData, "asset");
  }, [assetData])

  useEffect(() => {
    if (staffData.length > 0) {
      setSelectedStaff(deliveryData?.staff_data?.[0]?.user_id || "")
    }
  }, [staffData])


  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={updateSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setUpdateSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          {
            updateSuccess ? "Order updated successfully" : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => setValidationFail(false)}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Delivery Details</p>
        </div>

        <div className={style.iconPart}>
          <div className={style.printPart}
            onClick={() => {
              setBillModal(true);
            }}
            title="Generate Bill"
          >
            <IoPrint />
          </div>
          <div className={style.scannerPart}
            onClick={() => {
              setScannerModal(true);
            }}
            title="sShow QR Code"
          >
            <IoQrCodeOutline />
          </div>
        </div>

      </div>
      {Object.keys(deliveryData).length === 0 ? (
        <p>No data found</p>
      ) : (
        <div className={style.body}>
          <div className={style.bodyLeft}>
            <div className={style.orderDetails}>
              <div className={style.orderDetailHead}>
                <div className={style.orderNumber}>
                  <p>Del order no : {deliveryData?.delivery_data?.order_number || "N/A"}</p>
                  <p>Referral no : {deliveryData?.order_data?.[0]?.order_number || "N/A"}</p>
                </div>
                <div className={style.slotData}><span>Date:{convertDateFormat(deliveryData?.order_data?.[0]?.scheduled_date?.[0]?.$date || "N/A")}</span>
                  <span>Slot : {getSlotTimes(deliveryData?.order_data?.[0]?.slot_id || "N/A")}</span></div>
              </div>
              <div className={style.orderDetailBody}>
                {deliveryData?.item_data?.map((item, index) => (
                  <div className={style.orderDetailCard} key={index}>
                    <div className={style.orderDetailCardLeft}>
                      <div className={style.cardImage}>
                        <img
                          style={{ width: "80px", height: "80px" }}
                          src={item.media_thumbnail ? item.media_thumbnail : sampleImage}
                          alt="sample"
                        />
                      </div>
                    </div>
                    <div className={style.orderDetailCardRight}>
                      <div className={style.cardRightTop}>
                        <span>{item.name}</span>
                        <p>₹{item.selling_price}</p>
                      </div>
                      <div className={style.cardRightBottom}>
                        <p>Quantity:{item.quantity}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {selectedAssets && selectedAssets.length > 0 && selectedAssets.map((item, index) => (
                  <div className={style.orderDetailCard} key={index}>
                    <div className={style.orderDetailCardLeft}>
                      <div className={style.cardImage}>
                        <img
                          style={{ width: "80px", height: "80px" }}
                          src={item.media?.[0] ? item.media[0].MediaThumbnail : sampleImage}
                          alt="sample"
                        />
                      </div>
                    </div>
                    <div className={style.orderDetailCardRight}>
                      <div className={style.cardRightTop}>
                        <span>{item.ItemName}</span>
                        <p></p>
                      </div>
                      <div className={style.cardRightBottom}>
                        <p>Quantity:{item.quantity ? item.quantity : 1}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={style.addAssetButton}>
                <button onClick={() => {
                  setModalOpen(true);
                }}>Add Assets</button>
              </div>
              <div className={style.orderPayment}>
                <div className={style.orderPaymentLeft}>
                  <p>Order note:</p>
                  <span>{deliveryData?.order_data?.[0]?.customer_remark || "N/A"}</span>
                </div>
                <div className={style.orderPaymentRight}>
                  <div className={style.orderPaymentRightSection}>
                    <div className={style.paymentData}>
                      <p>Sub Total</p>
                      <p>₹{deliveryData?.order_data?.[0]?.gross_amount || "N/A"}</p>
                    </div>
                    <div className={style.paymentData}>
                      <p>Discount</p>
                      <p>₹{deliveryData?.order_data?.[0]?.total_discount_amount || "0"}</p>
                    </div>
                    <div className={style.paymentData}>
                      <p>Tax</p>
                      <p>₹{deliveryData?.order_data?.[0]?.total_tax_amount || "N/A"}</p>
                    </div>
                    <div className={style.paymentSeparateLine}></div>
                    <div className={style.paymentTotalData}>
                      <p>Total</p>
                      <p>₹{deliveryData?.order_data?.[0]?.net_amount || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.customerDetails}>
              <div className={style.customerDetailsHead}>
                <p>Customer Details</p>
                <FaAngleDown />
              </div>
              <div className={style.customerDetailsBody}>
                <div className={style.customerDetailsBodyLeft}>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Name</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <span>{deliveryData?.customer_data?.[0]?.customer_name || 'N/A'}</span>
                    </div>
                  </div>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Email</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <span>{deliveryData?.customer_data?.[0]?.customer_email || 'N/A'}</span>
                    </div>
                  </div>
                </div>
                <div className={style.customerDetailsBodyRight}>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Phone</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <span>{deliveryData?.customer_data?.[0]?.customer_phone || 'N/A'}</span>
                    </div>
                  </div>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Address</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <div>
                      <p>{deliveryData?.order_data?.[0]?.address_detail?.[0]?.name || 'N/A'}</p>
                      <p>{deliveryData?.order_data?.[0]?.address_detail?.[0]?.city || 'N/A'}</p>
                      <p>{deliveryData?.order_data?.[0]?.address_detail?.[0]?.district || 'N/A'}</p>
                      <p>{deliveryData?.order_data?.[0]?.address_detail?.[0]?.pin_code || 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.bodyRight}>
            <div className={style.staffDetails}>
              <div className={style.staffDetailsHead}>
                <p>Staff Details</p>
                <MdEdit />
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Name</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <FormControl fullWidth>
                    <InputLabel>Select staff</InputLabel>
                    <Select
                      disabled={deliveryData.delivery_data?.order_status === 3 || deliveryData.delivery_data?.order_status === 4}
                      size="small"
                      value={selectedStaff || ""}
                      onChange={handleStaffChange}
                      label="Select staff"
                    >
                      {staffData.length > 0 ? staffData.map((staff, i) => (
                        <MenuItem key={i} value={staff.user_id}>{staff.firstname}</MenuItem>
                      )) : (
                        <MenuItem value="" disabled>
                          No staff available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Phone</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>{deliveryData?.staff_data?.[0]?.contactNumber || 'N/A'}</span>
                </div>
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Delivery status</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <FormControl fullWidth size="small" disabled={deliveryData.delivery_data?.order_status === 3 || deliveryData.delivery_data?.order_status === 4}>
                    <InputLabel>Select Status</InputLabel>
                    <Select
                      value={selectedStatus ? selectedStatus : ""}
                      onChange={(e) => {
                        if (e.target && e.target.value) {
                          setSelectedStatus(e.target.value);
                        }
                      }}
                      label="Select Status"
                    >
                      {filterMenuItem && filterMenuItem.length > 0 ? filterMenuItem.map((status, i) => {
                        return (
                          <MenuItem key={i} value={status.status_code}>
                            {status.status_name}
                          </MenuItem>
                        );
                      }) : ""}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className={style.staffDetails}>
              <div className={style.staffDetailsHead}>
                <p>Vehicle Details</p>
                <MdEdit />
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Vehicle</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <FormControl fullWidth>
                    <InputLabel>Select vehicle</InputLabel>
                    <Select
                      disabled={deliveryData.delivery_data?.order_status === 3 || deliveryData.delivery_data?.order_status === 4}
                      size="small"
                      value={selectedVehicle || ""}
                      onChange={(e) => {
                        if (e.target && e.target.value) {
                          setSelectedVehicle(e.target.value);
                        }
                      }}
                      label="Select vehicle"
                    >
                      {vehicleData.length > 0 ? vehicleData.map((vehicle, i) => (
                        <MenuItem key={i} value={vehicle.vehicle_id}>{vehicle.vehicle_name}</MenuItem>
                      )) : (
                        <MenuItem value="" disabled>
                          No vehicle available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

            </div>
            <div className={style.paymentDetails}>
              <div className={style.staffDetailsHead}>
                <p>Payment Details</p>
                <MdEdit />
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Payment mode</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>Cash on delivery</span>
                </div>
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Payment status</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>Unpaid</span>
                </div>
              </div>
            </div>
            <div className={style.buttonSection}>
              <button disabled={deliveryData.delivery_data?.order_status === 3 || deliveryData.delivery_data?.order_status === 4} onClick={() => {
                deliveryUpdate()
              }}>Update</button>
            </div>
          </div>
        </div>
      )}
      <>
        <Modal
          open={modalOpen}
          aria-labelledby="create-order-modal"
          aria-describedby="create-order-form"
        >
          <Paper className={style.modal} elevation={6}>
            <div className={style.modalHeader}>
              <h2 id="create-order-modal">Map Assets</h2>
              <MdOutlineQrCodeScanner style={{ cursor: 'pointer' }} size={24} title="Scan Here"
                onClick={() => setScannerOpen(true)}
              />
            </div>
            <div className={style.modalContent}>
              <Box sx={{ minWidth: 200 }}>
                <Autocomplete
                  multiple
                  id="asset-select"
                  options={assetData}
                  getOptionLabel={(option) => option.ItemName}
                  value={selectedAssets}
                  onChange={(event, newValue) => {
                    setSelectedAssets(newValue);  // Update with entire object
                  }}
                  isOptionEqualToValue={(option, value) => option.itemID === value.itemID}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an Asset"
                      variant="outlined"
                      size="small"
                      sx={{
                        "& fieldset": {
                          borderColor: "#59293a",
                          borderWidth: 2,
                        },
                        "&:hover fieldset": {
                          borderColor: "#794a57",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#a96b84",
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>

        {scannerOpen && (
          <Modal
            open={scannerOpen}
            onClose={() => setScannerOpen(false)}
            aria-labelledby="qr-scanner-modal"
          >
            <Paper className={style.modal} elevation={6}>
              <IconButton
                aria-label="close"
                className={style.closeButton}
                onClick={() => {
                  setScannerOpen(false)
                }}
                sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                <CgCloseR />
              </IconButton>
              <QrScanner
                onScan={handleScan}
                onError={handleError}
                style={{ width: '100%' }}
              />
            </Paper>
          </Modal>
        )}
      </>

      <Modal
        open={scannerModal}
        aria-labelledby="create-order-modal"
        aria-describedby="create-order-form"
        onClose={() => {
          setScannerModal(false);
        }}
      >
        <Paper className={style.modalQr} elevation={6}>
          <div className={style.modalContentQr}>
            <Box sx={{ minWidth: 200 }}>
              <IconButton
                aria-label="close"
                className={style.closeButton}
                onClick={() => {
                  setScannerModal(false);
                }}
                sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                <CgCloseR />
              </IconButton>
              <QRCodeCanvas value={delivery_id || ''} size={256} />
            </Box>
          </div>
        </Paper>
      </Modal>

      <Modal
        open={billModal}
        aria-labelledby="bill-modal"
        aria-describedby="bill-modal-description"
        onClose={() => {
          setBillModal(false);
        }}
      >
        <Paper className={style.modalBill} elevation={6}>
          <div className={style.dateTime} style={{ top: '1rem', right: '1rem', fontSize: '0.50rem' }}>
            <p>{formattedDateTime}</p>
          </div>
          <div className={style.billHeader}>
            <h1 style={{ textAlign: "center", marginBottom: "1rem" }}>TeaMan</h1>
          </div>

          <div className={style.customerDetails} style={{ marginBottom: "1rem" }}>
            <div className={style.customerDetail} style={{ marginBottom: "0.5rem" }}>
              <strong>Customer Name:</strong> {deliveryData?.customer_data?.[0]?.customer_name || "N/A"}
            </div>
            <div className={style.customerDetail} style={{ marginBottom: "0.5rem" }}>
              <strong>Phone:</strong> {deliveryData?.customer_data?.[0]?.customer_phone || "N/A"}
            </div>
            <div className={style.customerDetail} style={{ marginBottom: "0.5rem" }}>
              <strong>Address:</strong> {deliveryData?.order_data?.[0]?.address_detail?.[0]?.address || "N/A"}
            </div>
            <div className={style.customerDetail}>
              <strong>Landmark:</strong> {deliveryData?.order_data?.[0]?.address_detail?.[0]?.name || "N/A"}
            </div>
            <div className={style.customerDetail}>
              <strong>City:</strong> {deliveryData?.order_data?.[0]?.address_detail?.[0]?.city || "N/A"}
            </div>
            <div className={style.customerDetail}>
              <strong>District:</strong> {deliveryData?.order_data?.[0]?.address_detail?.[0]?.district || "N/A"}
            </div>
          </div>

          <div className={style.billDetails}>
            {deliveryData?.item_data?.map((item, index) => (
              <div className={style.billItem} key={index}>
                <div className={style.billItemName}>
                  <p>{item.name}</p>
                </div>
                <div className={style.billItemPrice}>
                  <p>₹{item.selling_price}</p>
                </div>
              </div>
            ))}
          </div>

          <div className={style.billTotal}>
            <h2>Total:</h2>
            <h2>₹{deliveryData?.order_data?.[0]?.net_amount || "N/A"}</h2>
          </div>

          <div className={style.billQRCode} style={{ textAlign: "center", margin: "1.5rem 0" }}>
            <QRCodeCanvas value={delivery_id || ''} size={128} />
          </div>

          <div className={style.billPrintButton} style={{ textAlign: "center" }}>
            <button
              onClick={() => window.print()}
              style={{
                backgroundColor: "#794a57",
                color: "white",
                border: "none",
                padding: "0.5rem 1rem",
                fontSize: "1rem",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Print
            </button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default DeliveryDetails;
