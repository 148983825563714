import React, { useEffect, useState } from "react";
import style from "./Category.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import {
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { TbCategoryPlus } from "react-icons/tb";
import { IoSearch } from "react-icons/io5";
import { BiFoodMenu } from "react-icons/bi";
import { useOutletContext } from 'react-router-dom';
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";

function Category() {

  const { selectedNewHub } = useOutletContext();

  const [orgData, setOrgData] = useState([]);
  const [createPopup, setCreatePopup] = useState("");
  const [createOrgId, setCreateOrgId] = useState("");
  const [createName, setCreateName] = useState("");
  const [createDescription, setCreateDescription] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [showCategoryMessage, setShowCategoryMessage] = useState(true);
  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editStatus, setEditStatus] = useState("active");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [roleFail, setRoleFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [categorySuccessSnack, setCategorySuccessSnack] = useState(false);
  const [categoryDeletionSnack, setCategoryDeletionSnack] = useState(false);
  const [categoryUpdationSnack, setCategoryUpdationSnack] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noCategoriesFound, setNoCategoriesFound] = useState(false);
  const [categoryImage, setCategoryImage] = useState({ preview: "", data: "" });
  const [ediCategoryImage, setEditCategoryImage] = useState({ preview: "", data: "" });

  const token = JSON.parse(localStorage.getItem("access-token"));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const createValidation = () => {
    if (!createName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!createDescription) {
      setErrorMessage("Description is required");
      return false;
    }
    // if (!createOrgId) {
    //   setErrorMessage("Organization is required");
    //   return false;
    // }
    return true;
  };

  const updateValidation = () => {
    if (!editName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!editDescription) {
      setErrorMessage("Description is required");
      return false;
    }

    return true;
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setCategoryImage(img);
  };
  const handleEditFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setEditCategoryImage(img);
  };

  const handleClick = () => {
    document.getElementById("imageInput").click();
  };
  const handleUpdateImageClick = () => {
    document.getElementById("updateImageInput").click();
  };

  async function orgListing() {
    await fetch(`${baseUrl}/organizations/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        if (data.status === true) {
          setOrgData(data.data);
        }
      });
  }

  async function createCategory() {
    let formData = new FormData()
    formData.append('image', categoryImage.data);
    formData.append('org_id', selectedNewHub);
    formData.append('parent_id', "");
    formData.append('createName', createName);
    formData.append('description', createDescription);
    const reqData = {
      org_id: createOrgId,
      parent_id: "",
      name: createName,
      description: createDescription,
    };
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/categories/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "createdData");
          if (data.status === true) {
            setCreatePopup(false);
            setCreateName("");
            setCreateDescription("");
            setCreateOrgId("");
            categoryList(selectedNewHub);
            setCategorySuccessSnack(true);
            // setCreateOrgId(createOrgId);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function categoryList(orgId) {
    if (!orgId) {
      setCategoryData([]);
    }
    const reqData = {
      org_id: orgId,
      category_id: "",
    };
    await fetch(`${baseUrl}/categories/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "cateogoryList");
        if (data.status === true) {
          setCategoryData(data.data);
        }
      });
  }

  async function categoryUpdate() {
    let formData = new FormData()
    if (ediCategoryImage.data !== "") {
      formData.append('image', ediCategoryImage.data);
    }
    formData.append('category_id', editId);
    formData.append('name', editName);
    formData.append('description', editDescription);
    formData.append('status', editStatus);

    const reqData = {
      category_id: editId,
      name: editName,
      description: editDescription,
      status: editStatus,
    };
    if (!updateValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/category/edit`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "editCategoryData");
          if (data.status === true) {
            setUpdatePopup(false);
            setEditName("");
            setEditDescription("");
            categoryList(selectedNewHub);
            setCategoryUpdationSnack(true);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function categoryDelete() {
    await fetch(`${baseUrl}/categories/delete`, {
      method: "POST",
      body: JSON.stringify({
        category_id: deleteId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "deleteData");
        if (data.status === true) {
          setDeletePopup(false);
          categoryList(selectedNewHub);
          setCategoryDeletionSnack(true);
        } else {
          setApiDescription(data.description);
          setRoleFail(true);
        }
      });
  }

  async function categorySearch(searchOrgId, searchValue) {
    const reqData = {
      org_id: searchOrgId,
      search_value: searchValue,
      category_id: "",
    };
    await fetch(`${baseUrl}/categories/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "deleteData");
        if (data.status === true) {
          setCategoryData(data.data);
          setNoCategoriesFound(false);
        } else if (searchValue === "") {
          categoryList(searchOrgId);
          setNoCategoriesFound(false);
        } else {
          setNoCategoriesFound(true);
          setCategoryData([]);
        }
      });
  }

  useEffect(() => {
    orgListing();
  }, []);

  useEffect(() => {
    console.log(selectedNewHub, "newh hub");
    categoryList(selectedNewHub)
  }, [selectedNewHub])
  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          categorySuccessSnack || categoryDeletionSnack || categoryUpdationSnack
        }
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCategorySuccessSnack(false);
          setCategoryDeletionSnack(false);
          setCategoryUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {categorySuccessSnack
            ? "Category created successfully"
            : categoryDeletionSnack
              ? "Category deleted successfully"
              : categoryUpdationSnack
                ? "Category updated successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roleFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Category</p>
        </div>
        {/* <div className={style.dropdownSelect}>
          <Box sx={{ minWidth: 200 }}>
            <FormControl sx={{ m: 2, minWidth: 200 }} size="small">
              <Select
                value={createOrgId}
                onChange={(e) => {
                  setCreateOrgId(e.target.value);
                  categoryList(e.target.value);
                }}
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&fieldset:hover ": {
                        borderColor: "#794a57",
                      },
                      "& fieldset:active": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  {" "}
                  <em>Select a Hub</em>
                </MenuItem>
                {orgData.map((item) => (
                  <MenuItem key={item.org_id} value={item.org_id}>
                    {item.org_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div> */}
        <div className={style.search}>
          <IoSearch className={style.searchIcon} />
          <input
            type="text"
            placeholder=""
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              categorySearch(selectedNewHub, e.target.value);
            }}
          />
        </div>
        <button
          onClick={() => {
            setCreatePopup(true);
          }}
          className={style.buttonSection}
        >
          Add Category
        </button>
      </div>
      {/* <div className={style.buttonSection}>
                <button onClick={() => {
                    setCreatePopup(true)
                }}>
                    Add Category
                </button>
            </div> */}
      <div className={style.tableSection}>
        {!noCategoriesFound && (
          <TableContainer className={style.table} component={Paper}>
            {/* <div className={style.tableHeading}>Category</div> */}
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead style={{ backgroundColor: "#f7eedf" }}>
                <TableRow>
                  {/* <TableCell></TableCell> */}
                  <TableCell style={{ width: '15%' }}>Image</TableCell>
                  <TableCell align="left" style={{ width: '20%' }}>Name</TableCell>
                  <TableCell align="left" style={{ width: '34%' }}>Description</TableCell>
                  <TableCell align="center" style={{ width: '15%' }}>Status</TableCell>
                  <TableCell align="center" style={{ width: '16%' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryData.map((item) => (
                  <TableRow key={item.category_id}>
                    <TableCell component="th" scope="row" >
                      <div className={style.itemImage}>
                        <img src={item.image} alt="Image description" />
                      </div>
                    </TableCell>
                    <TableCell align="left" >
                      {item.name}
                    </TableCell>
                    <TableCell align="left" >{item.description.length > 130 ? 
                          item.description.substring(0, 120) + '...' : 
                          item.description}</TableCell>
                    <TableCell align="center">
                    {item.status==="active"?<FaRegCircleCheck style={{color:'green',fontSize:"1.35rem"}}/>:item.status==="Inactive"?<IoIosCloseCircleOutline style={{color:'red',fontSize:"1.5rem"}}/>:""}</TableCell>
                    <TableCell align="center" >
                      <div className={style.actionIcons} style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
                        <BiEditAlt
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          title="Edit"
                          onClick={() => {
                            setUpdatePopup(true);
                            setEditId(item.category_id);
                            setEditName(item.name);
                            setEditDescription(item.description);
                            setEditStatus(item.status);
                            setEditCategoryImage({ preview: item.image, data: "" })
                          }}
                        />
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            marginLeft: "15px",
                          }}
                          title="Delete"
                          onClick={() => {
                            setDeletePopup(true);
                            setDeleteId(item.category_id);
                            setDeleteName(item.name);
                          }}
                        />

                        {item.category_type === "category" && (
                          <Link
                            to={`/category/subcategory?orgId=${selectedNewHub}&categoryId=${item.category_id}`}
                          >
                            <TbCategoryPlus
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                marginLeft: "15px",
                                color: "black",
                              }}
                              title="View Sub category"
                            />
                          </Link>
                        )}
                        {item.category_type === "stockitem" && (
                          <Link
                            to={`/stockitems?orgId=${selectedNewHub}&categoryId=${item.category_id}`}
                          >
                            <BiFoodMenu
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                marginLeft: "15px",
                                color: "black",
                              }}
                              title="View Stock Items"
                            />
                          </Link>
                        )}
                        {item.category_type === "" && (
                          <div>
                            <Link
                              to={`/category/subcategory?orgId=${selectedNewHub}&categoryId=${item.category_id}`}
                            >
                              <TbCategoryPlus
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  marginLeft: "15px",
                                  color: "black",
                                }}
                                title="View Sub category"
                              />
                            </Link>
                            <Link
                              to={`/stockitems?orgId=${selectedNewHub}&categoryId=${item.category_id}`}
                            >
                              <BiFoodMenu
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  marginLeft: "15px",
                                  color: "black",
                                }}
                                title="View Stock Items"
                              />
                            </Link>
                          </div>
                        )}
                        </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {noCategoriesFound && (
          <Alert
            severity="error"
            color="warning"
            sx={{
              width: "100%",
            }}
          >
            No Category found, please check your input.
          </Alert>
        )}

        {!selectedNewHub && showCategoryMessage && (
          <div className={style.categoryMessage}>
            <Alert severity="error" color="warning">
              Selecet an hub first
            </Alert>
          </div>
        )}
      </div>

      {createPopup && (
        <Modal
          open={createPopup}
          onClose={() => {
            setCreatePopup(false);
            setCategoryImage({ preview: "", data: "" });
          }}
          aria-labelledby="add-hub-modal"
          aria-describedby="add-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-hub-modal">Add Category</h2>
            <div className={style.modalContent}>
              {categoryImage.preview !== "" ? (
                <>
                  <img
                    onClick={handleClick}
                    src={categoryImage.preview}
                    style={{ width: "150px", height: "150px" }}
                    alt="something wrong"
                  />
                  <input
                    id="imageInput"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                    type="file"
                  />
                </>
              ) : (
                <input
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                  type="file"
                />
              )}
              <TextField
                label="Category Name"
                fullWidth
                margin="normal"
                value={createName}
                onChange={(e) => {
                  setCreateName(e.target.value);
                }}
              />
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={createDescription}
                onChange={(e) => {
                  setCreateDescription(e.target.value);
                }}
              />
              {/* <TextField
                select
                label="Organization"
                value={createOrgId}
                onChange={(e) => {
                  setCreateOrgId(e.target.value);
                }}
                fullWidth
                margin="normal"
              >
                {orgData.map((item) => (
                  <MenuItem key={item.org_id} value={item.org_id}>
                    {item.org_name}
                  </MenuItem>
                ))}
              </TextField> */}
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    createCategory();
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false);
          }}
          aria-labelledby="edit-hub-modal"
          aria-describedby="edit-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="edit-hub-modal">Edit Category</h2>

            <div className={style.modalContent}>
              {ediCategoryImage.preview !== "" ? (
                <>
                  <img
                    onClick={handleUpdateImageClick}
                    src={ediCategoryImage.preview}
                    style={{ width: "150px", height: "150px" }}
                    alt="something wrong"
                  />
                  <input
                    id="updateImageInput"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleEditFileChange}
                    type="file"
                  />
                </>
              ) : (
                <input
                  accept="image/png, image/jpeg"
                  onChange={handleEditFileChange}
                  type="file"
                />
              )}
              <TextField
                label="Category Name"
                fullWidth
                margin="normal"
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
              />
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={editDescription}
                onChange={(e) => {
                  setEditDescription(e.target.value);
                }}
              />
              <TextField
                select
                label="Status"
                fullWidth
                margin="normal"
                value={editStatus}
                onChange={(e) => {
                  setEditStatus(e.target.value);
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.modalActions}>
                <button
                  className={style.EditSub}
                  variant="contained"
                  onClick={() => {
                    categoryUpdate();
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.EditClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>
                Are you sure you want to delete {deleteName}?
              </p>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    categoryDelete();
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default Category;
