import React, { useState, useRef, useEffect } from "react";
import style from "./ItemCreation.module.css";
import {
  MenuItem, TextField, FormControl, Select, Checkbox, InputLabel, Alert,
  Snackbar,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FaImage } from "react-icons/fa6";
import { IoMdCloudUpload } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { baseUrl } from "../Url";
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function ItemCreation() {
  const { selectedNewHub } = useOutletContext();
  const navigate = useNavigate()
  const typeRef = useRef(null);
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedType, setSelectedType] = useState({});
  const [showType, setShowType] = useState({});
  const [attributeSections, setAttributeSections] = useState([]);
  const [selectedVarient, setSelectedVarient] = useState("");
  const [variantSections, setVariantSections] = useState([]);
  const [attributeList, setSttributeList] = useState([]);
  const [takenAttribute, setTakenAttribute] = useState("");
  const [itemImages, setItemImages] = useState([]);
  const [variantExist, setVariantExist] = useState(null);
  const [manufacturingItem, setManufacturingItem] = useState(false);
  const [fixedAsset, setFixedAsset] = useState(false);
  const [transferableAsset, setTransferableAsset] = useState(false);
  const [maintainStock, setMaintainStock] = useState(false);
  const [itemName, setItemName] = useState("")
  const [shortDesc, setShortDesc] = useState("")
  const [longDesc, setLongDesc] = useState("")
  const [hsnCode, setHsnCode] = useState("")
  const [barCode, setBarCode] = useState("")
  const [itemType, setItemType] = useState("")
  const [makingCost, setMakingCost] = useState("")
  const [inventoryCost, setInventoryCost] = useState("")
  const [purchasePrice, setPurchasePrice] = useState("")
  const [salePrice, setSalePrice] = useState("")
  const [saleDiscountPercentage, setSaleDiscountPercentage] = useState("")
  const [SaleTaxPercentage, setSaleTaxPercentage] = useState("")
  const [purchaseTaxPercentage, setPurchasePercentage] = useState("")
  const [status, setStatus] = useState("")
  const [categoryData, setCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [attributeData, setAttributeData] = useState([]);
  const [savedAttributes, setSavedAttributes] = useState([])
  const [savedVariation, setSavedVariation] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [taxType, setTaxType] = useState(null)

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200, // Set the maximum height you want
      },
    },
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTypeSelect = (attributeID, type) => {
    setSelectedType((prevSelectedType) => {
      const typesForAttribute = prevSelectedType[attributeID] || [];
      const isChecked = typesForAttribute.find((selected) => selected.type_code === type.type_code);

      if (!isChecked) {
        return {
          ...prevSelectedType,
          [attributeID]: [...typesForAttribute, type]
        };
      } else {
        return prevSelectedType;
      }
    });
  };
  const handleRemoveType = (attributeID, typeCodeToRemove) => {
    setSelectedType((prevSelectedType) => {
      const updatedTypesForAttribute = prevSelectedType[attributeID].filter(
        (type) => type.type_code !== typeCodeToRemove
      );

      return {
        ...prevSelectedType,
        [attributeID]: updatedTypesForAttribute
      };
    });
  };

  const generateUniqueId = () => {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomId = Math.random().toString(36).substring(2, 15); // Generate random number
    return `${timestamp}-${randomId}`; // Combine timestamp and random number
  };

  const handleAddAttribute = async () => {
    if (selectedAttribute === "custom product attribute") {
      const newId = generateUniqueId();
      // Add custom attribute section
      setAttributeSections((prevSections) => [
        ...prevSections,
        { type: "custom", name: "", values: "", isExpanded: true, attributeID: newId },
      ]);
    } else {
      const selectedAttributeObj = attributeData.find(
        (attr) => attr.attribute_code === selectedAttribute
      );
      console.log('selectedAttributeObj-------', selectedAttributeObj);

      // Check if the attribute already exists in the attributeSections
      const attributeExists = attributeSections.some(
        (section) => section.attributeID === selectedAttributeObj.attribute_id
      );
      console.log('attributeExists---------', attributeExists);

      if (!attributeExists) {
        const attributeTypes = await attrTypeList()
        setAttributeSections((prevSections) => [
          ...prevSections,
          {
            type: "existing",
            name: selectedAttributeObj.attribute_name,
            values: "",
            isExpanded: true,
            attributeID: selectedAttributeObj.attribute_id,
            attributeType: attributeTypes
          },
        ]);
      } else {
        console.log("Attribute already exists");

      }
    }
  };


  const handleDeleteAttribute = (attributeID) => {
    // Remove from attributeSections
    setAttributeSections((prevSections) =>
      prevSections.filter((section) => section.attributeID !== attributeID)
    );

    // Remove from selectedType
    setSelectedType((prevSelectedType) => {
      const { [attributeID]: _, ...remainingSelectedTypes } = prevSelectedType;
      return remainingSelectedTypes;
    });

    // Remove from savedAttributes
    setSavedAttributes((prevSavedAttributes) =>
      prevSavedAttributes.filter((savedAttr) => savedAttr.attributeID !== attributeID)
    );
  };


  const handleExpand = (attributeID) => {
    setAttributeSections((prevSections) =>
      prevSections.map((section) =>
        section.attributeID === attributeID
          ? { ...section, isExpanded: !section.isExpanded }
          : section
      )
    );
  };


  const handleSaveAttributes = (attributeID) => {
    console.log('Saving attributes for attributeID:', attributeID);
    const typesForAttribute = selectedType[attributeID] || [];
    console.log('typesForAttribute-------', typesForAttribute);
    const attributeObject = { [attributeID]: typesForAttribute };
    console.log('attributeObject-----', attributeObject);
    console.log('Current typesForAttribute sections:', [attributeObject]);

    if (typesForAttribute.length > 0) {
      setSavedAttributes((prevSavedAttributes) => {
        const newSavedAttributes = Array.isArray(prevSavedAttributes) ? [...prevSavedAttributes] : [];
        console.log('Previous saved attributes:', prevSavedAttributes);

        // Find the index of the existing attribute with the same attributeID
        const existingIndex = newSavedAttributes.findIndex(
          (attr) => Object.keys(attr)[0] === attributeID
        );

        if (existingIndex !== -1) {
          // If found, replace the existing attribute with the new one
          newSavedAttributes[existingIndex] = attributeObject;
        } else {
          // If not found, add the new attribute
          newSavedAttributes.push(attributeObject);
        }

        console.log('Updated saved attributes:', newSavedAttributes);
        return newSavedAttributes;
      });
    }
  };

  // const handleVarient = () => {
  //   const newId = generateUniqueId();
  //   setVariantSections((prevVariantSections) => [
  //     ...prevVariantSections,
  //     {
  //       type: "custom",
  //       name: "",
  //       isExpanded: true,
  //       variantID: newId,
  //       image: [],
  //       regular_price: "",
  //       sale_price: "",
  //       stock_status: "",
  //       description: "",
  //       attributes: {} // Initialize the attributes field
  //     }
  //   ]);
  // };
  const handleVarient = () => {
    const newId = generateUniqueId();
    const selectedAttributeObj = attributeData.find(
      (attr) => attr.attribute_code === selectedAttribute
    );
    const selectedAttributeName = selectedAttributeObj ? selectedAttributeObj.attribute_name : "";
    setVariantSections((prevVariantSections) => [
      ...prevVariantSections,
      {
        type: "custom",
        name: selectedAttributeName,
        isExpanded: true,
        variantID: newId,
        image: [],
        regular_price: "",
        sale_price: "",
        stock_status: "",
        description: "",
        attributes: {} // Initialize the attributes field
      }
    ]);
  };

  const renderVariantDropdowns = (variantID) => {
    return savedAttributes.map((attribute, index) => {
      const attributeID = Object.keys(attribute)[0];
      const attributeOptions = attribute[attributeID];
      return (
        <TextField
          key={attributeID}
          select
          label={`Select from Attribute ${index + 1}`}
          fullWidth
          margin="normal"
          value={variantSections.find(v => v.variantID === variantID)?.attributes[attributeID] || ''}
          onChange={(e) => {
            setVariantSections((prevVariantSections) => {
              return prevVariantSections.map((variant) => {
                if (variant.variantID === variantID) {
                  const newAttributes = { ...variant.attributes, [attributeID]: e.target.value };
                  return { ...variant, attributes: newAttributes };
                }
                return variant;
              });
            });
          }}
        >
          {attributeOptions.map((option) => (
            <MenuItem key={option.type_id} value={option.type_id}>
              {option.type_name}
            </MenuItem>
          ))}
        </TextField>
      );
    });
  };


  const handleVariantChange = (variantID, field, value) => {
    setVariantSections((prevSections) =>
      prevSections.map((section) =>
        section.variantID === variantID
          ? { ...section, [field]: value }
          : section
      )
    );
  };

  const handleVariantImage = () => {
    document.getElementById("variantImageUpload").click();
  };

  const handleVariantImageUpload = async (e, variantID) => {
    const files = e.target.files;
    const uploadedImages = [];

    for (let file of files) {
      const formData = new FormData();
      formData.append('image', file);
      try {
        const response = await fetch(`${baseUrl}/manage/media`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          const imageUrl = responseData.data[0].image_url;
          uploadedImages.push(imageUrl);
        } else {
          console.error('Failed to upload image', response);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    if (uploadedImages.length > 0) {
      setVariantSections((prevSections) =>
        prevSections.map((section) =>
          section.variantID === variantID
            ? { ...section, image: (section.image || []).concat(uploadedImages) }
            : section
        )
      );
    }
  };

  const handleDeleteVariantImage = (variantID, imageIndex) => {
    setVariantSections((prevSections) =>
      prevSections.map((section) =>
        section.variantID === variantID
          ? {
            ...section,
            image: section.image.filter((_, index) => index !== imageIndex),
          }
          : section
      )
    );
  };


  const handleSaveVariant = (variantId) => {
    setVariantSections((prevSections) => {
      const variantToSave = prevSections.find((section) => section.variantID === variantId);

      if (!variantToSave) return prevSections;

      const { type, isExpanded, variantID, ...variantData } = variantToSave;
      console.log('variantToSave//////', variantData);

      const areAttributesEqual = (attr1, attr2) => {
        const keys1 = Object.keys(attr1);
        const keys2 = Object.keys(attr2);
        if (keys1.length !== keys2.length) return false;
        for (let key of keys1) {
          if (attr1[key] !== attr2[key]) return false;
        }
        return true;
      };

      // Check if a variant with the same attributes already exists
      const existingVariant = savedVariation.find((savedVar) =>
        areAttributesEqual(savedVar.attributes, variantData.attributes)
      );

      if (existingVariant) {
        setErrorMessage("A variant with the same attributes already exists!");
        setValidationFail(true)
        return prevSections;
      }

      // Update savedVariation state
      setSavedVariation((prevSavedVariation) => {
        const updatedVariations = prevSavedVariation.filter(
          (variation) => variation.variantID !== variantId
        );
        return [...updatedVariations, variantData];
      });
      handleVarientExpand(variantId);

      return prevSections;
    });
  };


  const handleVarientExpand = (variantID) => {
    setVariantSections((prevState) =>
      prevState.map((variSection, idx) =>
        variSection.variantID === variantID
          ? { ...variSection, isExpanded: !variSection.isExpanded }
          : variSection
      )
    );
  };

  const handleVarientDelete = (variantID) => {
    setVariantSections((prevSections) =>
      prevSections.filter((section) => section.variantID !== variantID)
    );
    setSavedVariation((prevSections) =>
      prevSections.filter((section) => section.variantID !== variantID)
    );
  };

  const handleImageUpload = () => {
    document.getElementById("stockItemImage").click();
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const uploadedImages = [];

    for (let file of files) {
      const formData = new FormData();
      formData.append('image', file);
      try {
        const response = await fetch(`${baseUrl}/manage/media`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          const imageUrl = responseData.data[0].image_url;
          uploadedImages.push(imageUrl);
        } else {
          console.error('Failed to upload image', response);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    if (uploadedImages.length > 0) {
      setItemImages((prevImages) => [...prevImages, ...uploadedImages]);
    }
  };


  const handleDeleteImage = (index) => {
    const updatedImages = itemImages.filter((_, i) => i !== index);
    setItemImages(updatedImages);
  };

  const handleCheckboxChange = (categoryID) => {
    const newSelectedcategory = selectedCategory.includes(categoryID)
      ? selectedCategory.filter((id) => id !== categoryID)
      : [...selectedCategory, categoryID];

    setSelectedCategory(newSelectedcategory);
  };

  useEffect(() => {
    console.log(savedAttributes, 'savedAttributes UseEffect');
  }, [savedAttributes])
  useEffect(() => {
    console.log(selectedType, 'selectedType UseEffect');
  }, [selectedType])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (typeRef.current && !typeRef.current.contains(event.target)) {
        setShowType(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const createValidation = () => {
    if (!itemName || itemName.trim().length === 0) {
      setErrorMessage("Item name is required");
      return false;
    }
    if (!selectedCategory && !fixedAsset && !transferableAsset) {
      setErrorMessage("Please select a category");
      return false;
    }
    if (!itemType) {
      setErrorMessage("Please select an item type");
      return false;
    }
    if (makingCost === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Making cost required");
      return false;
    }
    if (inventoryCost === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Inventory cost required");
      return false;
    }
    if (purchasePrice === "") {
      setErrorMessage("Purchase price required");
      return false;
    }
    if (salePrice === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Sale price required");
      return false;
    }
    if (saleDiscountPercentage === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Sale discount percentage required");
      return false;
    }
    if (purchaseTaxPercentage === "") {
      setErrorMessage("Purchase tax percentage required");
      return false;
    }
    if (SaleTaxPercentage === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Sale tax percentage required");
      return false;
    }
    if (!status) {
      setErrorMessage("Status is required");
      return false;
    }
    if (!itemImages || itemImages.length === 0) {
      setErrorMessage("Please upload at least one image");
      return false;
    }
    // if (savedVariation.length < 1 && variantExist) {
    //   setErrorMessage("Add at least one variant");
    //   return false;
    // }

    return true;
  };


  async function categoryList() {
    const reqData = {
      org_id:selectedNewHub
    }
    console.log(reqData,'reqdataaa')
    await fetch(`${baseUrl}/hub/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCategoryData(data.data);
        }
      });
  }


  async function listAttribute() {
    await fetch(`${baseUrl}/attribute/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({})
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setAttributeData(data.data);
        }
      })
  }

  async function attrTypeList() {
    const reqData = {
      attribute_code: selectedAttribute,
      attribute_id: ""
    }
    const response = await fetch(`${baseUrl}/attribute/type/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData.data


    } else {
      console.error('Failed to upload image', response);
    }
  }


  async function createItem() {
    let itemData = {
      org_id: selectedNewHub,
      ItemName: itemName,
      category_id: selectedCategory,
      ShortDescription: shortDesc,
      LongDescription: longDesc,
      ItemType: itemType,
      IsVariantsExist: variantExist,
      MakingCost: parseFloat(makingCost),
      InventoryCost: parseFloat(inventoryCost),
      PurchasePrice: parseFloat(purchasePrice),
      SalePrice: parseFloat(salePrice),
      SaleDiscountPercentage: parseFloat(saleDiscountPercentage),
      PurchaseTaxPercentage: parseFloat(purchaseTaxPercentage),
      SaleTaxPercentage: parseFloat(SaleTaxPercentage),
      Status: status,
      image: itemImages,
      attribute_item: variantExist === true ? savedAttributes : [],
      variant_item: variantExist === true ? savedVariation : [],
      product_tax_type: taxType === "true" ? true : false,
      hsn_code: hsnCode,
      bar_code: barCode,
      is_manufacturing_item: manufacturingItem,
      is_fixed_asset: fixedAsset,
      is_transferable_asset:transferableAsset,
      maintain_stock: maintainStock,

    };
    console.log(itemData, 'itemData');
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/stock/item/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(itemData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "createdData");
          if (data.status === true) {
            navigate("/stockitems")
          } else {

          }
        });
    }
  }


  useEffect(() => {
    categoryList()
  }, [selectedNewHub])

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Stock Items Creation</p>
        </div>
        <div className={style.buttonSection}>
          <button onClick={() => {


            createItem()
          }}>Create Stock</button>
        </div>
      </div>
      <div className={style.tabSection}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#59293a" } }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            label="StockItem"
            sx={{
              "&.Mui-selected": {
                color: "#59293a",
                backgroundColor: "",
              },
              fontSize: "1.1rem",
              color: "#000",
              backgroundColor: "",
              margin: "0 .5rem",
              borderRadius: "10px",
              // textTransform: "capitalize",
            }}
          />
          {variantExist === true && (
            <Tab
              label=" Attributes"
              sx={{
                "&.Mui-selected": {
                  color: "#59293a",
                  backgroundColor: "",
                },
                fontSize: "1.1rem",
                color: "#000",
                backgroundColor: "",
                margin: "0 .5rem",
                borderRadius: "10px",
                // textTransform: "capitalize",
              }}
              onClick={() => {
                listAttribute()
              }}
            />
          )}
          {variantExist === true && (
            <Tab
              label=" Varients"
              sx={{
                "&.Mui-selected": {
                  color: "#59293a",
                  backgroundColor: "",
                },
                fontSize: "1.1rem",
                color: "#000",
                backgroundColor: "",
                margin: "0 .5rem",
                borderRadius: "10px",
                // textTransform: "capitalize",
              }}
            />
          )}
        </Tabs>
      </div>
      <div className={style.typography}>
        <Typography>
          {selectedTab === 0 && (
            <div className={style.tableSection}>
              <div className={style.leftSection}>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Item Name</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      className={style.textfield}
                      label="Item Name"
                      fullWidth
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Item Image</p>
                  </div>
                  <div className={style.inputSection}>
                    <div className={style.uploadImageContainer}>
                      <div className={style.uploadButton}>
                        <button onClick={handleImageUpload}>
                          Upload images <IoMdCloudUpload />
                        </button>
                        <input
                          id="stockItemImage"
                          className={style.fileInput}
                          label="Item image"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>
                      <div className={style.uploadedImages}>
                        {itemImages && itemImages.length > 0
                          ? itemImages.map((img, i) => {
                            return (
                              <div className={style.uploadImage}>
                                <img
                                  key={i}
                                  style={{ width: "100px", height: "100px" }}
                                  src={img}
                                  alt=""
                                />
                                <div className={style.uploadImageOverlay}>
                                  <RiDeleteBin6Line
                                    onClick={() => {
                                      handleDeleteImage(i);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Short Description</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      label="Short Description"
                      fullWidth
                      multiline
                      rows={2}
                      value={shortDesc}
                      onChange={(e) => {
                        setShortDesc(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Long Description</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      label="Long Description"
                      fullWidth
                      multiline
                      rows={4}
                      value={longDesc}
                      onChange={(e) => {
                        setLongDesc(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Item Type</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={itemType}
                      onChange={(e) => {
                        setItemType(e.target.value)
                      }} select label="Item Type" fullWidth>
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Service">Service</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.fixedAsset}>
                    <input type="checkbox" id="fixedAsset" className={style.containerCheck}
                      checked={fixedAsset}
                      disabled={transferableAsset}
                      onChange={(e) => {
                        setFixedAsset(e.target.checked)
                        if (e.target.checked) {
                          setTransferableAsset(false);
                        }
                      }}
                    />
                    <label htmlFor="fixedAsset">Is Fixed Asset</label>
                  </div>
                  <div className={style.transferableAsset}>
                    <input type="checkbox" id="transferableAsset" className={style.containerCheck}
                      checked={transferableAsset}
                      disabled={fixedAsset}
                      onChange={(e) => {
                        setTransferableAsset(e.target.checked)
                        if (e.target.checked) {
                          setFixedAsset(false);
                        }
                      }}
                    />
                    <label htmlFor="transferableAsset">Is Transferable Asset</label>
                  </div>
                  <div className={style.manufacturingItem}>
                    <input type="checkbox" id="manufacturingItem" className={style.containerCheck}
                      value={manufacturingItem}
                      onChange={(e) => {
                        setManufacturingItem(e.target.checked)
                      }}
                    />
                    <label htmlFor="manufacturingItem">Is Manufacturing Item</label>
                  </div>
                  <div className={style.maintainStock}>
                    <input type="checkbox" id="maintainStock" className={style.containerCheck}
                      value={maintainStock}
                      onChange={(e) => {
                        setMaintainStock(e.target.checked)
                      }}
                    />
                    <label htmlFor="maintainStock">Maintain Stock</label>
                  </div>
                </div>
                {(!fixedAsset && !transferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Varient</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      select
                      label="Varient Exist"
                      fullWidth
                      value={variantExist}
                      onChange={(e) => {
                        setVariantExist(e.target.value);
                      }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Making Cost</p>
                  </div>
                  <div className={style.inputSection}>
                    {" "}
                    <TextField value={makingCost}
                      onChange={(e) => {
                        setMakingCost(e.target.value)
                      }} label="Making Cost" fullWidth />
                  </div>
                </div>
                </>
                )}
              </div>
              <div className={style.rightSection}>
              {(!fixedAsset && !transferableAsset) && (
                  <>
              <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Inventory Cost</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={inventoryCost}
                      onChange={(e) => {
                        setInventoryCost(e.target.value)
                      }} label="Inventory Cost" fullWidth />
                  </div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Purchase Price</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={purchasePrice}
                      onChange={(e) => {
                        setPurchasePrice(e.target.value)
                      }} label="Purchase Price" fullWidth />
                  </div>
                </div>
                {(!fixedAsset && !transferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Sale Price</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={salePrice}
                      onChange={(e) => {
                        setSalePrice(e.target.value)
                      }} label="Sale Price" fullWidth />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Sale Discount Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={saleDiscountPercentage}
                      onChange={(e) => {
                        setSaleDiscountPercentage(e.target.value)
                      }} label="Sale Discount Percentage" fullWidth />
                  </div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Purchase Tax Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={purchaseTaxPercentage}
                      onChange={(e) => {
                        setPurchasePercentage(e.target.value)
                      }} label="Purchase Tax Percentage" fullWidth />
                  </div>
                </div>
                {(!fixedAsset && !transferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Sale Tax Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={SaleTaxPercentage}
                      onChange={(e) => {
                        setSaleTaxPercentage(e.target.value)
                      }} label="Sale Tax Percentage" fullWidth />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p> Tax Type</p>
                  </div>
                  <div className={style.inputSection}>
                    <label>
                      <input
                        className={style.radioButton}
                        type="radio"
                        name="taxType"
                        value="true"
                        checked={taxType === "true"}
                        onChange={(e) => {
                          setTaxType(e.target.value)
                        }}
                      />
                      Inclusive
                    </label>
                    <label>
                      <input
                        className={style.radioButton}
                        type="radio"
                        name="taxType"
                        value="false"
                        checked={taxType === "false"}
                        onChange={(e) => {
                          setTaxType(e.target.value)
                        }}
                      />
                      Exclusive
                    </label>
                  </div>
                </div>
                <div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>HSN Code</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={hsnCode}
                      onChange={(e) => {
                        setHsnCode(e.target.value)
                      }}
                      label="HSN Code" fullWidth />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Bar Code</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={barCode}
                      onChange={(e) => {
                        setBarCode(e.target.value)
                      }}
                      label="Bar Code" fullWidth />
                  </div>
                </div>
                {(!fixedAsset && !transferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Category</p>
                  </div>
                  <div className={style.inputSection}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select category"
                        multiple
                        value={selectedCategory}
                        onChange={(event) => setSelectedCategory(event.target.value)} // Add this prop
                        MenuProps={menuProps}
                      >
                        {categoryData &&
                          categoryData.length > 0 &&
                          categoryData.map((item) => {
                            return (
                              <MenuItem
                                key={item.category_id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                value={item.category_id}
                              >
                                {" "}
                                {item.name}
                                <Checkbox
                                  name={item.category_id}
                                  id={item.category_id}
                                  onChange={() => handleCheckboxChange(item.category_id)}
                                  checked={selectedCategory.includes(item.category_id)}
                                />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Status</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={status}
                      onChange={(e) => {
                        setStatus(e.target.value)
                      }} select label="Status" fullWidth>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={style.attributeSection}>
              <div className={style.attributeSelectionHeader}>
                <div className={style.attributeSelection}>
                  <TextField
                    select
                    label="Select Category"
                    fullWidth
                    margin="normal"
                    value={selectedAttribute}
                    onChange={(e) => {
                      setSelectedAttribute(e.target.value);
                    }}
                  >
                    {attributeData.map((atr) => {
                      return (
                        <MenuItem key={atr.attribute_code} value={atr.attribute_code}>
                          {atr.attribute_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className={style.attributeAddButton}>
                  <button
                    disabled={selectedAttribute === ""}
                    onClick={() => {
                      handleAddAttribute();
                      attrTypeList()
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className={style.attributeSectionBody}>
                {attributeSections.map((attribute, index) => {
                  const typesForAttribute = selectedType[attribute.attributeID] || [];
                  const isTypeVisible = showType[attribute.attributeID] || false;
                  return (
                    <div key={index} className={style.existingAttributeSection}>
                      <div className={style.existingAttributeHeader}>
                        <p>{attribute.name}</p>
                        <div className={style.attributeHeadIcon}>
                          {attribute.isExpanded ? (
                            <FaCaretUp onClick={() => handleExpand(attribute.attributeID)} />
                          ) : (
                            <FaCaretDown onClick={() => handleExpand(attribute.attributeID)} />
                          )}
                          <RiDeleteBinLine
                            onClick={() => {
                              handleDeleteAttribute(attribute.attributeID);
                            }}
                          />
                          <BiEditAlt onClick={() => handleExpand(attribute.attributeID)} />
                        </div>
                      </div>
                      {attribute.isExpanded && (
                        <>
                          <div className={style.existingAttributeBody}>
                            <div className={style.existingAttributeBodyLeft}>
                              {attribute.type === "custom" ? (
                                <>
                                  <div className={style.customAttributeName}>
                                    <span>Name</span>:<p></p>
                                  </div>
                                  <div
                                    className={style.customAttributeNameInput}
                                  >
                                    <input />
                                  </div>
                                </>
                              ) : (
                                <div className={style.existingAttributeName}>
                                  <span>Name</span>:<p>{attribute.name}</p>
                                </div>
                              )}
                            </div>
                            <div className={style.existingAttributeBodyRight}>
                              <div className={style.existingAttributeName}>
                                <span>Value(s)</span>:
                              </div>
                              {attribute.type === "custom" ? (
                                <div
                                  className={
                                    style.customAttributeSelectionContainer
                                  }
                                >
                                  <textarea placeholder="Enter some text or attributes by (|) separating values" />
                                </div>
                              ) : (
                                <div
                                  className={
                                    style.existingAttributeSelectionContainer
                                  }
                                >
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowType((prevState) => ({
                                        ...prevState,
                                        [attribute.attributeID]: !isTypeVisible,
                                      }));
                                    }}
                                    className={style.existingAttributeSelection}
                                  >
                                    {typesForAttribute.length > 0 ? (
                                      typesForAttribute.map((id, i) => {
                                        const typeName = attribute.attributeType.find(
                                          (type) => type.type_code === id.type_code
                                        )?.type_name;
                                        return (
                                          <div key={i}
                                            className={style.selectedTypeCard}
                                          >
                                            <p>{typeName}</p>
                                            <IoIosCloseCircleOutline
                                              onClick={() => handleRemoveType(attribute.attributeID, id.type_code)}
                                            />
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className={style.placeHolder}>
                                        <p>Select Type</p>
                                      </div>
                                    )}
                                  </div>
                                  {isTypeVisible && (
                                    <div
                                      ref={typeRef}
                                      className={style.typeContainer}
                                    >
                                      {attribute.attributeType.map((type, i) => {
                                        return (
                                          <div
                                            onClick={() => {
                                              handleTypeSelect(attribute.attributeID, type);
                                              setShowType(false);
                                            }}
                                            key={i}
                                            className={style.typeValue}
                                          >
                                            <p>{type.type_name}</p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={style.existingAttributeButton}>
                            <button onClick={() => {

                              handleSaveAttributes(attribute.attributeID)
                              handleExpand(attribute.attributeID)
                            }}>Save Attributes</button>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={style.attributeSection}>
              <div className={style.variantSelectionHeader}>
                <div className={style.attributeSelection}>

                </div>
                <div className={style.attributeAddButton}>
                  <button
                    onClick={() => {
                      handleVarient();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className={style.attributeSectionBody}>
                {variantSections.map((item, index) => {
                  return (
                    <div key={index} className={style.existingAttributeSection}>
                      <div className={style.existingVarientHeader}>
                        <div className={style.varientLabelDropdown}>
                          {renderVariantDropdowns(item.variantID)}
                        </div>
                        <div className={style.attributeHeadIcon}>
                          {item.isExpanded ? (
                            <FaCaretUp
                              onClick={() => handleVarientExpand(item.variantID)}
                            />
                          ) : (
                            <FaCaretDown
                              onClick={() => handleVarientExpand(item.variantID)}
                            />
                          )}
                          <RiDeleteBinLine
                            onClick={() => handleVarientDelete(item.variantID)}
                          />
                          <BiEditAlt />
                        </div>
                      </div>
                      {item.isExpanded && (
                        <>
                          <div className={style.existingVarientBody}>
                            <div className={style.uploadImageContainer}>
                              <div className={style.imageUpload}>
                                <label htmlFor="imageUpload">
                                  <FaImage
                                    title="Upload Image"
                                    style={{ color: "#59293a", fontSize: "70px" }}
                                    onClick={handleVariantImage}
                                  />
                                </label>
                                <input
                                  type="file"
                                  id="variantImageUpload"
                                  name="imageUpload"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  multiple
                                  onChange={(e) => handleVariantImageUpload(e, item.variantID)}
                                />
                              </div>
                              <div className={style.uploadedImages}>
                                {item.image?.length > 0
                                  ? item.image.map((img, i) => {
                                    return (
                                      <div className={style.uploadImage}>
                                        <img
                                          key={i}
                                          style={{ width: "100px", height: "100px" }}
                                          src={img}
                                          alt=""
                                        />
                                        <div className={style.uploadImageOverlay}>
                                          <RiDeleteBin6Line
                                            onClick={() => handleDeleteVariantImage(item.variantID, i)}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                                  : ""}
                              </div>
                            </div>
                            <div className={style.priceFields}>
                              <TextField
                                label="Regular Price"
                                fullWidth
                                margin="normal"
                                value={item.regular_price}
                                onChange={(e) =>
                                  handleVariantChange(item.variantID, "regular_price", parseFloat(e.target.value))
                                }
                              />
                              <TextField
                                label="Sale Price"
                                fullWidth
                                margin="normal"
                                value={item.sale_price}
                                onChange={(e) =>
                                  handleVariantChange(item.variantID, "sale_price", parseFloat(e.target.value))
                                }
                              />
                            </div>
                            <div className={style.stockStatus}>
                              <TextField
                                select
                                label="Stock Status"
                                fullWidth
                                margin="normal"
                                value={item.stock_status}
                                onChange={(e) =>
                                  handleVariantChange(item.variantID, "stock_status", e.target.value)
                                }
                              >
                                <MenuItem value="in-stock">In Stock</MenuItem>
                                <MenuItem value="out-of-stock">
                                  Out of Stock
                                </MenuItem>
                              </TextField>
                            </div>

                            <div className={style.descriptionField}>
                              <TextField
                                label="Description"
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                                value={item.desc}
                                onChange={(e) =>
                                  handleVariantChange(item.variantID, "description", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className={style.existingAttributeButton}>
                            <button onClick={() => {
                              handleSaveVariant(item.variantID);
                            }}>Add Variants</button>
                          </div>
                        </>
                      )}
                      {/* <div className={style.existingAttributeButton}>
                        <button>Save Variants</button>
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Typography>
      </div>
    </div>
  );
}

export default ItemCreation;

