import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import style from "./Home.module.css";

function Home() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const storedToken = localStorage.getItem("access-token");

    const handleNavigation = () => {
      if (storedToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        navigate("/login");
      }
    };

    handleNavigation();
  }, [navigate]);

  return (
    <>
      {isLoggedIn ? (
        <div className={style.hub}>
          <h1>Home</h1>
        </div>
      ) : (
        <div>Please log in to access this page.</div> 
      )}
    </>
  );
}

export default Home;
