import React, { useState, useEffect } from "react";
import style from "./PermissionsContent.module.css";
import { styled } from "@mui/material/styles";
import { baseUrl } from "../Url";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#59293a" : "#59293a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function PermissionsContent() {
  const [showAnimation, setShowAnimation] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [features, setFeatures] = useState([]);
  const [mappedFeatures, setMappedFeatures] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const selectedModules = JSON.parse(localStorage.getItem("selectedModules"));
    if (selectedModules && selectedModules.length > 0) {
      fetchFeatures(selectedModules);
      fetchMapedFeatures();
    }
  }, []);

  const fetchFeatures = async (selectedModules) => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/features/under/modules`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({ module_code: selectedModules }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch features");
      }
      const data = await response.json();
      if (data.status) {
        setFeatures(data.data);
      } else {
        throw new Error(data.description);
      }
    } catch (error) {
      console.error("Error fetching features:", error.message);
    }
  };

  const fetchMapedFeatures = async () => {
    try {
      const selectedModules = JSON.parse(
        localStorage.getItem("selectedModules")
      );
      const roleCode = localStorage.getItem("role_code");

      const requestBody = {
        module_code: selectedModules,
        role_code: roleCode,
      };

      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(
        `${baseUrl}/mapped_permission/under/modules`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch mapped features");
      }

      const data = await response.json();
      if (data.status) {
        setMappedFeatures(data.data);
      } else {
        throw new Error(data.description);
      }
    } catch (error) {
      console.error("Error fetching mapped features:", error.message);
    }
  };

  const handleSwitchToggle = async (
    featureCode,
    featureName,
    permissionType,
    checked
  ) => {
    setSnackbarMessage("Permissions changed!");
    setSnackbarOpen(true);
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const roleCode = localStorage.getItem("role_code");
      const roleName = localStorage.getItem("role_name");
      const roleId = localStorage.getItem("role_id");

      const requestBody = {
        feature_code: featureCode,
        feature_name: featureName,
        role_code: roleCode,
        role_name: roleName,
        role_id: roleId,
        permission: [
          {
            Read: permissionType === "Read" ? checked : false,
            Write: permissionType === "Write" ? checked : false,
            Full_access: permissionType === "Full Access" ? checked : false,
          },
        ],
      };

      const response = await fetch(`${baseUrl}/permission/map`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to update permission");
      }
      await fetchMapedFeatures(); // Refresh mapped permissions
      await fetchFeatures(JSON.parse(localStorage.getItem("selectedModules")));
    } catch (error) {
      console.error("Error updating permission:", error.message);
    }
  };

  const handlePermissionToggle = async (
    featureCode,
    featureName,
    permissionType,
    checked
  ) => {
    setSnackbarMessage("Permissions Changed !");
    setSnackbarOpen(true);
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const roleCode = localStorage.getItem("role_code");
      const roleName = localStorage.getItem("role_name");
      const roleId = localStorage.getItem("role_id");

      const existingPermissions = mappedFeatures.find(
        (mappedFeature) =>
          mappedFeature?.pemissions?.[0]?.feature_code === featureCode
      )?.pemissions?.[0]?.permission_list?.[0];

      // Create updated permissions object with Full Access value passed to Full_access
      const updatedPermissions = {
        ...existingPermissions,
        [permissionType === "Full Access" ? "Full_access" : permissionType]:
          checked,
      };
      // Delete Full Access property if it exists
      delete updatedPermissions["Full Access"];

      const requestBody = {
        feature_code: featureCode,
        feature_name: featureName,
        role_code: roleCode,
        role_name: roleName,
        role_id: roleId,
        permission: [updatedPermissions],
      };

      const response = await fetch(`${baseUrl}/permission/map`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to update permission");
      }
      await fetchMapedFeatures(); // Refresh mapped permissions
      await fetchFeatures(JSON.parse(localStorage.getItem("selectedModules")));
    } catch (error) {
      console.error("Error updating permission:", error.message);
    }
  };

  return (
    <>
      <div
        className={`${style.container} ${showAnimation ? style.slideIn : ""}`}
      >
        <div className={style.tableContainer}>
          <div className={style.Header}>
            <div className={style.HeaderText}>
              <p>
              <p>Permissions For <span>: {localStorage.getItem("role_name")}</span></p>

              </p>
            </div>
          </div>
          <TableContainer className={style.table} component={Paper}>
            <Table>
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell>Features</TableCell>
                  <TableCell>Read</TableCell>
                  <TableCell>Write</TableCell>
                  <TableCell>Full Access</TableCell>
                </TableRow>
              </TableHead>

              {mappedFeatures.length > 0 && (
                <TableBody className={style.table1}>
                  {mappedFeatures.map((mappedFeature) => {
                    const permissions = mappedFeature?.pemissions?.[0];
                    if (
                      !permissions ||
                      !Object.values(permissions).some(Boolean)
                    ) {
                      return null;
                    }
                    return (
                      <TableRow key={permissions?.feature_code}>
                        <TableCell>{permissions?.feature_name}</TableCell>
                        <TableCell>
                          <AntSwitch
                            checked={permissions?.permission_list?.[0]?.Read}
                            onChange={(e) =>
                              handlePermissionToggle(
                                permissions?.feature_code,
                                permissions?.feature_name,
                                "Read",
                                e.target.checked
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <AntSwitch
                            checked={permissions?.permission_list?.[0]?.Write}
                            onChange={(e) =>
                              handlePermissionToggle(
                                permissions?.feature_code,
                                permissions?.feature_name,
                                "Write",
                                e.target.checked
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <AntSwitch
                            checked={
                              permissions?.permission_list?.[0]?.Full_access
                            }
                            onChange={(e) =>
                              handlePermissionToggle(
                                permissions?.feature_code,
                                permissions?.feature_name,
                                "Full Access",
                                e.target.checked
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

              <TableBody className={style.table2}>
                {features
                  .filter(
                    (feature) =>
                      !mappedFeatures.find(
                        (mappedFeature) =>
                          mappedFeature?.pemissions?.[0]?.feature_name ===
                          feature.feature_name
                      )
                  )
                  .map((feature) => (
                    <TableRow key={feature?._id?.$oid}>
                      <TableCell>{feature && feature.feature_name}</TableCell>
                      <TableCell>
                        <AntSwitch
                          onChange={(e) =>
                            handleSwitchToggle(
                              feature?.feature_code,
                              feature?.feature_name,
                              "Read",
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <AntSwitch
                          onChange={(e) =>
                            handleSwitchToggle(
                              feature?.feature_code,
                              feature?.feature_name,
                              "Write",
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <AntSwitch
                          onChange={(e) =>
                            handleSwitchToggle(
                              feature?.feature_code,
                              feature?.feature_name,
                              "Full Access",
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

         
        </div>

        <div className={style.snackbar}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={1000}
              onClose={() => setSnackbarOpen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              sx={{ width: "300px",
              marginTop: "630px",
              marginLeft: "150px",

            
            }}
            >
              <Alert
                severity="success"
                variant="outlined"
                sx={{ width:"100%", fontSize: "1rem" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
      </div>
    </>
  );
}

export default PermissionsContent;
