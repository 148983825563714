import React, { useState, useEffect, useRef } from 'react';
import style from './ItemUpdate.module.css';
import {
  MenuItem, TextField, FormControl, Select, Checkbox, InputLabel, Alert,
  Snackbar, Tab, Tabs, Typography
} from "@mui/material";
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../Url';
import { FaCaretDown, FaCaretUp, FaImage, FaLessThanEqual } from 'react-icons/fa';
import { RiDeleteBin6Line, RiDeleteBinLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoMdCloudUpload } from "react-icons/io";

function ItemUpdate() {
  const token = JSON.parse(localStorage.getItem("access-token"));
  const location = useLocation();
  const state = location.state;
  const typeRef = useRef(null);
  const [editId, setEditId] = useState(state.editId);
  const [editName, setEditName] = useState("");
  const [editShortDesc, setEditShortDesc] = useState("");
  const [editLongDesc, setEditLongDesc] = useState("");
  const [editItemType, setEditItemType] = useState("");
  const [editVarient, setEditVarient] = useState("");
  const [editMakingCost, setEditMakingCost] = useState("");
  const [editInvenCost, setEditInvenCost] = useState("");
  const [editPurchasePrice, setEditPurchasePrice] = useState("");
  const [editSalePrice, setEditSalePrice] = useState("");
  const [editDiscPercentage, setEditDiscPercentage] = useState("");
  const [editPurchasePercentage, setEditPurchasePercentage] = useState("");
  const [editSalePercentage, setEditSalePercentage] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [itemUpdationSnack, setItemUpdationSnack] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [editAttribute, setEditAttribute] = useState("");
  const [attributeData, setAttributeData] = useState([]);
  const [savedAttributeData, setSavedAttributeData] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [showType, setShowType] = useState({});
  const [savedAttributes, setSavedAttributes] = useState([]);
  const [savedAttributeDetails, setSavedAttributeDetails] = useState([]);
  const [savedVariantData, setSavedVariantData] = useState([]);
  const [updatedVariants, setUpdatedVariants] = useState([]);
  const [editSelectedCategory, setEditSelectedCategory] = useState([])
  const [editItemImages, setEditItemImages] = useState([]);
  const [categoryData, setCategoryData] = useState([])
  const [editOrgID, setEditOrgId] = useState('')
  const [taxType, setTaxType] = useState("")
  const [editHsnCode, setEditHsnCode] = useState("")
  const [editBarCode, setEditBarCode] = useState("")
  const [editManufacturingItem, setEditManufacturingItem] = useState(false)
  const [editFixedAsset, setEditFixedAsset] = useState(false)
  const [editTransferableAsset, setEditTransferableAsset] = useState(false)
  const [editMaintainStock, setEditMaintainStock] = useState(false)



  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200, // Set the maximum height you want
      },
    },
  };


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleExpand = (attributeID) => {
    setSavedAttributeData((prevData) =>
      prevData.map((item) =>
        item.attribute_data.attribute[0].attribute_id === attributeID
          ? { ...item, isExpanded: !item.isExpanded }
          : item
      )
    );
  };

  const handleDeleteAttribute = (attributeID) => {
    setSavedAttributeData((prevData) =>
      prevData.filter((item) => item.attribute_data.attribute[0].attribute_id !== attributeID)
    );

    // Remove from selectedType
    setSelectedType((prevSelectedType) => {
      const { [attributeID]: _, ...remainingSelectedTypes } = prevSelectedType;
      return remainingSelectedTypes;
    });

    // Remove from savedAttributes
    setSavedAttributes((prevSavedAttributes) =>
      prevSavedAttributes.filter((savedAttr) => savedAttr.attributeID !== attributeID)
    );

    // Remove from savedAttributeDetails
    setSavedAttributeDetails((prevSavedDetails) =>
      prevSavedDetails.filter((savedDetail) => savedDetail.attributeID !== attributeID)
    );
  };

  const handleRemoveType = (attributeID, typeCodeToRemove) => {
    setSelectedType((prevSelectedType) => {
      const updatedTypesForAttribute = prevSelectedType[attributeID].filter(
        (type) => type.type_code !== typeCodeToRemove
      );

      return {
        ...prevSelectedType,
        [attributeID]: updatedTypesForAttribute
      };
    });
  };

  const handleTypeSelect = (attributeID, type) => {
    console.log('Selected type---1111:', type);
    setSelectedType((prevSelectedType) => {
      const typesForAttribute = prevSelectedType[attributeID] || [];
      const isChecked = typesForAttribute.find((selected) => selected.type_code === type.type_code);

      if (!isChecked) {
        return {
          ...prevSelectedType,
          [attributeID]: [...typesForAttribute, type]
        };
      } else {
        return prevSelectedType;
      }
    });
  };

  const handleSaveAttributes = (attributeID) => {
    console.log('Saving attributes for attributeID:', attributeID);
    const typesForAttribute = selectedType[attributeID] || [];
    console.log(typesForAttribute, 'typesForAttribute----------');;
    const attributeObject = { [attributeID]: typesForAttribute };
    console.log('Attribute Object:---11111111', attributeObject);
    console.log('Current typesForAttribute sections:', [attributeObject]);
    if (typesForAttribute.length > 0) {
      setSavedAttributes((prevSavedAttributes) => {
        const newSavedAttributes = Array.isArray(prevSavedAttributes) ? [...prevSavedAttributes] : [];
        console.log('Previous saved attributes:', prevSavedAttributes);
        const updatedSavedAttributes = [
          ...newSavedAttributes,
          attributeObject
        ];
        console.log('Updated saved attributes:', updatedSavedAttributes);
        return updatedSavedAttributes;
      });
      // Save attribute name and types to savedAttributeDetails
      const attributeName = savedAttributeData.find(item => item.attribute_data.attribute[0].attribute_id === attributeID).attribute_data.attribute[0].attribute_name;
      setSavedAttributeDetails((prevDetails) => {
        const newDetails = prevDetails.filter(detail => detail.attributeID !== attributeID);
        const updatedDetails = [
          ...newDetails,
          { attributeID, attributeName, types: typesForAttribute }
        ];
        console.log('Updated saved attribute details:', updatedDetails);
        return updatedDetails;
      });
    }
  };

  // variant
  const handleSaveVariantData = () => {
    console.log("Saved updated Variant Data:", savedVariantData);
    // Assuming savedVariantData contains all the variants with updated details
    setUpdatedVariants([...savedVariantData]);
  };

  const handleVariantImageUpload = (event, variantID) => {
    const files = event.target.files;
    const file = files[0]; // Assuming only one file is uploaded
    const reader = new FileReader();

    reader.onload = (event) => {
      const newImage = event.target.result;

      // Update the variant state with the new image
      setSavedVariantData((prevState) =>
        prevState.map((variant) =>
          variant.variant_id === variantID
            ? { ...variant, image_file: [newImage] } // Replace existing image with new one
            : variant
        )
      );
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleVariantExpand = (index) => {
    setSavedVariantData((prevState) =>
      prevState.map((variant, i) =>
        i === index ? { ...variant, isExpanded: !variant.isExpanded } : variant
      )
    );
  };

  const handleVariantDelete = (variantID) => {
    setSavedVariantData((prevState) =>
      prevState.filter((variant) => variant.variant_id !== variantID)
    );
  };

  const handleVariantChange = (index, field, value) => {
    setSavedVariantData((prevState) =>
      prevState.map((variant, i) =>
        i === index ? { ...variant, [field]: value } : variant
      )
    );
  };

  function renderVariantDropdowns(attributeType) {
    return attributeType.map((type, index) => (
      <TextField
        key={index}
        select
        // label={`Select ${type.type_name}`}
        label={`Attribute ${index + 1}`}
        fullWidth
        margin="normal"
        value={type.type_code} // or a state variable if you need to manage the selected value
        onChange={(e) => {
          // handle change if necessary
        }}
      >
        <MenuItem value={type.type_code}>{type.type_name}</MenuItem>
      </TextField>
    ));
  }

  async function listAttribute() {
    await fetch(`${baseUrl}/attribute/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({})
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setAttributeData(data.data);
        }
      })
  }

  const handleImageUpload = () => {
    document.getElementById("stockItemImage").click();
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const uploadedImages = [];

    for (let file of files) {
      const formData = new FormData();
      formData.append('image', file);
      try {
        const response = await fetch(`${baseUrl}/manage/media`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          const imageUrl = responseData.data[0].image_url;
          uploadedImages.push(imageUrl);
        } else {
          console.error('Failed to upload image', response);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    if (uploadedImages.length > 0) {
      setEditItemImages((prevImages) => [...prevImages, ...uploadedImages]);
    }
  };


  const handleDeleteImage = (index) => {
    const updatedImages = editItemImages.filter((_, i) => i !== index);
    setEditItemImages(updatedImages);
  };

  const handleCheckboxChange = (categoryID) => {
    const newSelectedcategory = editSelectedCategory.includes(categoryID)
      ? editSelectedCategory.filter((id) => id !== categoryID)
      : [...editSelectedCategory, categoryID];

    setEditSelectedCategory(newSelectedcategory);
  };


  async function categoryList() {
    const reqData = {
      org_id:editOrgID
    }
    console.log(reqData,'reqData')
    await fetch(`${baseUrl}/hub/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCategoryData(data.data);
        }
      });
  }

  async function menuItemsUpdate() {
    const reqData = {
      org_id: editOrgID,
      itemID: editId,
      ItemName: editName,
      ShortDescription: editShortDesc,
      LongDescription: editLongDesc,
      ItemType: editItemType,
      IsVariantsExist: editVarient,
      category_id: editSelectedCategory,
      MakingCost: editMakingCost,
      InventoryCost: editInvenCost,
      PurchasePrice: editPurchasePrice,
      SalePrice: editSalePrice,
      SaleDiscountPercentage: editDiscPercentage,
      PurchaseTaxPercentage: editPurchasePercentage,
      SaleTaxPercentage: editSalePercentage,
      Status: editStatus,
      image: editItemImages,
      attribute_item: editVarient === true ? savedAttributeDetails : [],
      variant_item: editVarient === true ? updatedVariants : [],
      product_tax_type: taxType === "true" ? true : false,
      hsn_code: editHsnCode,
      bar_code: editBarCode,
      is_manufacturing_item: editManufacturingItem,
      is_fixed_asset: editFixedAsset,
      is_transferable_asset: editTransferableAsset,
      maintain_stock: editMaintainStock,
    };
    await fetch(`${baseUrl}/Item/edit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'updateData');
        if (data.status === true) {
          setItemUpdationSnack(true);
        }
      });
  }

  async function getItemDetails() {
    await fetch(`${baseUrl}/stock/item/detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        itemID: editId
      })
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'edit details');
        if (data.status === true) {
          setEditName(data.data[0].ItemName)
          setEditShortDesc(data.data[0].ShortDescription)
          setEditLongDesc(data.data[0].LongDescription)
          setEditItemType(data.data[0].ItemType)
          setEditVarient(data.data[0].IsVariantsExist)
          setEditMakingCost(data.data[0].MakingCost)
          setEditInvenCost(data.data[0].InventoryCost)
          setEditPurchasePrice(data.data[0].PurchasePrice)
          setEditSalePrice(data.data[0].SalePrice)
          setEditDiscPercentage(data.data[0].SaleDiscountPercentage)
          setEditPurchasePercentage(data.data[0].PurchaseTaxPercentage)
          setEditSalePercentage(data.data[0].SaleTaxPercentage)
          setEditStatus(data.data[0].Status)
          setEditHsnCode(data.data[0].hsn_code)
          setEditBarCode(data.data[0].bar_code)
          setEditManufacturingItem(data.data[0].is_manufacturing_item)
          setEditFixedAsset(data.data[0].is_fixed_asset)
          setEditTransferableAsset(data.data[0].is_transferable_asset)
          setEditMaintainStock(data.data[0].maintain_stock)
          const newImages = data.data[0].media.map(img => img.MediaThumbnail);
          setEditItemImages(newImages);
          const newCategory = data.data[0].category_details.map(catId => catId);
          setEditSelectedCategory(newCategory);
          setEditOrgId(data.data[0].org_id)
          const tax_type = data.data[0].product_tax_type === true ? "true" : "false"
          setTaxType(tax_type)
          let newAttr = data.data[0].attribute_data.map((item) => ({
            ...item,
            isExpanded: false
          }))
          console.log(newAttr, 'newAttr');
          setSavedAttributeData(newAttr);

          // Initialize selectedType based on saved attributes
          const initialSelectedType = {};
          data.data[0].attribute_data.forEach(attr => {
            initialSelectedType[attr.attribute_data.attribute[0].attribute_id] = attr.attribute_data.attribute_type.map(type => ({
              type_code: type.type_code,
              type_name: type.type_name
            }));
          });
          setSelectedType(initialSelectedType);

          let newVar = data.data[0].variant.map((item) => ({
            ...item,
            isExpanded: false
          }))
          console.log(newVar, 'newVar');
          setSavedVariantData(newVar);
        }
      })
  }

  useEffect(() => {
    listAttribute()
    getItemDetails()
    // categoryList()
  }, [editId]);
  useEffect(()=>{
  if(editOrgID){
    categoryList()
  }
  },[editOrgID])
  useEffect(() => {
    console.log(taxType);
  }, [taxType])

  const tabArray = [
    <Tab
      key="StockItem"
      label="StockItem"
      sx={{
        "&.Mui-selected": {
          color: "#59293a",
          backgroundColor: "",
        },
        fontSize: "1.1rem",
      }}
    />,
  ];

  if (editVarient) {
    tabArray.push(
      <Tab
        key="Attributes"
        label="Attributes"
        sx={{
          "&.Mui-selected": {
            color: "#59293a",
            backgroundColor: "",
          },
          fontSize: "1.1rem",
        }}
      />,
      <Tab
        key="Variants"
        label="Variants"
        sx={{
          "&.Mui-selected": {
            color: "#59293a",
            backgroundColor: "",
          },
          fontSize: "1.1rem",
        }}
      />
    );
  }

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={itemUpdationSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setItemUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {itemUpdationSnack ? "Item updated successfully" : ""}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Stock Items Updation</p>
        </div>
        <div className={style.buttonSection}>
          <button
            onClick={() => {
              menuItemsUpdate();
            }}
          >
            Update Stock
          </button>
        </div>
      </div>
      <div className={style.tabSection}>
        <Tabs TabIndicatorProps={{ style: { backgroundColor: "#59293a" } }} value={selectedTab} onChange={handleTabChange}>
          {tabArray}
        </Tabs>
      </div>
      <div className={style.typography}>
        <Typography>
          {selectedTab === 0 && (
            <div className={style.tableSection}>
              <div className={style.leftSection}>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Item Name</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      className={style.textfield}
                      label="Item Name"
                      fullWidth
                      value={editName}
                      onChange={(e) => {
                        setEditName(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Item Image</p>
                  </div>
                  <div className={style.inputSection}>
                    <div className={style.uploadImageContainer}>
                      <div className={style.uploadButton}>
                        <button onClick={handleImageUpload}>
                          Upload images <IoMdCloudUpload />
                        </button>
                        <input
                          id="stockItemImage"
                          className={style.fileInput}
                          label="Item image"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>
                      <div className={style.uploadedImages}>
                        {editItemImages && editItemImages.length > 0
                          ? editItemImages.map((img, i) => {
                            return (
                              <div key={i} className={style.uploadImage}>
                                <img
                                  style={{ width: "100px", height: "100px" }}
                                  src={img}
                                  alt=""
                                />
                                <div className={style.uploadImageOverlay}>
                                  <RiDeleteBin6Line
                                    onClick={() => {
                                      handleDeleteImage(i);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Short Description</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      label="Short Description"
                      fullWidth
                      multiline
                      rows={2}
                      value={editShortDesc}
                      onChange={(e) => {
                        setEditShortDesc(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Long Description</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      label="Long Description"
                      fullWidth
                      multiline
                      rows={4}
                      value={editLongDesc}
                      onChange={(e) => {
                        setEditLongDesc(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Item Type</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editItemType}
                      onChange={(e) => {
                        setEditItemType(e.target.value)
                      }} select label="Item Type" fullWidth>
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Service">Service</MenuItem>
                    </TextField>
                  </div>
                </div>

                <div className={style.inputContainer}>
                  <div className={style.fixedAsset}>
                    <input type="checkbox" id="fixedAsset" className={style.containerCheck}
                      checked={editFixedAsset}
                      disabled={editTransferableAsset}
                      onChange={(e) => {
                        setEditFixedAsset(e.target.checked)
                        if(e.target.checked){
                          setEditTransferableAsset(false)
                        }
                      }}
                    />
                    <label htmlFor="fixedAsset">Is Fixed Asset</label>
                  </div>
                  <div className={style.transferableAsset}>
                    <input type="checkbox" id="transferableAsset" className={style.containerCheck}
                      checked={editTransferableAsset}
                      disabled={editFixedAsset}
                      onChange={(e) => {
                        setEditTransferableAsset(e.target.checked)
                        if(e.target.checked){
                          setEditFixedAsset(false)
                        }
                      }}
                    />
                    <label htmlFor="transferableAsset">Is Transferable Asset</label>
                  </div>
                  <div className={style.manufacturingItem}>
                    <input type="checkbox" id="manufacturingItem" className={style.containerCheck}
                      checked={editManufacturingItem}
                      onChange={(e) => {
                        setEditManufacturingItem(e.target.checked)
                      }}
                    />
                    <label htmlFor="manufacturingItem">Is Manufacturing Item</label>
                  </div>
                  <div className={style.maintainStock}>
                    <input type="checkbox" id="maintainStock" className={style.containerCheck}
                      checked={editMaintainStock}
                      onChange={(e) => {
                        setEditMaintainStock(e.target.checked)
                      }}
                    />
                    <label htmlFor="maintainStock">Maintain Stock</label>
                  </div>
                </div>
                {(!editFixedAsset && !editTransferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Varient</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      select
                      label="Varient Exist"
                      fullWidth
                      value={editVarient}
                      onChange={(e) => {
                        setEditVarient(e.target.value);
                      }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Making Cost</p>
                  </div>
                  <div className={style.inputSection}>
                    {" "}
                    <TextField value={editMakingCost}
                      onChange={(e) => {
                        setEditMakingCost(e.target.value)
                      }} label="Making Cost" fullWidth />
                  </div>
                </div>
                </>
                )}
              </div>
              <div className={style.rightSection}>
              {(!editFixedAsset && !editTransferableAsset) && (
                  <>
              <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Inventory Cost</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editInvenCost}
                      onChange={(e) => {
                        setEditInvenCost(e.target.value)
                      }} label="Inventory Cost" fullWidth />
                  </div>
                </div>
                </>
              )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Purchase Price</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editPurchasePrice}
                      onChange={(e) => {
                        setEditPurchasePrice(e.target.value)
                      }} label="Purchase Price" fullWidth />
                  </div>
                </div>
                {(!editFixedAsset && !editTransferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Sale Price</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editSalePrice}
                      onChange={(e) => {
                        setEditSalePrice(e.target.value)
                      }} label="Sale Price" fullWidth />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Sale Discount Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editDiscPercentage}
                      onChange={(e) => {
                        setEditDiscPercentage(e.target.value)
                      }} label="Sale Discount Percentage" fullWidth />
                  </div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Purcahse Tax Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editPurchasePercentage}
                      onChange={(e) => {
                        setEditPurchasePercentage(e.target.value)
                      }} label="Purcahse Tax Percentage" fullWidth />
                  </div>
                </div>
                {(!editFixedAsset && !editTransferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Sale Tax Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editSalePercentage}
                      onChange={(e) => {
                        setEditSalePercentage(e.target.value)
                      }} label="Sale Tax Percentage" fullWidth />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p> Tax Type</p>
                  </div>
                  <div className={style.inputSection}>
                    <label>
                      <input
                        className={style.radioButton}
                        type="radio"
                        name="taxType"
                        value="true"
                        checked={taxType === "true"}
                        onChange={(e) => {
                          setTaxType(e.target.value)
                        }}
                      />
                      Inclusive
                    </label>
                    <label>
                      <input
                        className={style.radioButton}
                        type="radio"
                        name="taxType"
                        value="false"
                        checked={taxType === "false"}
                        onChange={(e) => {
                          setTaxType(e.target.value)
                        }}
                      />
                      Exclusive
                    </label>
                  </div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>HSN Code</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={editHsnCode}
                      onChange={(e) => {
                        setEditHsnCode(e.target.value)
                      }}
                      label="HSN Code" fullWidth />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Bar Code</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={editBarCode}
                      onChange={(e) => {
                        setEditBarCode(e.target.value)
                      }}
                      label="Bar Code" fullWidth />
                  </div>
                </div>
                {(!editFixedAsset && !editTransferableAsset) && (
                  <>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Category</p>
                  </div>
                  <div className={style.inputSection}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select category"
                        multiple
                        value={editSelectedCategory}
                        onChange={(event) => setEditSelectedCategory(event.target.value)}
                        MenuProps={menuProps}
                      >
                        {categoryData &&
                          categoryData.length > 0 &&
                          categoryData.map((item) => {
                            return (
                              <MenuItem
                                key={item.category_id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                value={item.category_id}
                              >
                                {" "}
                                {item.name}
                                <Checkbox
                                  name={item.category_id}
                                  id={item.category_id}
                                  onChange={() => handleCheckboxChange(item.category_id)}
                                  checked={editSelectedCategory.includes(item.category_id)}
                                />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Status</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField value={editStatus}
                      onChange={(e) => {
                        setEditStatus(e.target.value)
                      }} select label="Status" fullWidth>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={style.attributeSection}>
              <div className={style.attributeSelectionHeader}>
                {/* <div className={style.attributeSelection}>
                  <TextField
                    select
                    label="Select Category"
                    fullWidth
                    margin="normal"
                    value={editAttribute}
                    onChange={(e) => {
                      setEditAttribute(e.target.value)
                    }}
                  >
                    {attributeData.map((atr) => {
                      return (
                        <MenuItem key={atr.attribute_code} value={atr.attribute_code}>
                          {atr.attribute_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div> */}
                {/* <div className={style.attributeAddButton}>
                  <button>
                    Add
                  </button>
                </div> */}
              </div>
              <div className={style.attributeSectionBody}>
                {
                  savedAttributeData && savedAttributeData.map((item) => {
                    console.log(item);
                    console.log(item.attribute_data.attribute[0].attribute_name);
                    const typesForAttribute = selectedType[item.attribute_data.attribute[0].attribute_id] || [];
                    const isTypeVisible = showType[item.attribute_data.attribute[0].attribute_id] || false;
                    return (
                      <div className={style.existingAttributeSection}>
                        <div className={style.existingAttributeHeader}>
                          <p>{item.attribute_data.attribute[0].attribute_name}</p>
                          <div className={style.attributeHeadIcon}>
                            {item.isExpanded ? (
                              <FaCaretUp onClick={() => handleExpand(item.attribute_data.attribute[0].attribute_id)} />
                            ) : (
                              <FaCaretDown onClick={() => handleExpand(item.attribute_data.attribute[0].attribute_id)} />
                            )}
                            {/* <RiDeleteBinLine
                              onClick={() => {
                                handleDeleteAttribute(item.attribute_data.attribute[0].attribute_id);
                              }}
                            />
                            <BiEditAlt onClick={() => handleExpand(item.attribute_data.attribute[0].attribute_id)} /> */}
                          </div>
                        </div>
                        {item.isExpanded && (
                          <>
                            <div className={style.existingAttributeBody}>
                              <div className={style.existingAttributeBodyLeft}>
                                <div className={style.existingAttributeName}>
                                  <span>Name</span>:<p>{item.attribute_data.attribute[0].attribute_name}</p>
                                </div>
                              </div>
                              <div className={style.existingAttributeBodyRight}>
                                <div className={style.existingAttributeName}>
                                  <span>Value(s)</span>:
                                </div>
                                <div
                                  className={
                                    style.existingAttributeSelectionContainer
                                  }
                                >
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowType((prevState) => ({
                                        ...prevState,
                                        [item.attribute_data.attribute[0].attribute_id]: !isTypeVisible,
                                      }));
                                    }}
                                    className={style.existingAttributeSelection}
                                  >
                                    {typesForAttribute.length > 0 ? (
                                      typesForAttribute.map((type, i) => (
                                        <div key={i} className={style.selectedTypeCard}>
                                          <p>{type.type_name}</p>
                                          <IoIosCloseCircleOutline
                                            onClick={() => handleRemoveType(item.attribute_data.attribute[0].attribute_id, type.type_code)}
                                          />
                                        </div>
                                      ))
                                    ) : (
                                      <div className={style.placeHolder}>
                                        <p>Select Type</p>
                                      </div>
                                    )}
                                  </div>
                                  {isTypeVisible && (
                                    <div
                                      ref={typeRef}
                                      className={style.typeContainer}
                                    >
                                      {item.attribute_data.all_types.map((type, i) => {
                                        console.log('Type being rendered:---111111', type);
                                        const isSelected = typesForAttribute.some(selectedType => selectedType.type_name === type.type_name);
                                        if (!isSelected) {
                                          return (
                                            <div
                                              onClick={() => {
                                                handleTypeSelect(item.attribute_data.attribute[0].attribute_id, type);
                                                setShowType(false);
                                              }}
                                              key={i}
                                              className={style.typeValue}
                                            >
                                              <p>{type.type_name}</p>
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={style.existingAttributeButton}>
                              <button onClick={() => {

                                handleSaveAttributes(item.attribute_data.attribute[0].attribute_id)
                                handleExpand(item.attribute_data.attribute[0].attribute_id)
                              }}>Update Attributes</button>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={style.attributeSection}>
              <div className={style.variantSelectionHeader}>
                <div className={style.attributeSelection}>

                </div>
                <div className={style.attributeAddButton}>
                  {/* <button
                  onClick={() => {
                    handleVarient();
                  }}
                  >
                    Add
                  </button> */}
                </div>
              </div>
              <div className={style.attributeSectionBody}>
                {savedVariantData && savedVariantData.map((variant, index) => (
                  <div
                    key={variant.variant_id}
                    className={style.existingAttributeSection} >
                    <div className={style.existingVarientHeader}>
                      <div className={style.varientLabelDropdown}>
                        {renderVariantDropdowns(variant.attribute_type)}
                      </div>
                      <div className={style.attributeHeadIcon}>
                        {variant.isExpanded ? (
                          <FaCaretUp
                            onClick={() => handleVariantExpand(index)}
                          />
                        ) : (
                          <FaCaretDown
                            onClick={() => handleVariantExpand(index)}
                          />
                        )}
                        {/* <RiDeleteBinLine
                          onClick={() => handleVariantDelete(variant.variant_id)}
                        />
                        <BiEditAlt /> */}
                      </div>
                    </div>
                    {variant.isExpanded && (
                      <>
                        <div className={style.existingVarientBody}>
                          <div className={style.uploadImageContainer}>
                            <div className={style.imageUpload}>
                              <label htmlFor={`variantImageUpload-${variant.variant_id}`}>
                                <FaImage
                                  title="Upload Image"
                                  style={{ color: "#59293a", fontSize: "70px" }}
                                // onClick={handleVariantImage}
                                />
                              </label>
                              <input
                                type="file"
                                id={`variantImageUpload-${variant.variant_id}`}
                                name="imageUpload"
                                accept="image/*"
                                style={{ display: "none" }}
                                multiple
                                onChange={(e) => handleVariantImageUpload(e, variant.variant_id)}
                              />
                            </div>
                            <div className={style.uploadedImages}>
                              {variant.image_file.map((img, i) => {
                                return (
                                  <div className={style.uploadImage} key={i}>
                                    <img
                                      style={{ width: "100px", height: "100px" }}
                                      src={img}
                                      alt={`Variant ${i}`}
                                    />
                                    <div className={style.uploadImageOverlay}>
                                      <RiDeleteBin6Line
                                      // onClick={() => handleDeleteVariantImage(item.variantID, i)}
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          <div className={style.priceFields}>
                            <TextField
                              label="Regular Price"
                              fullWidth
                              margin="normal"
                              value={variant.regular_price}
                              onChange={(e) =>
                                handleVariantChange(index, 'regular_price', e.target.value)
                              }
                            />
                            <TextField
                              label="Sale Price"
                              fullWidth
                              margin="normal"
                              value={variant.sale_price}
                              onChange={(e) =>
                                handleVariantChange(index, 'sale_price', e.target.value)}
                            />
                          </div>
                          <div className={style.stockStatus}>
                            <TextField
                              select
                              label="Stock Status"
                              fullWidth
                              margin="normal"
                              value={variant.stock_status}
                              onChange={(e) =>
                                handleVariantChange(index, 'stock_status', e.target.value)}
                            >
                              <MenuItem value="in-stock">In Stock</MenuItem>
                              <MenuItem value="out-of-stock">
                                Out of Stock
                              </MenuItem>
                            </TextField>
                          </div>

                          <div className={style.descriptionField}>
                            <TextField
                              label="Description"
                              fullWidth
                              multiline
                              rows={4}
                              margin="normal"
                              value={variant.description}
                              onChange={(e) =>
                                handleVariantChange(index, 'description', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className={style.existingAttributeButton}>
                          <button
                            onClick={() => {
                              handleSaveVariantData()
                              handleVariantExpand(index)
                            }}
                          // onClick={handleSaveVariantData}
                          >Update Variants</button>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Typography>
      </div>
    </div>
  );
}

export default ItemUpdate;
