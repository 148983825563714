import React,{useState,useEffect} from 'react'
import Sidebar from './Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import { baseUrl } from "./Url";



function Layout() {

  const token = JSON.parse(localStorage.getItem("access-token"));
  const OrgId = localStorage.getItem("organizationId")
 

  const [selectedNewHub, setSelectedHub] = useState("");
  const [hubList,setHubList]=useState([]);

  async function hubGet() {
    const reqData= {
      org_id: OrgId ? OrgId : ""
    }
    await fetch(`${baseUrl}/organizations/list/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
         setHubList(data.data)
        }
      })
  }

  useEffect(()=>{
   hubGet()
  },[token])

  useEffect(() => {
    if (hubList.length > 0 && !selectedNewHub) {
      setSelectedHub(hubList[0].org_id);
    }
  }, [hubList]);

  return (
    <>
      <Sidebar 
       hubList={hubList}
      selectedHub={selectedNewHub}
        setSelectedHub={setSelectedHub}/>
      <Outlet context={{ selectedNewHub }}/>
    </>
  )
}

export default Layout