import React, { useState, useEffect } from "react";
import style from "./Tax.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Alert,
  Snackbar,
  OutlinedInput,
} from "@mui/material";
import { baseUrl } from "../Url";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Tax = () => {
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [segmentName, setSegmentName] = useState("");
  const [segmentDesc, setSegmentDesc] = useState("");
  const [segmentStatus, setSegmentStatus] = useState("");
  const [segmentData, setSegmentData] = useState([]);
  const [editSegment, setEditSegment] = useState({
    tax_segment_name: "",
    tax_segment_description: "",
    tax_segment_status: "",
    tax_segment_id: "",
  });
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [segmentId, setSegmentId] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const createValidation = () => {
    if (!segmentName) {
      setErrorMessage("Name is required");
      return false;
    }
    if (!segmentDesc) {
      setErrorMessage("Description is required");
      return false;
    }
    if (!segmentStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  }

  async function createSegment() {
    const reqData = {
      tax_segment_name: segmentName,
      tax_segment_description: segmentDesc,
      tax_segment_status: segmentStatus,
    };

    if (!createValidation()) {
      setValidationFail(true);
      return
    }
    else {
      await fetch(`${baseUrl}/tax/segment/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            setCreatePopup(false);
            segmentList();
            setCreateSuccess(true);
            setSegmentName("");
            setSegmentDesc("");
            setSegmentStatus("");
          }
        });
    }
  }

  async function segmentList() {
    const reqData = {
      source: "web",
    };
    await fetch(`${baseUrl}/tax/segment/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSegmentData(data.data);
        }
      });
  }

  async function updateSegment() {
    const body = {
      tax_segment_id: editSegment.tax_segment_id,
      tax_segment_name: editSegment.tax_segment_name,
      tax_segment_description: editSegment.tax_segment_description,
      tax_segment_status: editSegment.tax_segment_status,
    };
    await fetch(`${baseUrl}/tax/segment/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setEditPopup(false);
          segmentList();
          setUpdateSuccess(true);
        }
      });
  }

  async function deleteSegment() {
    const reqData = {
      tax_segment_id: segmentId,
    };
    await fetch(`${baseUrl}/tax/segment/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          segmentList();
          setDeleteSuccess(true);
          setSegmentId("");
        }
      });
  }

  useEffect(() => {
    segmentList();
  }, [token]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess || updateSuccess || deleteSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
          setUpdateSuccess(false);
          setDeleteSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "Segment created successfully"
            : updateSuccess
              ? "Segment updated successfully"
              : deleteSuccess
                ? "Segment deleted successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Tax Segments</p>
        </div>
        <div className={style.headerAction}>
          <div className={style.addButton}>
            <button
              onClick={() => {
                setCreatePopup(true);
              }}
            >
              Add Tax segment
            </button>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.bodySection}>
          <div className={style.listTableHeader}>
            <div className={style.listTableHeadName}>
              <p className={style.listTableHeaderText}>Name</p>
            </div>
            <div className={style.listTableDescription}>
              <p className={style.listTableHeaderText}>Description</p>
            </div>
            <div className={style.listTableStatus}>
              <p className={style.listTableHeaderText}>Status</p>
            </div>
            <div className={style.listTableHeadActions}>
              <p className={style.listTableHeaderText}>Actions</p>
            </div>
          </div>
          <div className={style.listTableBody}>
            {segmentData && segmentData.length > 0
              ? segmentData.map((item, i) => {
                return (
                  <div key={i} className={style.listTableRow}>
                    <div className={style.listTableDataName}>
                      <p className={style.listTableDataText}>
                        {item.tax_segment_name}
                      </p>
                    </div>
                    <div className={style.listTableDataDescription}>
                      <p className={style.listTableDataText}>
                        {item.tax_segment_description}
                      </p>
                    </div>
                    <div className={style.listTableDataStatus}>
                      <p className={style.listTableDataText}>
                        {item.tax_segment_status === "active" ? (
                          <FaRegCircleCheck
                            style={{ color: "green", fontSize: "1.35rem" }}
                          />
                        ) : item.tax_segment_status === "inactive" ? (
                          <IoIosCloseCircleOutline
                            style={{ color: "red", fontSize: "1.5rem" }}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <div className={style.listTableDataActions}>
                      <div className={style.listTableDataIcons}>
                        <BiEditAlt
                          onClick={() => {
                            setEditPopup(true);
                            setEditSegment(item);
                          }}
                        />
                        <RiDeleteBinLine
                          onClick={() => {
                            setDeletePopup(true);
                            setSegmentId(item.tax_segment_id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
              : ""}
          </div>
        </div>
      </div>
      {createPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Create Tax segment</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setCreatePopup(false);
                    setSegmentName("");
                    setSegmentDesc("");
                    setSegmentStatus("");
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={segmentName}
                onChange={(e) => {
                  setSegmentName(e.target.value);
                }}
                label="Tax segment name"
                fullWidth
                margin="normal"
              />

              <TextField
                value={segmentDesc}
                onChange={(e) => {
                  setSegmentDesc(e.target.value);
                }}
                label="Tax segment description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={segmentStatus}
                onChange={(e) => {
                  setSegmentStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    createSegment();
                  }}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                    setSegmentName("");
                    setSegmentDesc("");
                    setSegmentStatus("");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/*Edit Popup */}
      {editPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Edit Tax segment</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setEditPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={editSegment.tax_segment_name}
                onChange={(e) =>
                  setEditSegment({
                    ...editSegment,
                    tax_segment_name: e.target.value,
                  })
                }
                label="Tax segment name"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editSegment.tax_segment_description}
                onChange={(e) =>
                  setEditSegment({
                    ...editSegment,
                    tax_segment_description: e.target.value,
                  })
                }
                label="Tax segment description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editSegment.tax_segment_status}
                onChange={(e) =>
                  setEditSegment({
                    ...editSegment,
                    tax_segment_status: e.target.value,
                  })
                }
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    updateSegment();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setEditPopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* Delete popup*/}
      {deletePopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.deletePopup}>
            <div className={style.deletePopupHeader}>
              <div className={style.deletePopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setDeletePopup(false);
                    setSegmentId("")
                  }}
                />
              </div>
            </div>
            <div className={style.deletePopupSection}>
              <div className={style.deletePopupText}>
                <p>Are you sure want to delete?</p>
              </div>
              <div className={style.deletePopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    deleteSegment();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.closeButton}
                  onClick={() => {
                    setDeletePopup(false);
                    setSegmentId("")
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Tax;
